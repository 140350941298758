export const AT_LEAST_ONE_LOWERCASE_REGEX = new RegExp(/.*[a-z]/);
export const AT_LEAST_ONE_UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const AT_LEAST_ONE_NUMBER_REGEX = new RegExp(/.*\d/);
export const MIN_12_CHAR = new RegExp(/.{12,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(
  /.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/
);

export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[
    AT_LEAST_ONE_LOWERCASE_REGEX.source,
    AT_LEAST_ONE_UPPERCASE_REGEX.source,
    AT_LEAST_ONE_NUMBER_REGEX.source,
    MIN_12_CHAR.source,
    SPECIAL_CHARS_REGEX.source,
  ].join(")(?=")}).*$`
);

