import React, { FunctionComponent } from "react";
import classNames from "classnames/bind";
import styles from "./StudentSuccessChangePin.scss";
import field from "../../assets/field.png";
import messageSent from "../../assets/messageSent.svg";

const StudentSuccessChangePin: FunctionComponent = () => {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("student-success-change-pin-container")}>
      <div className={cx("student-success-change-pin-image-container")}>
        <img className={cx("student-success-change-pin-image")} src={field} />
        <div className={cx("student-success-change-pin-image-overlay")}></div>
      </div>

      <div className={cx("student-success-change-pin-rec-container")}>
        <img
          className={cx("student-success-change-pin-party-cracker")}
          src={messageSent}
        />
        <div className={cx("student-success-change-pin-title")}>
          Successfully Sent
        </div>

        <div className={cx("student-success-change-pin-subtitle")}>
          We have successfully sent PIN reset instructions to your parent’s
          email which is{" "}
          <span className={cx("student-success-change-pin-highlight")}>
            taniya@gmail.com
          </span>
        </div>
      </div>
    </div>
  );
};

export default StudentSuccessChangePin;
