import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./ParentEmailSuccess.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import studentMailIcon from "../../../assets/studentMailIcon.svg";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentGreenCheckedIcon from "../../../assets/studentChecked.png";
import studentTestBtnIcon from "../../../assets/studentTestBtn.svg";
import { fetchStudentProfile } from "utils/WebServiceConfig";

const ParentEmailSuccess = () => {
  // State to hold name
  const [studentName, setStudentName] = useState<string>("en-US");
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  // Fetch the student profile for name
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await fetchStudentProfile();

        profile.preferred_name !== null
          ? setStudentName(profile.preferred_name)
          : setStudentName(`${profile.first_name} ${profile.last_name}`);
      } catch (error) {
        console.error("Error fetching student profile:", error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <>
      <NavigationBarHomeLogout />
      <div className={cx("st-parent-email-success-container")}>
        <div className={cx("st-parent-email-success-title")}>
          Hello {studentName}, Welcome !
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;
        </div>
        <div className={cx("st-parent-email-success-subtitle")}>
          Let's do two things first before we start the exercises. Ask your
          parents to help you with these
          <br /> steps.
        </div>
        <div className={cx("st-parent-email-success-rectangle-container")}>
          <img
            className={cx("st-parent-email-success-mail-icon")}
            src={studentMailIcon}
            alt="student-parent-email-success-mail-icon"
          />
          <div className={cx("st-parent-email-success-label-container")}>
            <div className={cx("st-parent-email-success-label-title")}>
              1. Parent's Email
            </div>
            <div className={cx("st-parent-email-success-label-subtitle")}>
              Make your account safer by adding your parent's <br />
              email. Click "Add" and enter their email.
            </div>
          </div>
          <div className={cx("st-parent-email-success-checked-icon-set")}>
            <img
              className={cx("st-parent-email-success-checked-icon")}
              src={studentGreenCheckedIcon}
              alt="student-parent-email-success-checked-icon"
            />
            <div className={cx("st-parent-email-success-checked-label")}>
              Success
            </div>
          </div>
        </div>

        <div
          className={cx(
            "st-parent-email-success-rectangle-container",
            "st-parent-email-success-microphone-container"
          )}
        >
          <img
            className={cx("st-parent-email-success-mic-icon")}
            src={studentMicIcon}
            alt="student-parent-email-success-mic-icon"
          />
          <div className={cx("st-parent-email-success-label-container")}>
            <div className={cx("st-parent-email-success-label-title")}>
              2. Test Your Microphone
            </div>
            <div className={cx("st-parent-email-success-label-subtitle")}>
              Make sure your Microphone works well. Click on
              <br /> the "Test" button to check it.
            </div>
          </div>

          <img
            className={cx("st-parent-email-success-test-btn")}
            src={studentTestBtnIcon}
            alt="student-parent-email-success-test-btn"
          />
        </div>
      </div>
    </>
  );
};

export default ParentEmailSuccess;
