import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Information.scss";
import { useNavigate, useLocation } from "react-router-dom";
import nextBtn from "assets/nextBtn.svg";
import homeIcon from "assets/Home.svg";
import historyIcon from "assets/History.svg";
import closeIcon from "assets/Chat History Close.svg";
import CustomChatFeed from "shared/CustomChatFeed";
import continueBtn from "assets/continueBtn.svg";
import circularClose from "assets/modalCloseIconCircular.svg";
import micInfo from "assets/studentRecordMic.svg";
import repeatInfo from "assets/studentRepeatInfo.svg";
import sendInfo from "assets/studentSendInfo.svg";
import letStartBtn from "assets/studentLetsStartBtn.svg";
import nonSmiley from "assets/nonSmiley.svg";

import { fetchExerciseChatHistory, fetchStudentProfile } from "utils/WebServiceConfig";
// import { useGetExerciseImage } from "hooks/useGetExerciseImageS3Url";
import { useGetExerciseImageS3Url } from "hooks/useGetExerciseImageS3Url";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";

interface ChatMessage {
  id: number;
  message: string;
}

const Information = () => {
  const [showHistory, setShowHistory] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [showModal, setShowModal] = useState(false); // State to handle modal visibility
  const [showTenMinPopup, setShowTenMinPopup] = useState(false);
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const [studentName, setStudentName] = useState<string>("en-US");

  const searchParams = new URLSearchParams(location.search);
  const exerciseId =
    searchParams.get("exerciseId") ||
    localStorage.getItem("selectedExerciseId");

  const isIncomplete = localStorage.getItem("isIncomplete") === "true";

  // const exerciseImageS3Url = useGetExerciseImage(exerciseId);
  const exerciseImageS3Url = useGetExerciseImageS3Url(exerciseId);

  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_information");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await fetchStudentProfile();
        profile.preferred_name !== null
          ? setStudentName(profile.preferred_name)
          : setStudentName(`${profile.first_name} ${profile.last_name}`);
      } catch (error) {
        console.error("Error fetching student profile:", error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const getChatHistory = async () => {
      try {
        if (exerciseId) {
          const chatHistory = await fetchExerciseChatHistory(exerciseId);
          setChatHistory(chatHistory);
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };

    getChatHistory();
  }, [exerciseId]);



  useEffect(() => {
    const body = document.body;
    if (isIncomplete) {
      body.classList.add('incomplete-exercise');
    } else {
      body.classList.remove('incomplete-exercise');
    }

    return () => {
      body.classList.remove('incomplete-exercise');
    };
  }, [isIncomplete]);

  // Manage inactivity timers
  useEffect(() => {
    let inactivityTimeout: NodeJS.Timeout | null = null;
    let redirectTimeout: NodeJS.Timeout | null = null;

    const elementsToMonitor = [
      ".floating",
      ".icon",
      ".no-btn",
      ".yes-btn",
      ".backtohome-popup-close-btn",
      ".try-again",
      ".try-again-close-btn",
      ".popup-actions",
    ];

    // Reset timer function
    const resetTimer = () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      if (redirectTimeout) clearTimeout(redirectTimeout);
      startInactivityTimer();
    };

    // Start inactivity timer function
    const startInactivityTimer = () => {

      inactivityTimeout = setTimeout(() => {
        setShowTenMinPopup(true);
        setShowModal(false); // Close showModal when showTenMinPopup is active
      }, 10 * 60 * 1000); // 10 minutes

      redirectTimeout = setTimeout(() => {
        navigate("/home");
      }, 15 * 60 * 1000); // 15 minutes
    };

    // Function to add click event listeners to all elements that match the selectors
    const addEventListeners = () => {
      elementsToMonitor.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          element.addEventListener("click", resetTimer);
          console.log(`Event listener added to: ${selector}`);
        });
      });
    };

    // Add event listeners to the initial set of elements
    addEventListeners();

    // Start the inactivity timer initially
    startInactivityTimer();

    // Observe changes in the DOM to handle dynamically added elements
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          addEventListeners();
        }
      }
    });

    // Observe the entire document for changes
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function to clear timeouts and remove event listeners when component unmounts
    return () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      if (redirectTimeout) clearTimeout(redirectTimeout);
      observer.disconnect();

      elementsToMonitor.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          element.removeEventListener("click", resetTimer);
          console.log(`Event listener removed from: ${selector}`);
        });
      });
    };
  }, [navigate]);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleStartBtn = () => {
    localStorage.setItem("nextPromptAPICalled", "false");
    localStorage.setItem("startExerciseAPICalled", "false");
    navigate(`/information-exercise-begins?exerciseId=${exerciseId}`);
  };

  return (
    <div className={cx("student-information-container")}>
      {exerciseImageS3Url ? (
        <img
          className={cx("student-information-background-img")}
          src={exerciseImageS3Url}
          alt="student-information-background"
        />
      ) : (
        <p>Loading image...</p>
      )}
      <div className={cx("student-information-background-chat")}>
        <div
          className={cx("icons-container", {
            "icons-container-active": showHistory,
          })}
        >
          <img
            src={homeIcon}
            alt="home-icon"
            className={cx("icon")}
            onClick={() => navigate("/home")}
          />
          <img
            src={showHistory ? closeIcon : historyIcon}
            alt="toggle-icon"
            className={cx("icon")}
            onClick={() => setShowHistory(!showHistory)}
          />
        </div>
      </div>

      {showHistory && (
        <div className={cx("overlay")} onClick={() => setShowHistory(false)}>
          <div className={cx("chat-history-container")}>
            <div
              className={cx("chat-history-popout")}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={cx("icons-container-modal")}>
                <img
                  src={homeIcon}
                  alt="home-icon"
                  className={cx("icon")}
                  onClick={() => navigate("/home")}
                />
                <img
                  src={closeIcon}
                  alt="toggle-icon"
                  className={cx("icon")}
                  onClick={() => setShowHistory(false)}
                />
              </div>
              <CustomChatFeed messages={chatHistory} />
            </div>
          </div>
        </div>
      )}

      <div className={cx("student-information-chatbox")}>
        <div className={cx("first-time")}>
          <div className={cx("student-information-description")}>
            Hello {studentName}, Welcome! Look at the picture and try to find the
            things, characters, and colours in it. When you're ready to talk about the picture, click the
            “Next” button.
          </div>
          <img
            className={cx("floating floating-extra")}
            src={nextBtn}
            alt="next-button"
            onClick={() => setShowModal(true)}
          />
        </div>

        <div className={cx("not-first-time")}>
          <div className={cx("student-information-description")}>
            Welcome back {studentName}! Look at the picture and try to find the
            things, characters, and colours in it. When you're ready to talk about the picture, click the
            “Continue” button.
          </div>
          <img
            className={cx("floating floating-extra")}
            src={continueBtn}
            alt="Continue Button"
            onClick={handleStartBtn}
          />
        </div>
      </div>

      {showModal && !showTenMinPopup && ( // Prevent showModal from displaying if showTenMinPopup is active
        <div className={cx("st-info-instructions-overlay")}>
          <div className={cx("st-info-instructions-panel")}>
            <img
              className={cx("st-info-instructions-close-btn")}
              src={circularClose}
              alt="circular-close"
              onClick={closeModal} // Close modal on click
            />
            <div className={cx("st-info-instructions-desc-container")}>
              <div className={cx("st-info-instructions-title")}>
                Cool! You're almost there!
              </div>
              <div className={cx("st-info-instructions-icon-set")}>
                <img
                  className={cx("st-info-instructions-icon")}
                  src={micInfo}
                  alt="st-info-instructions-mic-info"
                />
                <div className={cx("st-info-instructions-label-set")}>
                  <div className={cx("st-info-instructions-icon-title")}>
                    Microphone
                  </div>
                  <div className={cx("st-info-instructions-icon-subtitle")}>
                    Press the microphone button to provide your answer.
                  </div>
                </div>
              </div>
              <div className={cx("st-info-instructions-icon-set")}>
                <img
                  className={cx("st-info-instructions-icon")}
                  src={repeatInfo}
                  alt="st-info-instructions-mic-info"
                />
                <div className={cx("st-info-instructions-label-set")}>
                  <div className={cx("st-info-instructions-icon-title")}>
                    Repeat
                  </div>
                  <div className={cx("st-info-instructions-icon-subtitle")}>
                    Press the loudspeaker button to hear the question again.
                  </div>
                </div>
              </div>
              <div className={cx("st-info-instructions-icon-set")}>
                <img
                  className={cx("st-info-instructions-icon")}
                  src={sendInfo}
                  alt="st-info-instructions-mic-info"
                />
                <div className={cx("st-info-instructions-label-set")}>
                  <div className={cx("st-info-instructions-icon-title")}>
                    Send
                  </div>
                  <div className={cx("st-info-instructions-icon-subtitle")}>
                    Press the send button to submit your response.
                  </div>
                </div>
              </div>
              <div className={cx("st-info-instructions-language-desc-container")}>
                <p className={cx("st-info-instructions-language-desc")}>
                  Starting from the next screen, we will communicate in your
                  Mother
                  <br />
                  tongue language which is{" "}
                  <span className={cx("st-info-instructions-language-highlight")}>
                    Chinese/Malay/Tamil.
                  </span>
                </p>
              </div>
              <div className={cx("st-info-instructions-lets-start-btn-container")}>
                <img
                  className={cx("st-info-instructions-lets-start-btn")}
                  src={letStartBtn}
                  alt="st-info-instructions-lets-start-btn"
                  onClick={handleStartBtn} // Close modal and handle start action
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showTenMinPopup && (
        <div className={cx("backtohome-popup-overlay ten-min-no-response-overlay")}>
          <div className={cx("backtohome-popup-container ten-min-no-response")}>
            <img
              className={cx("backtohome-popup-close-btn")}
              src={circularClose}
              alt="circular-close"
              onClick={() => setShowTenMinPopup(false)} // Close the popup
            />
            <img
              className={cx("")}
              src={nonSmiley}
              alt="Oops"
              onClick={() => navigate(`/home?exerciseId=${exerciseId}`)}
            />
            <h1>Hmm… No response</h1>
            <div className={cx("backtohome-popup-message")}>
              <p>Looks like you are taking a break. If there is no response from your side, it will take you back
                to the home page automatically after a while.</p>
            </div>
            <div className={cx("popup-actions")}>
              <a onClick={() => setShowTenMinPopup(false)}>Continue to Welcome Screen </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Information;
