import React, { useState, ChangeEvent, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./ChangeParentsEmail.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import disabledStudentVerifyBtn from "../../../assets/studentVerifyBtnDisabled.svg";
import studentVerifyBtn from "../../../assets/studentVerifyBtn.svg";
import {
  changeParentEmailAPI,
  fetchStudentProfile,
} from "../../../utils/WebServiceConfig";
import modalCloseIcon from "../../../assets/modalCloseIconCircular.svg";
import studentMailIcon from "../../../assets/studentMailIcon.svg";
import NavigationBarFull from "../../../shared/student/NavigationBarFull";
import { useLogOut } from "hooks/useCheckExpiredToken";

const cx = classNames.bind(styles);

const ChangeParentsEmail: React.FC = () => {
  const navigate = useNavigate();
  const {doLogOut, checkIsTokenExpired } = useLogOut();

  const [existingParentsEmail, setExistingParentsEmail] = useState<string>("");
  const [parentsEmail, setParentsEmail] = useState<string>("");
  const [parentsConfirmEmail, setParentsConfirmEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [modalOverlay, setModalOverlay] = useState<boolean>(false);

  const checkOnboardingStatus = localStorage.getItem(
    "both_parent_email_and_mic_test_onboarding"
  );

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await fetchStudentProfile();
        setExistingParentsEmail(profile.parent_email);
      } catch (error) {
        console.error("Error fetching student profile:", error);
      }
    };

    fetchProfile();
  }, []);

  const handleParentsEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setParentsEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
    }
  };

  const handleParentsConfirmEmailChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setParentsConfirmEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
    }
  };

  const isEmailValid = (email: string): boolean => {
    return email.includes("@");
  };

  const isFormValid = (): boolean => {
    return (
      parentsEmail === parentsConfirmEmail &&
      isEmailValid(parentsEmail) &&
      isEmailValid(parentsConfirmEmail)
    );
  };

  const handleVerifyButtonClick = async () => {
    if (isFormValid()) {
      try {
        await changeParentEmailAPI({ email: parentsEmail });
        localStorage.setItem("parents-email-input", parentsEmail);
        setModalOverlay(true);
      } catch (error) {
        if (error instanceof Error && error.message === "Token is required but not found") {
          console.error("Caught Token is missing");
          doLogOut();
        } else if (error instanceof Error && error.message === "Signature has expired") {
          console.error("Caught Signature has expired");
          doLogOut();
        } else {
        setErrorMsg("Failed to verify parent email. Please try again.");
        }
      }
    } else {
      setErrorMsg("Emails do not match or are invalid.");
    }
  };

  const renderEmailPendingVerificationModalOverlay = () => {
    return (
      <div className={cx("modal-overlay")}>
        <div className={cx("modal")}>
          <img
            onClick={handleCloseIconClick}
            className={cx("close-icon-circular")}
            src={modalCloseIcon}
            alt="student-parent-email-success-modal-close-icon"
          />
          <div className={cx("parent-email-verification-success-icon-label")}>
            <img
              className={cx("email-verification-success-icon")}
              src={studentMailIcon}
              alt="student-parent-email-success-email-icon"
            />
            <div className={cx("parent-email-verification-label")}>
              Email Verification
            </div>
            <div className={cx("parent-email-verification-subtitle")}>
              Email verification instructions have been sent to your parent's
              email which is
              <span className={cx("highlight")}> {parentsEmail}</span>. Please
              check it and verify with the help of your parents.
              <br />
              <br />
              Try again after a while if you didn't receive the email.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleCloseIconClick = async () => {
    localStorage.setItem("parents-email-input", "");
    await localStorage.setItem("parent-email-pending-verification", "true");
    navigate("/profile");
  };

  return (
    <>
      {checkOnboardingStatus ? (
        <NavigationBarFull />
      ) : (
        <NavigationBarHomeLogout />
      )}
      <div className={cx("st-change-parents-email-container")}>
        <div
          className={cx(
            "st-change-parents-email-rectangle-container",
            errorMsg && "error-height"
          )}
        >
          <div className={cx("st-change-parents-email-contents")}>
            <div className={cx("st-change-parents-email-title")}>
              Change Parent's Email
            </div>

            <div className={cx("st-change-parents-email-subtitle")}>
              Existing Parent's Email
            </div>

            <input
              id="st-change-parents-email-input-type-email"
              className={cx("st-change-parents-existing-email")}
              type="text"
              name="existingParentsEmail"
              value={existingParentsEmail}
              disabled
            />

            <div className={cx("st-change-parents-email-subtitle")}>
              Parent's New Email
            </div>

            <input
              id="st-change-parents-email-input-type-email"
              className={cx("st-change-parents-email-input")}
              type="text"
              name="parentsEmail"
              placeholder="Type your new parent's email here"
              value={parentsEmail}
              onChange={handleParentsEmailChange}
            />

            <div className={cx("st-change-parents-email-subtitle")}>
              Confirm Parent's New Email
            </div>

            <input
              id="st-change-parents-email-input-confirm-email"
              className={cx("st-change-parents-email-input")}
              type="text"
              name="parentsConfirmEmail"
              placeholder="Type your new parent's email here"
              value={parentsConfirmEmail}
              onChange={handleParentsConfirmEmailChange}
            />

            {errorMsg && (
              <div className={cx("st-change-parents-email-error")}>
                {errorMsg}
              </div>
            )}

            <div className={cx("st-change-parents-email-verify-btn-container")}>
              <img
                className={cx("st-change-parents-email-verify-btn")}
                src={
                  isFormValid() ? studentVerifyBtn : disabledStudentVerifyBtn
                }
                alt={isFormValid() ? "verify-btn" : "disabled-verify-btn"}
                onClick={isFormValid() ? handleVerifyButtonClick : undefined}
                style={{ cursor: isFormValid() ? "pointer" : "not-allowed" }}
              />
            </div>
          </div>
        </div>
      </div>
      {modalOverlay && renderEmailPendingVerificationModalOverlay()}
    </>
  );
};

export default ChangeParentsEmail;
