import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./ResetEmail.scss";
import studentLoginBackground from "../../../assets/studentLoginBackground.png";
import mailIcon from "../../../assets/resetPinMail.svg";
import loginBtn from "../../../assets/studentLoginBtn.svg";
import { resetParentEmail } from "../../../utils/WebServiceConfig";

const cx = classNames.bind(styles);

const ResetEmail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const parentToken = queryParams.get("token");

  const [verificationStatus, setVerificationStatus] = useState<
    "success" | "failure" | null
  >(null);

  useEffect(() => {
    localStorage.setItem("parent-email-pending-verification", "");
    if (parentToken) {
      resetParentEmail(parentToken)
        .then((result: any) => {
          console.log("Success:", result);
          localStorage.setItem("parent-email-pending-verification", "");
          localStorage.setItem("parent-email-onboarding", "true");
          setVerificationStatus("success");
        })
        .catch((error: Error) => {
          console.error("Error:", error.message);
          localStorage.setItem("parent-email-pending-verification", "");
          localStorage.setItem("parent-email-onboarding", "false");
          setVerificationStatus("failure");
        });
    }
  }, [parentToken]);

  const handleRelogin = (event: any) => {
    event.preventDefault();
    localStorage.setItem("role", "");
    localStorage.setItem("bearer-token", "");
    localStorage.setItem("parent-email-pending-verification", "");

    navigate("/");
  };

  console.log("verificationStatus", verificationStatus);

  return (
    <div className={cx("st-email-verify-success-container")}>
      <img
        className={cx("st-email-verify-success-background")}
        src={studentLoginBackground}
        alt="student-email-verify-success-background"
      />

      <div className={cx("st-email-verify-success-rectangle-container")}>
        <div className={cx("st-email-verify-success-rectangle")}>
          <div className={cx("st-email-verify-success-contents-container")}>
            <img
              className={cx("st-email-verify-success-mail-icon")}
              src={mailIcon}
              alt="st-email-verify-success-mail-icon"
            />
            <div
              className={cx(
                verificationStatus === "success"
                  ? "st-email-verify-success-title"
                  : "st-email-verify-failure-title"
              )}
            >
              {verificationStatus === "success"
                ? "Email Verification Successful"
                : "Email Verification Unsuccessful"}
            </div>

            <div
              className={cx(
                verificationStatus === "success"
                  ? "st-email-verify-success-subtitle"
                  : "st-email-verify-unsuccessful-subtitle"
              )}
            >
              {verificationStatus === "success"
                ? "Cool! Your parent's email has been successfully verified."
                : "Oops! Your parent's email has not been successfully verified. Please try again."}
            </div>
            <img
              className={cx("st-email-verify-success-login-btn")}
              src={loginBtn}
              alt="st-email-verify-success-login-btn"
              onClick={handleRelogin}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetEmail;
