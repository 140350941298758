import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./ForgotPin.scss";
import studentLoginBackground from "../../../assets/studentLoginBackground.png";
import confirmBtn from "../../../assets/confirmBtn.svg";
import { forgotPIN } from "../../../utils/WebServiceConfig";

const ForgotPin = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, setChangeEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleEmailChange = (event: any) => {
    setChangeEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
      setFormSubmitted(false);
    }
  };

  const handleConfirmClick = async () => {
    setFormSubmitted(true);
    if (email.length === 0) {
      return;
    }

    if (!email.includes("@")) {
      setErrorMsg("Email not valid");
      return;
    }

    try {
      const response = await forgotPIN({ email });

      if (response.results === "No account found") {
        navigate("/sent-new-pin", { state: { email } });
      } else if (
        response.results === "Parent Email Not Found, Please contact teacher"
      ) {
        navigate("/forgot-pin-no-parent-email");
      } else {
        navigate("/sent-new-pin", { state: { email } });
      }
    } catch (error: any) {
      setErrorMsg(
        error.message || "An error occurred. Please try again later."
      );
      console.error(error);
    }
  };

  return (
    <div className={cx("st-forgot-pin-container")}>
      <img
        className={cx("st-forgot-pin-background")}
        src={studentLoginBackground}
        alt="student-forgot-pin-background"
      />

      <div
        className={cx("st-forgot-pin-rectangle-container", {
          "error-state": errorMsg,
        })}
      >
        <div className={cx("st-forgot-pin-rectangle")}>
          <div className={cx("st-forgot-pin-contents-container")}>
            <div className={cx("st-forgot-pin-title")}>Forgot PIN</div>
            <div className={cx("st-forgot-pin-subtitle")}>
              Please enter your email to reset the PIN.
            </div>

            <div className={cx("st-forgot-pin-email-label")}>Your Email</div>
            <input
              className={
                email.length === 0 && formSubmitted
                  ? cx("st-forgot-pin-input-error-highlight")
                  : cx("st-forgot-pin-input")
              }
              type="text"
              name="email"
              placeholder="Type your email here"
              value={email}
              onChange={handleEmailChange}
            />
            {email.length === 0 && formSubmitted && (
              <div className={cx("st-forgot-pin-field-validation")}>
                Input required
              </div>
            )}
            {errorMsg && (
              <div className={cx("st-forgot-pin-field-validation")}>
                {errorMsg}
              </div>
            )}
            <div className={cx("st-forgot-pin-cta-set")}>
              <div
                className={cx("st-forgot-pin-confirm-btn")}
                onClick={handleConfirmClick}
              >
                <img src={confirmBtn} alt="student-forgot-pin-confirm-btn" />
              </div>

              <a href="/" className={cx("st-forgot-pin-link")}>
                Back to Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPin;
