import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./FloatDropdown.scss";

interface Props {
  children?: any;
  label: string;
  value: any;
  testId?: string;
}

const FloatDowndrop: React.FC<Props> = ({ children, label, value, testId }) => {
  const cx = classNames.bind(styles);
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";

  return (
    <div
      data-testid={`custom-float-dropdown-${testId}`}
      className={cx("float-label")}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={cx(labelClass)}>{label}</label>
    </div>
  );
};

export default FloatDowndrop;
