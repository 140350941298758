import React, { useEffect, useState, useRef, SyntheticEvent } from "react";
import classNames from "classnames/bind";
import styles from "./MicTestRecording.scss";
import { useNavigate } from "react-router-dom";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentStopRecordingIcon from "../../../assets/studentStopVoiceRecording.svg";
import studentTryAgainRecordingIcon from "../../../assets/studentTryAgainAudio.svg";
import studentYesIHearBtn from "../../../assets/studentYesIHearBtn.svg";
import studentNoTryAgainBtn from "../../../assets/studentNoTryAgainBtn.svg";
import AudioBar from "../../../shared/AudioBar";
import { useMediaStream } from "../../../contexts/MediaStreamContext";
import Cookies from "js-cookie";

const cx = classNames.bind(styles);

const MicRecording: React.FC = () => {
  const navigate = useNavigate();
  const { start, stop } = useMediaStream();

  useEffect(() => {
    handleStartRecording();
  }, []);

  // const selectedMicrophone = Cookies.get("selectedMicrophone");
  // const selectedSpeaker = Cookies.get("selectedSpeaker");
  const selectedMicrophone = localStorage.getItem("selected-microphone-id");
  const selectedSpeaker = localStorage.getItem("selected-speaker-id");
  
  

  const [recording, setRecording] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string>("");
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [audioDuration, setAudioDuration] = useState<number | null>(null);

  const checkOnboardingStatus = localStorage.getItem(
    "both_parent_email_and_mic_test_onboarding"
  );

  const handleStartRecording = async () => {
    try {
      start();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/mp4",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (error) {
      console.error("Error accessing the microphone:", error);
    }
  };

  const handleStopRecording = () => {
    stop();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
    setRecording(false);
  };

  const renderRecording = () => (
    <div
      className={cx(
        "st-mic-test-recording-rectangle-container-start-recording"
      )}
    >
      <div className={cx("st-mic-test-recording-start-recording-label")}>
        Your voice is now recording..
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={cx("st-mic-test-recording-audio-bar")}>
          <AudioBar width={"353"} height={"43"} />
        </div>
        <img
          className={cx("st-mic-test-stop-record-mic-icon")}
          src={studentStopRecordingIcon}
          alt="st-mic-test-stop-record-mic-icon"
          onClick={handleStopRecording}
        />
      </div>
    </div>
  );

  const handleLoadedMetadata = (e: SyntheticEvent<HTMLAudioElement>) => {
    const duration = e.currentTarget.duration;
    setAudioDuration(duration);
  };

  const handleTryAgainRecording = () => {
    handleStartRecording();
  };

  const handleConfirmHeardPlayback = () => {
    localStorage.setItem("mic-test-recording", "true");
    if (checkOnboardingStatus) {
      navigate("/profile");
    } else {
      navigate("/welcome");
    }
  };

  const renderPlayback = () => (
    <div
      className={cx(
        "st-mic-test-recording-rectangle-container-start-recording"
      )}
    >
      <div className={cx("st-mic-test-recording-start-recording-label")}>
        <span>Recording has stopped.</span>{" "}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={cx("st-mic-test-recording-audio-bar")}>
          <audio
            className={cx("audio-large")}
            src={audioUrl}
            controls
            onLoadedMetadata={handleLoadedMetadata}
          />
        </div>
        <img
          className={cx("st-mic-test-re-record-mic-icon")}
          src={studentTryAgainRecordingIcon}
          alt="st-mic-test-re-record-mic-icon"
          onClick={handleStartRecording}
        />
      </div>
      <div className={cx("st-mic-test-recording-hear-verification-container")}>
        <div className={cx("hear-label")}>Can you hear what you recorded?</div>
        <div className={cx("hear-verification-btn-set")}>
          <img
            className={cx("st-mic-test-hear-no-try-again-icon")}
            src={studentNoTryAgainBtn}
            alt="st-mic-test-no-try-again-btn-icon"
            onClick={handleTryAgainRecording}
          />
          <img
            className={cx("st-mic-test-hear-yes-icon")}
            src={studentYesIHearBtn}
            alt="st-mic-test-yes--i-hear-btn-icon"
            onClick={handleConfirmHeardPlayback}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={cx("st-mic-test-recording-container")}>
      <div className={cx("st-mic-test-recording-rectangle-container")}>
        <div className={cx("st-mic-test-recording-contents")}>
          <img
            className={cx("st-mic-test-recording-icon")}
            src={studentMicIcon}
            alt="student-mic-test-recording-icon"
          />
          <div className={cx("st-mic-test-recording-title")}>
            Test Your Microphone
          </div>
          <div className={cx("st-mic-test-recording-subtitle-mic")}>
            Your mic is {selectedMicrophone}
          </div>
          <div className={cx("st-mic-test-recording-subtitle-speaker")}>
            Your speaker is {selectedSpeaker}
          </div>
        </div>
      </div>
      {!recording ? renderPlayback() : renderRecording()}
    </div>
  );
};

export default MicRecording;
