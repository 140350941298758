export const apiCall = async (
    url: string,
    method: string,
    body?: any,
    requireToken: boolean = false
  ) => {
    const headers: any = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, OPTIONS",
    };
  
    if (requireToken) {
      const token = localStorage.getItem("bearer-token");
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      } else {
        throw new Error("Token is required but not found");
      }
    }
  
    const requestOptions: any = {
      method,
      headers,
      redirect: "follow",
    };
  
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }
  
    try {
      const response = await fetch(url, requestOptions);
  
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.detail["error message"]);
      }
  
      return response.json();
    } catch (err:any) {
      throw new Error(err.message);
    }
  };
  
