import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames/bind";
import styles from "./ResetPassword.scss";
import systemOwnerResetPwBackground from "../../../assets/systemOwnerLoginBackground.png";
import { Form, FormGroup, Input, Label } from "reactstrap";
import soDialog from "../../../assets/soDialog.svg";
import CheckList from "../../../shared/Checklist";
import { resetPassword } from "../../../utils/WebServiceConfig";

const SPECIAL_CHAR_REGEX = /[@#$%^&*(-=_+]/;

const ResetPassword = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [password, setChangePassword] = useState("");
  const [confirmPassword, setConfirmChangePassword] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [seeRegex, setSeeRegex] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const [searchParams] = useSearchParams();
  const tokenParams = searchParams.get("token");

  useEffect(() => {
    const regexRulesFulfilled =
      localStorage.getItem("regexRulesFulfilled") === "true";

    if (
      regexRulesFulfilled &&
      password !== "" &&
      password === confirmPassword
    ) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [password, confirmPassword]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangePassword(event.target.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmChangePassword(event.target.value);
  };

  const handleOnFocus = () => {
    setSeeRegex(true);
  };

  const handleOnBlur = () => {
    setSeeRegex(false);
  };

  const postResetPassword = async (tokenParams: any, password: string) => {
    let url = resetPassword;

    var raw = JSON.stringify({
      token: tokenParams,
      password: password,
    });

    var requestOptions: any = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PATCH,OPTIONS",
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        localStorage.setItem("regexRulesFulfilled", "false");
        navigate("/admin/system-owner-password-changed");
      } else {
        let errorResponse = await response.json();
        const errorMessage = errorResponse.detail["error message"];
        if (errorResponse.detail["error code"] === "AUTH-22016") {
          toast.error("Reset failed. Invalid Email.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
            style: {
              backgroundColor: "#F05959",
            },
          });
        } else {
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
            style: {
              backgroundColor: "#F05959",
            },
          });
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleSubmit = (
    tokenParams: any,
    password: string,
    confirmPassword: string
  ) => {
    if (
      SPECIAL_CHAR_REGEX.test(password) ||
      SPECIAL_CHAR_REGEX.test(confirmPassword)
    ) {
      toast.error("Special characters are not allowed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: {
          backgroundColor: "#F05959",
        },
      });
      return;
    }
    if (password !== confirmPassword) {
      setShowErrorMsg(true);
    } else {
      setShowErrorMsg(false);
      postResetPassword(tokenParams, password);
    }
  };

  return (
    <div className={cx("system-owner-reset-pw-container")}>
      <img
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
        }}
        src={systemOwnerResetPwBackground}
        alt="system-owner-reset-pw-bg"
      />

      <div className={cx("so-reset-pw-rectangle-container")}>
        <div className={cx("so-reset-pw-rectangle")}>
          <ToastContainer />
          <div className={cx("so-reset-pw-login-container")}>
            <div className={cx("so-reset-pw-login-title")}>Reset Password</div>

            <div className={cx("so-reset-pw-login-subtitle")}>
              Enter your new password.
            </div>

            <Form className={cx("so-reset-pw-form-inputs")}>
              <FormGroup floating>
                <Input
                  className={cx("so-new-pw-input")}
                  id="so-new-pw-input"
                  name="password"
                  placeholder="New password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                />
                <Label className={cx("so-new-pw-label")} for="so-email-label">
                  New password
                </Label>
              </FormGroup>

              <FormGroup floating>
                <Input
                  className={cx("so-confirm-pw-input")}
                  id="so-confirm-pw-input"
                  name="confirm-password"
                  placeholder="Password"
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />

                <Label
                  className={cx("so-confirm-pw-label")}
                  for="so-confirm-pw-label"
                >
                  Confirm new password
                </Label>
              </FormGroup>
            </Form>
            {showErrorMsg ? (
              <div className={cx("so-reset-field-validation")}>
                Passwords do not match
              </div>
            ) : null}
            {enableButton ? (
              <div className={cx("so-reset-pw-btn-container")}>
                <button
                  className={cx("so-reset-pw-btn-confirm")}
                  onClick={() =>
                    handleSubmit(tokenParams, password, confirmPassword)
                  }
                >
                  Confirm
                </button>
              </div>
            ) : (
              <div className={cx("so-reset-pw-btn-container")}>
                <button className={cx("so-reset-pw-btn-confirm-disabled")}>
                  Confirm
                </button>
              </div>
            )}
          </div>
        </div>
        {seeRegex && (
          <div className={cx("so-reset-pw-regex-container")}>
            <img
              className={cx("so-dialog-regex")}
              src={soDialog}
              alt="system-owner-reset-pw-bg"
            />
            <div className={cx("so-reset-regex")}>
              <CheckList value={password} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
