import { useState, useEffect } from "react";
import { getAnExerciseAPI } from "utils/WebServiceConfig";

export const useGetExerciseImageS3Url = (exerciseId: string | null) => {
  const [exerciseImageS3Url, setExerciseImageS3Url] = useState<string>("");

  useEffect(() => {
    const getExerciseImageS3Url = async () => {
      /*
       * Function to get the exercise's image and store it in state,
       * by calling the /student/exercise API.
       */
      try {
        if (exerciseId) {
          const exerciseDetails = await getAnExerciseAPI(exerciseId);
          setExerciseImageS3Url(exerciseDetails?.results[0].s3);
        }
      } catch (error) {
        console.error("Error fetching this exercise's details:", error);
      }
    };

    getExerciseImageS3Url(); // invoke the above function
  }, [exerciseId]);

  return exerciseImageS3Url;
};

export const useGetExerciseImage = (exerciseId: string | null) => {
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const exerciseImageS3Url = useGetExerciseImageS3Url(exerciseId);

  // Store image in session storage
  const storeImageInSession = (key: string, base64Data: string) => {
    try {
      // Try storing in sessionStorage, if error due to full we handle them here
      sessionStorage.setItem(key, base64Data); 
    } catch (e) {
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        // Handle the case where sessionStorage is full
        // Don't have to do anything as we want sessionStorage key-value pair
        // to be blank
        console.warn('Session storage quota exceeded, falling back to API.');
      } else {
        // Handle other issues from storage
        console.error('Error storing image in sessionStorage:', e);
      }
    }
  };

  // Fetch image from session storage
  const getValidImageFromSession = (key: string) => {
    const storedData = sessionStorage.getItem(key);
  
    // Check if the stored data is a valid base64 image
    if (storedData && storedData.startsWith("data:image/")) {
      return storedData; // Return the valid base64 image
    } else {
      // If the data is not a valid image, remove it from session storage
      if (storedData) {
        sessionStorage.removeItem(key);
      }
      return null; // Return null if the data is invalid
    }
  };

  // This is a separate url call from ApiUtils to have granular control over
  // presigned controls, and less complex url calls
  const fetchImage = async (url: string) => {
    if (exerciseId) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result as string;
          setBase64Image(base64Data);
          storeImageInSession(getImageKey(exerciseId), base64Data); // Save to session storage
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  };

  // Helper to build image key in session storage
  const getImageKey = (exerciseId: string) => `image_${exerciseId}`;
  useEffect(() => {
    // Fetch the image when the URL changes
    const loadImage = () => {
      if (exerciseId) {
        // First try to fetch the image from session storage
        const cachedImage = getValidImageFromSession(getImageKey(exerciseId));
        if (cachedImage) {
          setBase64Image(cachedImage); // Load from session storage
        } else if (exerciseImageS3Url) {
          fetchImage(exerciseImageS3Url); // Fetch from S3 if not in session storage
        }
      }
    };

    // Remove any previous images stored in session (related to other exercises)
    const removePreviousImages = () => {
      const allKeys = Object.keys(sessionStorage);
      if (exerciseId) {
        allKeys.forEach(key => {
          if (key.startsWith('image_') && key !== getImageKey(exerciseId)) {
            sessionStorage.removeItem(key); // Remove old images
          }
        });
      }
    };

    // Clear previous images from the session
    removePreviousImages();
    loadImage();
  }, [exerciseId, exerciseImageS3Url]);

  return base64Image
}