import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useLogOut } from "hooks/useCheckExpiredToken";

// const Protected = ({ isLoggedIn, role, children, path }) => {
const Protected = ({ children, path }) => {
  const token = localStorage.getItem("bearer-token");
  const userRole = localStorage.getItem("role");
  // const checkToken = token !== null && token !== "";
  const navigate = useNavigate();
  const {doLogOut, checkIsTokenExpired} = useLogOut();

  useEffect(() => {
    if (checkIsTokenExpired(token)) {
      // we add this at the first check for convenience of reading
      doLogOut();
    // } else if (isLoggedIn) {
    } else {
      const exerciseId = localStorage.getItem("selectedExerciseId");
      const selectedHomeFilter =
        localStorage.getItem("selectedHomeFilter") || "Newest";
      const selectedHistoryFilter =
        localStorage.getItem("selectedFilter") || "Recent";

      const systemOwnerPaths = [
        "/admin/system-owner-create-org-initial",
        "/admin/system-owner-create-org",
        "/admin/system-owner-admin-account-initial",
        "/admin/system-owner-admin-account",
        "/admin/system-owner-admin-main-dashboard",
        "/admin/system-owner-settings-info",
        "/admin/system-owner-password-changed",
        "/admin/system-owner-main-dashboard",
        "/admin/system-owner-organisation-details",
        "/admin/system-owner-multiple-organisation-upload",
        "/admin/system-owner-multiple-school-admin-upload",
        "/admin/system-owner-admin-delete",
      ];

      const studentPaths = [
        "/welcome",
        "/add-parents-email",
        "/test-your-microphone",
        "/detected-devices",
        "/blocked-microphone",
        "/add-parents-email-pending-verification",
        "/information",
        "/information-instructions",
        "/information-exercise-begins",
        "/exercise-response-recording",
        "/no-response",
        "/oops",
        "/well-done",
        "/success-change-pin",
        "/profile",
        "/change-parents-email",
        "/home",
        "/history",
        "/parent-email-success",
        "/email-verification-parents",
        "/microphone-access-denied",
        "/microphone-recording",
      ];

      if (
        // (role === "SYSTEM_OWNER" && systemOwnerPaths.includes(path)) ||
        (userRole === "SYSTEM_OWNER" && systemOwnerPaths.includes(path))
      ) {
        navigate(path);
      } else if (
        // (role === "STUDENT" && studentPaths.includes(path)) ||
        (userRole === "STUDENT" && studentPaths.includes(path))
      ) {
        if (
          exerciseId &&
          path !== "/home" &&
          path !== "/history" &&
          path !== "/welcome" &&
          path !== "/profile"
        ) {
          navigate(`${path}?exerciseId=${exerciseId}`);
        } else if (path === "/home") {
          navigate(`/home?filter=${selectedHomeFilter}`);
        } else if (path === "/history") {
          navigate(`/history?filter=${selectedHistoryFilter}`);
        } else {
          navigate(path);
        }
      } else {
        // log out users with incorrect roles
        doLogOut();
      }
    // } else {
    //   doLogOut();
    //   navigate("/");
    }
  // }, [isLoggedIn, role, path, navigate, checkToken, token, userRole]);
  }, [path, navigate, token, userRole]);

  return children;
};

export default Protected;
