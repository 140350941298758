import React, { FunctionComponent } from "react";
import classNames from "classnames/bind";
import styles from "./NoResponse.scss";
import circularClose from "../../../assets/studentCircularClose.svg";
import field from "../../../assets/field.png";
import nonSmiley from "../../../assets/nonSmiley.svg";
import tryAgaintBtn from "../../../assets/tryAgainBtn.svg";

const NoResponse: FunctionComponent = () => {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("student-no-response-container")}>
      <div className={cx("student-no-response-image-container")}>
        <img
          className={cx("student-no-response-image")}
          src={field}
          alt="field"
        />
      </div>

      <div className={cx("student-no-response-rec-container")}>
        <img
          className={cx("student-no-response-close-btn")}
          src={circularClose}
          alt="circular-close"
        />
        <img
          className={cx("student-no-response-non-smiley")}
          src={nonSmiley}
          alt="non-smiley"
        />
        <div className={cx("student-no-response-title")}>
          Hmm… Looks like no response
        </div>

        <div className={cx("student-no-response-subtitle")}>
          Please share your response by clicking on the microphone <br />
          icon and then click the send button to submit your answer. <br />
          If you’re having trouble with your microphone, please <br />
          make sure to check it’s settings.
        </div>

        <img src={tryAgaintBtn} alt="try-again-btn" />
      </div>
    </div>
  );
};

export default NoResponse;
