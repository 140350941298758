import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./AdminAccountInitial.scss";
import soAdminAccAvatar from "../../../assets/systemOwnerAdminAccountAvatar.svg";
import NavigationBar from "../../../shared/systemOwner/NavigationBar";

const AdminAccountInitial = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  return (
    <div className={cx("so-crte-admin-init-container")}>
      <div className={cx("so-crte-admin-init-sidebar-components-container")}>
        <NavigationBar />
        <div className={cx("so-admin-init-white-container")}>
          <div className={cx("so-admin-init-title")}>Admin Accounts</div>
          <div className={cx("so-admin-white-rec-container")}>
            <img
              className={cx("so-admin-acc")}
              src={soAdminAccAvatar}
              alt="system-owner-admin-acc"
            />
            <div className={cx("so-admin-acc-building-title-container")}>
              <div className={cx("so-admin-acc-building-title-black")}>
                Create
              </div>
              <div className={cx("so-admin-acc-building-title-blue")}>
                {"Admin Account"}
              </div>
            </div>

            <div className={cx("so-admin-acc-building-subtitle")}>
              Click on the below buttons to create one or multiple Admin
              Accounts.
            </div>

            <div className={cx("so-admin-acc-building-btn-ctn")}>
              <button
                onClick={() => navigate("/admin/system-owner-admin-account")}
                className={cx("admin-acc-btn-single")}
              >
                Create Single
              </button>
              <button
                onClick={() =>
                  navigate("/admin/system-owner-multiple-school-admin-upload")
                }
                className={cx("admin-acc-btn-multiple")}
              >
                Upload Multiple
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAccountInitial;
