import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./OrganisationDetails.scss";
import NavigationBar from "../../../shared/systemOwner/NavigationBar";
import leftChevron from "../../../assets/leftChevron.svg";
import schoolEdit from "../../../assets/schoolEdit.svg";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { Select } from "antd";
// DEPRECATED: Fix webpack failed to parse source map compile warnings.
// Reference: https://stackoverflow.com/questions/71500112/antd-source-map-not-supported-in-react
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";

const { Option } = Select;

const OrganisationDetails = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  return (
    <div className={cx("so-org-details-container")}>
      <NavigationBar />
      <div className={cx("so-org-details-white-container")}>
        <div
          className={cx("so-org-details-back-container")}
          onClick={() => navigate("/admin/system-owner-admin-account-initial")}
        >
          <img
            className={cx("so-nav-left-chevron")}
            src={leftChevron}
            alt="system-owner-go-back-chevron"
          />
          <div className={cx("so-nav-back-text")}>Go Back</div>
        </div>
        <div className={cx("so-org-title-icon-container")}>
          <div className={cx("so-org-details-title")}>
            Admiralty Primary School
          </div>
          <img
            className={cx("so-org-details-img")}
            src={schoolEdit}
            alt="so-school-edit-icon"
          />
        </div>

        <div className={cx("so-org-details-rect-box")}>
          <div className={cx("so-org-details-text-container")}>
            <div className={cx("so-org-details-label-data")}>
              <div className={cx("so-org-details-data")}>299</div>
              <div className={cx("so-org-details-label")}>Students</div>
            </div>
            <div className={cx("so-org-details-label-data")}>
              <div className={cx("so-org-details-data")}>14</div>
              <div className={cx("so-org-details-label")}>Teachers</div>
            </div>

            <div className={cx("so-org-details-label-data")}>
              <div className={cx("so-org-details-data")}>2</div>
              <div className={cx("so-org-details-label")}>Grades</div>
            </div>

            <div className={cx("so-org-details-label-data")}>
              <div className={cx("so-org-details-data")}>7</div>
              <div className={cx("so-org-details-label")}>Classes</div>
            </div>

            <div className={cx("so-org-details-label-data")}>
              <div className={cx("so-org-details-data")}>2</div>
              <div className={cx("so-org-details-label")}>Admins</div>
            </div>
          </div>
        </div>
        <div className={cx("so-org-details-subtitle")}>
          Organisation Details
        </div>
        <Form className={cx("so-org-details-form-inputs")}>
          <div className={cx("so-org-details-info-container")}>
            <FormGroup floating>
              <Input
                className={cx("so-org-details-input")}
                id="so-settings-label-max-no-of-students"
                name="text"
                placeholder="Max Number of Students"
                type="text"
                value={"300"}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-max-no-of-students"
              >
                Max Number of Students
              </Label>
            </FormGroup>

            <FormGroup floating>
              <Input
                className={cx("so-org-details-input")}
                id="so-settings-label-max-no-of-students"
                name="text"
                placeholder="Max Number of Students"
                type="text"
                value={"15"}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-max-no-of-students"
              >
                Max Number of Teachers
              </Label>
            </FormGroup>

            <FormGroup floating>
              <Input
                className={cx("so-org-details-input")}
                id="so-settings-label-max-no-of-students"
                name="text"
                placeholder="Max Number of Students"
                type="text"
                value={"15"}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-max-no-of-students"
              >
                Max Number of Classes
              </Label>
            </FormGroup>

            <FormGroup floating>
              <Input
                className={cx("so-org-details-input")}
                id="so-settings-label-max-no-of-students"
                name="text"
                placeholder="Max Number of Students"
                type="text"
                value={"2"}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-max-no-of-students"
              >
                Max Number of Admins
              </Label>
            </FormGroup>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default OrganisationDetails;
