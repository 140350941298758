import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./CreateOrganisationInitial.scss";
import soBuilding from "../../../assets/soBuilding.svg";
import NavigationBar from "../../../shared/systemOwner/NavigationBar";

const CreateOrganisationInitial = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  return (
    <div className={cx("so-crte-org-init-container")}>
      <div className={cx("so-crte-org-init-sidebar-components-container")}>
        <NavigationBar />
        <div className={cx("so-org-init-white-container")}>
          <div className={cx("so-org-init-title")}>Organisations</div>
          <div className={cx("so-org-white-rec-container")}>
            <img
              className={cx("so-building")}
              src={soBuilding}
              alt="system-owner-org"
            />
            <div className={cx("so-crte-building-title-container")}>
              <div className={cx("so-crte-building-title-black")}>Create</div>{" "}
              <div className={cx("so-crte-building-title-blue")}>
                {"Organisations"}
              </div>
            </div>

            <div className={cx("so-crte-building-subtitle")}>
              Click on the below buttons to create one or multiple
              Organisations.
            </div>

            <div className={cx("so-crte-building-btn-ctn")}>
              <button
                onClick={() => navigate("/admin/system-owner-create-org")}
                className={cx("building-btn-single")}
              >
                Create Single
              </button>
              <button
                className={cx("building-btn-multiple")}
                onClick={() =>
                  navigate("/admin/system-owner-multiple-organisation-upload")
                }
              >
                Upload Multiple
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrganisationInitial;
