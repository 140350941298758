import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./BlockMic.scss";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import studentBlockMic from "../../../assets/studentBlockMic.svg";

const BlockMic: React.FC = () => {
  const cx = classNames.bind(styles);

  useEffect(() => {
    const setBlockedMic = async () => {
      try {
        await localStorage.setItem("blocked-mic", "true");
      } catch (error) {
        console.error("Error setting blocked-mic in localStorage", error);
      }
    };

    setBlockedMic();
  }, []);

  return (
    <>
      <NavigationBarHomeLogout />
      <div className={cx("st-block-mic-container")}>
        <div className={cx("st-block-mic-rectangle-container")}>
          <div className={cx("st-block-mic-contents")}>
            <img
              className={cx("st-block-mic-icon")}
              src={studentBlockMic}
              alt="student-block-mic-icon"
            />
            <div className={cx("st-block-mic-title")}>
              We are unable to access your microphone
            </div>
            <div className={cx("st-block-mic-subtitle")}>
              To use the microphone, we require your permission. Please ask your
              parent for
              <br /> permission to enable microphone access so you can use this
              feature.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockMic;
