import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Login.scss";
import studentLoginBackground from "../../../assets/studentLoginBackground.png";
import startBtn from "../../../assets/studentStartBtn.svg";
import { login, fetchStudentProfile } from "../../../utils/WebServiceConfig";
import { reactGAEvent } from "../../../shared/ReactGA";
import { initHotjar } from "../../../helpers/Hotjar";
import Hotjar from "@hotjar/browser";

const cx = classNames.bind(styles);

const Login: React.FC = () => {
  const navigate = useNavigate();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, setChangeEmail] = useState("");
  const [pin0, setPin0] = useState("");
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [jSONFlagError, setJSONFlagError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const token = localStorage.getItem("bearer-token");
  const checkToken = token !== null && token !== "";
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    if (checkToken && userRole === "STUDENT") {
      checkOnboardingStatus();
    }
  }, [checkToken, userRole]);

  //Hotjar Event
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_login");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  const checkOnboardingStatus = async () => {
    const micTestRecording =
      localStorage.getItem("mic-test-recording") === "true";

    try {
      const profile = await fetchStudentProfile();
      const parentEmail = profile.parent_email;

      if (!parentEmail || !micTestRecording) {
        navigate("/welcome");
      } else {
        await localStorage.setItem(
          "both_parent_email_and_mic_test_onboarding",
          "true"
        );
        navigate("/home");
      }
    } catch (err) {
      console.error("Error fetching student profile:", err);
      navigate("/welcome");
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeEmail(event.target.value);
  };

  const handlePinChange =
    (
      setPin: React.Dispatch<React.SetStateAction<string>>, 
      nextInputId: string | null,
      prevInputId: string | null
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const limit = 1;
      const value = event.target.value.match(/^[0-9]+$/)
        ? event.target.value.slice(0, limit)
        : ""
      setPin(value);

      if (value) {
        if (nextInputId) {
          const nextInput = document.getElementById(nextInputId);
          if (nextInput) {
            (nextInput as HTMLInputElement).focus();
          }
        }
        else {
          const startButton = document.getElementById("student-login-start-btn");
          console.log(startButton)
          if (startButton) {
            (startButton as HTMLImageElement).focus();
          }
        }
      } else { // this is to help if user decides to backspace
        if (prevInputId) {
          const prevInput = document.getElementById(prevInputId);
          if (prevInput) {
            (prevInput as HTMLInputElement).focus();
          }
        }
      }
    };
    
  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    prevInputId: string | null
  ) => {
    // Detect backspace key press
    if (event.key === 'Backspace' && event.currentTarget.value === '' && prevInputId) {
      // Prevent the backspace event from propagating and affecting the input fields
      event.preventDefault();
      // goes back to previous pin
      const prevInput = document.getElementById(prevInputId);
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Enter') {
      handleLoginButton();
    } else if (event.key === 'Backspace') {
      // Prevent the backspace event from propagating and affecting the input fields
      event.preventDefault();
      // goes back to pin5
      const prevInput = document.getElementById("pin5");
      (prevInput as HTMLInputElement).focus();
    }
  };
  
  const handleLoginButton = () => {
    setJSONFlagError(false);
    setFormSubmitted(true);

    if (
      email.length !== 0 &&
      pin0.length !== 0 &&
      pin1.length !== 0 &&
      pin2.length !== 0 &&
      pin3.length !== 0 &&
      pin4.length !== 0 &&
      pin5.length !== 0
    ) {
      let pinRes = (pin0 + pin1 + pin2 + pin3 + pin4 + pin5).toString();
      handleClickLogin(email, pinRes);
      setFormSubmitted(false);
      setChangeEmail("");
      setPin0("");
      setPin1("");
      setPin2("");
      setPin3("");
      setPin4("");
      setPin5("");
    }
  };

  const handleClickLogin = (email: string, password: string) => {
    setJSONFlagError(false);
    if (email.length === 0 || password.length === 0) {
      setJSONFlagError(true);
      setErrorMsg("Login failed. Credentials are required.");
      return;
    } else if (!email.includes("@")) {
      setJSONFlagError(true);
      setErrorMsg("Invalid email credentials");
      return;
    } else {
      reactGAEvent(
        "Login Page for Students",
        "Clicked into Login Button for Students"
      );
      postLogin(email, password);
    }
  };

  const postLogin = async (email: string, pin: string) => {
    try {
      const res = await login(email, pin);
      localStorage.setItem("role", res.payload.role);
      localStorage.setItem("bearer-token", res.auth_token);
      setJSONFlagError(false);
      await checkOnboardingStatus();
    } catch (err: any) {
      setJSONFlagError(true);
      setErrorMsg(err.message || "Error. Please try again later.");
      console.log("error", err);
    }
  };

  return (
    <div className={cx("st-login-container")}>
      <img
        className={cx("st-login-background")}
        src={studentLoginBackground}
        alt="student-login-background"
      />
      <div className={cx("st-rectangle-container")}>
        <div
          className={
            jSONFlagError ||
            (email.length === 0 && formSubmitted) ||
            (pin0.length === 0 && formSubmitted) ||
            (pin1.length === 0 && formSubmitted) ||
            (pin2.length === 0 && formSubmitted) ||
            (pin3.length === 0 && formSubmitted) ||
            (pin4.length === 0 && formSubmitted) ||
            (pin5.length === 0 && formSubmitted)
              ? cx("st-rectangle-validation")
              : cx("st-rectangle")
          }
        >
          <div className={cx("st-login-contents-container")}>
            <div className={cx("st-login-title")}>Login</div>
            <div className={cx("st-login-subtitle")}>
              Please enter your email and PIN to login.
            </div>
            <div
              className={
                jSONFlagError ||
                (email.length === 0 && formSubmitted) ||
                (pin0.length === 0 && formSubmitted) ||
                (pin1.length === 0 && formSubmitted) ||
                (pin2.length === 0 && formSubmitted) ||
                (pin3.length === 0 && formSubmitted) ||
                (pin4.length === 0 && formSubmitted) ||
                (pin5.length === 0 && formSubmitted)
                  ? cx("st-login-email-label-validation")
                  : cx("st-login-email-label")
              }
            >
              Your Email
            </div>
            <input
              className={
                email.length === 0 && formSubmitted
                  ? cx("st-login-email-input-error-highlight")
                  : cx("st-login-email-input")
              }
              type="text"
              name="name"
              placeholder="Type your email here"
              value={email}
              onChange={handleEmailChange}
            />
            {email.length === 0 && formSubmitted ? (
              <div className={cx("st-login-email-input-validation")}>
                Email is required
              </div>
            ) : null}
            <div
              className={
                jSONFlagError ||
                (email.length === 0 && formSubmitted) ||
                (pin0.length === 0 && formSubmitted) ||
                (pin1.length === 0 && formSubmitted) ||
                (pin2.length === 0 && formSubmitted) ||
                (pin3.length === 0 && formSubmitted) ||
                (pin4.length === 0 && formSubmitted) ||
                (pin5.length === 0 && formSubmitted)
                  ? cx("st-login-pin-label-validation")
                  : cx("st-login-pin-label")
              }
            >
              Enter PIN
            </div>

            <div className={cx("st-login-pin-container")}>
              <input
                id="pin0"
                className={
                  pin0.length === 0 && formSubmitted
                    ? cx("st-login-circular-pin-validation")
                    : cx("st-login-circular-pin")
                }
                type="text"
                pattern="[0-9]"
                value={pin0}
                onFocus={(e) => e.target.select()}
                onInput={handlePinChange(setPin0, "pin1", null)}
              />
              <input
                id="pin1"
                className={
                  pin1.length === 0 && formSubmitted
                    ? cx("st-login-circular-pin-validation")
                    : cx("st-login-circular-pin")
                }
                type="text"
                pattern="[0-9]{1}"
                value={pin1}
                onFocus={(e) => e.target.select()}
                onInput={handlePinChange(setPin1, "pin2", "pin0")}
                onKeyDown={(e) => handleInputKeyDown(e, "pin0")}
              />
              <input
                id="pin2"
                className={
                  pin2.length === 0 && formSubmitted
                    ? cx("st-login-circular-pin-validation")
                    : cx("st-login-circular-pin")
                }
                type="text"
                pattern="[0-9]{1}"
                value={pin2}
                onFocus={(e) => e.target.select()}
                onInput={handlePinChange(setPin2, "pin3", "pin1")}
                onKeyDown={(e) => handleInputKeyDown(e, "pin1")}
              />
              <input
                id="pin3"
                className={
                  pin3.length === 0 && formSubmitted
                    ? cx("st-login-circular-pin-validation")
                    : cx("st-login-circular-pin")
                }
                type="text"
                pattern="[0-9]{1}"
                value={pin3}
                onFocus={(e) => e.target.select()}
                onInput={handlePinChange(setPin3, "pin4", "pin2")}
                onKeyDown={(e) => handleInputKeyDown(e, "pin2")}
              />
              <input
                id="pin4"
                className={
                  pin4.length === 0 && formSubmitted
                    ? cx("st-login-circular-pin-validation")
                    : cx("st-login-circular-pin")
                }
                type="text"
                pattern="[0-9]{1}"
                value={pin4}
                onFocus={(e) => e.target.select()}
                onInput={handlePinChange(setPin4, "pin5", "pin3")}
                onKeyDown={(e) => handleInputKeyDown(e, "pin3")}
              />
              <input
                id="pin5"
                className={
                  pin5.length === 0 && formSubmitted
                    ? cx("st-login-circular-pin-validation")
                    : cx("st-login-circular-pin")
                }
                type="text"
                pattern="[0-9]{1}"
                value={pin5}
                onFocus={(e) => e.target.select()}
                onInput={handlePinChange(setPin5, null, "pin4")}
                onKeyDown={(e) => handleInputKeyDown(e, "pin4")}
              />
            </div>
            {(pin0.length === 0 && formSubmitted) ||
            (pin1.length === 0 && formSubmitted) ||
            (pin2.length === 0 && formSubmitted) ||
            (pin3.length === 0 && formSubmitted) ||
            (pin4.length === 0 && formSubmitted) ||
            (pin5.length === 0 && formSubmitted) ? (
              <div className={cx("st-login-pin-validation")}>
                PIN is required.
              </div>
            ) : null}
            {jSONFlagError ? (
              <div className={cx("st-login-error-message")}>{errorMsg}</div>
            ) : null}

            <div
              className={
                (email.length === 0 && formSubmitted) ||
                (pin0.length === 0 && formSubmitted) ||
                (pin1.length === 0 && formSubmitted) ||
                (pin2.length === 0 && formSubmitted) ||
                (pin3.length === 0 && formSubmitted) ||
                (pin4.length === 0 && formSubmitted) ||
                (pin5.length === 0 && formSubmitted)
                  ? cx("st-login-forgot-pin-label-validation")
                  : cx("st-login-forgot-pin-label")
              }
            >
              Forgot PIN ?{" "}
              <a href="/forgot-pin" className={cx("st-login-forget-pin-link")}>
                Click here
              </a>
            </div>

            <div className={cx("st-login-btn")}>
              <img
                id="student-login-start-btn"
                src={startBtn}
                tabIndex={0}
                alt="student-login-start-btn"
                onClick={() => handleLoginButton()}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;