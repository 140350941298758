import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./ExerciseResponseRecording.scss";
import { useNavigate, useLocation } from "react-router-dom";
import AudioBar from "shared/AudioBar";
import { useMediaStream } from "contexts/MediaStreamContext";
// import field from "assets/scene1.png";
import homeIcon from "assets/Home.svg";
import historyIcon from "assets/History.svg";
import closeIcon from "assets/Chat History Close.svg";
import clickSubmitFloatingReminder from "assets/clickSubmitFloatingReminder.svg";
import promptMic from "assets/promptMic.svg";
import studentStopRecordingIcon from "assets/recordingSend.svg";
import clickMicFloatingReminder from "assets/clickMicFloatingReminder.svg";
import CustomChatFeed from "shared/CustomChatFeed";
import oopsSadmood from "assets/oopsSadmood.svg";
import circularClose from "assets/studentCircularClose.svg";
import yesBtn from "assets/yesBtn.svg";
import noBtn from "assets/noBtn.svg";
import nonSmiley from "assets/nonSmiley.svg"; // Add the nonSmiley image source
import tryAgainBtn from "assets/tryAgainBtn.svg"; // Add the tryAgainBtn image source
import {
  fetchExerciseChatHistory,
  newPresignedUploadAPI,
  uploadStudentAudioAPI,
  nextPromptAPI,
  fetchStudentProfile,
} from "utils/WebServiceConfig";
// import { useGetExerciseImage } from "hooks/useGetExerciseImageS3Url";
import { useGetExerciseImageS3Url } from "hooks/useGetExerciseImageS3Url";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";

// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";

// Interfaces for TypeScript type definitions
interface ChatMessage {
  id: number;
  message: string;
}

interface S3Url {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
    "x-amz-security-token": string;
  };
}

const ExerciseResponseRecording: React.FC = () => {
  // Bind class names using SCSS modules
  const cx = classNames.bind(styles);

  // React Router hooks for navigation and accessing location data
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve stored media ID and S3 URL from localStorage
  const interactionMediaIdJSON = localStorage.getItem(
    "interactionMediaIdStartExercise"
  );
  const inputS3UrlJSON = localStorage.getItem("inputS3UrlStartExercise");
  // console.log(localStorage.getItem("inputS3UrlStartExercise"));

  // State variables
  const [showHistory, setShowHistory] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [recording, setRecording] = useState<boolean>(false);
  const [useStudentIcon, setUseStudentIcon] = useState<boolean>(false);
  const [sendRecording, setSendRecording] = useState<boolean>(false);
  const [promptText, setPromptText] = useState<string>("");
  const [countdown, setCountdown] = useState<number>(600);
  const [showHomePopup, setShowHomePopup] = useState(false); // New state for the popup
  const [showNoResponsePopup, setShowNoResponsePopup] = useState(false); // New state for no-response popup

  // Refs for MediaRecorder and audio chunks
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  // State variables to hold parsed localStorage data
  const [inputS3Url, setInputS3Url] = useState<S3Url | null>(
    inputS3UrlJSON ? JSON.parse(inputS3UrlJSON) : null
  );
  const [interactionMediaId, setInteractionMediaId] = useState<string | null>(
    interactionMediaIdJSON
  );

  // State to hold selected language
  const [mtlLanguage, setMtlLanguage] = useState<string>("en-US");

  // New state to control the reminder visibility
  const [showReminder, setShowReminder] = useState(false);

  // Initialize Hotjar and trigger a specific event
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_exercise_response_recording");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  // Check session storage to see if the submit reminder has been shown before
  useEffect(() => {
    const reminderShown = sessionStorage.getItem("submitReminderShown");
    if (!reminderShown) {
      setShowReminder(true);
      sessionStorage.setItem("submitReminderShown", "true"); // Mark it as shown
    }
  }, []);

  // Using the speech recognition hook to get the transcript
  // const { transcript } = useSpeechRecognition();
  // console.log("useSpeechRecognition()", useSpeechRecognition()); // for debugging react-speech-recognition issues

  // Retrieve exerciseId from URL or localStorage
  const searchParams = new URLSearchParams(location.search);
  const exerciseId =
    searchParams.get("exerciseId") ||
    localStorage.getItem("selectedExerciseId");

  // call react hook to get exercise image s3 url
  const exerciseImageS3Url = useGetExerciseImageS3Url(exerciseId);
  // const exerciseImageS3Url = useGetExerciseImage(exerciseId);

  // Custom hook to manage media stream (e.g., starting/stopping the microphone)
  const { start, stop } = useMediaStream();


  // Fetch the student profile, set the appropriate language and start recording
  useEffect(() => {
    const fetchProfileAndSetLanguage = async () => {
      try {
        const profile = await fetchStudentProfile();

        // TODO: remove before push
        // console.log("fetchProfileAndSetLanguage profile", profile.mtl_language);
        // console.log(
        //   "fetchProfileAndSetLanguage profile is CHINESE",
        //   profile.mtl_language === "CHINESE"
        // );
        // console.log(
        //   "fetchProfileAndSetLanguage profile type",
        //   typeof profile.mtl_language
        // );
        // TODO: I think profile isn't being retrieved properly, have to investigate
        // store MTL from student's profile to state
        if (profile.mtl_language === "CHINESE") {
          setMtlLanguage("zh-sg");
        } else if (profile.mtl_language === "MALAY") {
          setMtlLanguage("ms-my");
        } else if (profile.mtl_language === "TAMIL") {
          setMtlLanguage("ta");
        }
        console.log("profile.mtl_language", profile.mtl_language);

        // only start recording after the MTL profile is retrieved
        handleStartRecording();
      } catch (error) {
        console.error("Error fetching student profile:", error);
      }
    };

    fetchProfileAndSetLanguage();
  }, []);

  // Fetch chat history based on the exerciseId
  // TODO: maybe can change this loading of Chat History to be a React Hook, like useGetExerciseImageS3Url().
  useEffect(() => {
    const getChatHistory = async () => {
      try {
        if (exerciseId) {
          const chatHistory = await fetchExerciseChatHistory(exerciseId);
          setChatHistory(chatHistory);
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };

    getChatHistory();
  }, [exerciseId]);

  // Manage the countdown for recording, stopping when it reaches 0
  useEffect(() => {
    let countdownInterval: NodeJS.Timeout | null = null;

    if (recording) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval!);
            handleStopRecording();
            return 0;
          }
          console.log(`${prevCountdown} seconds left`); // TODO: show countdown for debugging
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [recording]);

  useEffect(() => {
    let inactivityTimeout: NodeJS.Timeout | null = null;
    let redirectTimeout: NodeJS.Timeout | null = null;

    const elementsToMonitor = [
      ".icon",
      ".icons-container",
      ".no-btn",
      ".yes-btn",
      ".backtohome-popup-close-btn",
      ".try-again",
      ".try-again-close-btn",
      ".popup-actions",
      ".prompt-repeat-icon",
    ];

    const resetTimer = () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      if (redirectTimeout) clearTimeout(redirectTimeout);
      startInactivityTimer();
    };

    const startInactivityTimer = () => {
      inactivityTimeout = setTimeout(() => {
        setShowNoResponsePopup(true);
      }, 10 * 60 * 1000);

      redirectTimeout = setTimeout(() => {
        navigate("/home");
      }, 15 * 60 * 1000);
    };

    elementsToMonitor.forEach((selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        element.addEventListener("click", resetTimer);
        console.log(`Event listener added to: ${selector}`);
      });
    });

    startInactivityTimer();


    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          elementsToMonitor.forEach((selector) => {
            const elements = document.querySelectorAll(selector);
            elements.forEach((element) => {
              element.addEventListener("click", resetTimer);
              console.log(`Event listener added to: ${selector}`);
            });
          });
        }
      }
    });


    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function to clear timeouts and remove event listeners when component unmounts
    return () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      if (redirectTimeout) clearTimeout(redirectTimeout);
      observer.disconnect();

      elementsToMonitor.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          element.removeEventListener("click", resetTimer);
          console.log(`Event listener removed from: ${selector}`);
        });
      });
    };
  }, [navigate]);

  // Function to start recording audio
  const handleStartRecording = async () => {
    // console.log(SpeechRecognition.browserSupportsSpeechRecognition())
    // console.log(MediaRecorder.isTypeSupported("audio/webm"));
    // console.log(MediaRecorder.isTypeSupported("audio/ogg"));

    try {
      start();
      // SpeechRecognition.startListening({
      //   continuous: true,
      //   // language: "zh-sg",
      //   language: mtlLanguage,
      // });
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = {
        audioBitsPerSecond: 128000,
        mimeType: "audio/webm;codecs=opus",
        audioBitrateMode: "constant",
      };
      // const mediaRecorder = new MediaRecorder(stream);
      const mediaRecorder = new MediaRecorder(stream, options);

      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (error) {
      console.error("Error accessing the microphone:", error);
    }
  };

  // Function to stop recording audio and upload it to the server
  const handleStopRecording = async () => {
    stop();
    // SpeechRecognition.stopListening();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
    console.log("handleStopRecording mediaRecorderRef:", mediaRecorderRef); // TODO: for debugging
    console.log("handleStopRecording audioChunksRef:", audioChunksRef); // TODO: for debugging

    setRecording(false);
    setUseStudentIcon(true); // this is to send audio
  };
  // Function to stop recording audio and upload it to the server
  const handleStopAndSendRecording = async () => {
    stop();
    // SpeechRecognition.stopListening();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
    console.log("handleStopRecording mediaRecorderRef:", mediaRecorderRef); // TODO: for debugging
    console.log("handleStopRecording audioChunksRef:", audioChunksRef); // TODO: for debugging

    setRecording(false);
    setUseStudentIcon(true); // this was used to send audio, now used for display
    setSendRecording(true); // this is now used to send audio
  };

  // TO RECONSIDER:
  // Reconsider implementing this at the stop() level, rather than at this 
  // screen. This is a quick workaround for now
  useEffect(() => {
    // Handle browser "back" or "forward" button clicks
    const handlePopState = () => {
      handleStopRecording(); // Stop the recording when navigating back/forward
    };

    // Handle closing the page, refreshing, or navigating away
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      handleStopRecording(); // Stop the recording when the user tries to leave
    };

    // Attach the event listeners
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Function to handle home icon click and show confirmation popup
  const handleHomeClick = () => {
    setShowHomePopup(true); // Show popup on home icon click
  };

  const confirmHomeNavigation = () => {
    // TO RECONSIDER:
    // Reconsider implementing this at the stop() level, rather than at this 
    // screen. This is a quick workaround for now
    handleStopRecording();

    // Confirm and navigate to home
    navigate("/home");
  };

  const closePopup = () => {
    setShowHomePopup(false); // Close popup
  };

  // Function to close no-response popup
  const closeNoResponsePopup = () => {
    setShowNoResponsePopup(false);
    console.log("No Response Popup closed");
    navigate("/information-exercise-begins");
  };

  // Function to upload the recorded audio to the server
  const handleUploadStudentAudio = async (audioBlob: Blob) => {
    const startExerciseAPICalled =
      localStorage.getItem("startExerciseAPICalled") === "true";
    console.log(
      "handleUploadStudentAudio startExerciseAPICalled",
      startExerciseAPICalled
    ); // TODO: for debugging

    const newPresignedUploadCalled =
      localStorage.getItem("newPresignedUploadCalled") === "true";
    console.log(
      "handleUploadStudentAudio newPresignedUploadCalled",
      newPresignedUploadCalled
    ); // TODO: for debugging

    let s3Url: S3Url | null = null;
    let interactionMediaIdToUse: string | null = null;

    if (startExerciseAPICalled && !newPresignedUploadCalled) {
      s3Url = inputS3Url;
      interactionMediaIdToUse = interactionMediaId;
    } else if (newPresignedUploadCalled) {
      s3Url = JSON.parse(
        localStorage.getItem("newPresignedInputS3Url") || "null"
      );
      interactionMediaIdToUse = localStorage.getItem(
        "newPresignedInteractionMediaId"
      );
    }

    try {
      if (!s3Url) {
        throw new Error("s3Url is not defined");
      }
      if (!(audioBlob instanceof Blob)) {
        throw new Error("audioBlob is not a Blob");
      }

      const formData = new FormData();
      formData.append("key", s3Url.fields.key);
      formData.append("AWSAccessKeyId", s3Url.fields.AWSAccessKeyId);
      formData.append("policy", s3Url.fields.policy);
      formData.append("signature", s3Url.fields.signature);
      formData.append(
        "x-amz-security-token",
        s3Url.fields["x-amz-security-token"]
      );
      formData.append("file", audioBlob, "audio.webm");
      for (const [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      } // TODO: for debugging

      const response = await fetch(s3Url.url, {
        method: "POST",
        body: formData,
      });
      console.log("handleUploadStudentAudio fetch(s3Url)");

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error uploading audio: ${errorText}`);
      }

      // Call nextPromptAPI after successful upload
      const promptResponse = await nextPromptAPI(
        exerciseId!,
        interactionMediaIdToUse!
      );

      // Check if the end is true in the response and navigate to /home
      // console.log("promptResponse.end",promptResponse.end)
      if (promptResponse.end) {
        // We originally navigate back to home, but we do need to play the last
        // audio, so we set a new item in localStorage
        // navigate("/well-done");
        // return false
        localStorage.setItem("nextPromptEnd", "true");
      }

      // Save to local async storage
      localStorage.setItem("uploadAudioRecording", "true");
      localStorage.setItem(
        "nextPromptAIText",
        promptResponse.response.textInUtf8
      );
      localStorage.setItem(
        "nextPromptSoundUrl",
        promptResponse.response.soundFileUrl
      );
      localStorage.setItem("nextPromptAPICalled", "true");

      return true;
    } catch (error) {
      console.error("Error uploading student audio:", error);
      return false;
    }
  };

  // TODO: figure out why this useEffect exist, which uploads the same audio file 10 seconds after the first upload
  // Effect to upload the transcript and navigate after a delay if required
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (sendRecording) {
      // setPromptText(transcript);
      // console.log("transcript:", transcript);

      timeout = setTimeout(async () => {
        if (audioChunksRef.current.length > 0) {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/webm;codecs=opus",
            // type: "audio/wav",
          });
          console.log("useeffect audioBlob", audioBlob); // TODO: for debugging
          const success = await handleUploadStudentAudio(audioBlob);
          console.log("useeffect success", success); // TODO: for debugging
          if (success) {
            navigate("/information-exercise-begins");
          }
        }
      }, 500); // 0.5-second delay before uploading audio and navigating
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // }, [transcript, useStudentIcon]);
  }, [useStudentIcon]);

  // Function to render the recording UI component
  const renderRecording = () => {
    return (
      <div
        className={cx(
          "st-mic-test-recording-rectangle-container-start-recording"
        )}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={cx("st-mic-test-recording-audio-bar")}>
            <AudioBar
              width={"353"}
              height={"43"}
              useStudentIcon={useStudentIcon}
              // content={promptText || transcript}
              content={promptText}
            />
          </div>
        </div>
      </div>
    );
  };

  // Main render function for the component
  return (
    <div className={cx("student-exercise-recording-container")}>
      {exerciseImageS3Url ? (
        <img
          className={cx("student-exercise-recording-background-img")}
          src={exerciseImageS3Url}
          // src={field}
          alt="student-exercise-recording-background"
        />
      ) : (
        <p>Loading image...</p>
      )}
      <div className={cx("student-exercise-recording-background-chat")}>
        <div
          className={cx("icons-container", {
            "icons-container-active": showHistory,
          })}
        >
          <img
            src={homeIcon}
            alt="home-icon"
            className={cx("icon")}
            onClick={handleHomeClick} // Trigger popup on home click
          />
          <img
            src={showHistory ? closeIcon : historyIcon}
            alt={showHistory ? "close-icon" : "history-icon"}
            className={cx("icon")}
            onClick={() => setShowHistory(!showHistory)}
          />
        </div>
      </div>
      {showHistory && (
        <div className={cx("overlay")} onClick={() => setShowHistory(false)}>
          <div className={cx("chat-history-container")}>
            <div
              className={cx("chat-history-popout")}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={cx("icons-container-modal")}>
                <img
                  src={homeIcon}
                  alt="home-icon"
                  className={cx("icon")}
                  onClick={handleHomeClick}
                />
                <img
                  src={closeIcon}
                  alt="close-icon"
                  className={cx("icon")}
                  onClick={() => setShowHistory(false)}
                />
              </div>
              <CustomChatFeed messages={chatHistory} />
            </div>
          </div>
        </div>
      )}

      {showNoResponsePopup && (
        <div className={cx("backtohome-popup-overlay", "ten-min-no-response-overlay")}>
          <div className={cx("backtohome-popup-container", "ten-min-no-response")}>
            <img className={cx("backtohome-popup-close-btn")} src={circularClose} alt="circular-close" onClick={closeNoResponsePopup} />
            <img className={cx("")} src={nonSmiley} alt="Oops" />
            <h1>Hmm… Looks like no response</h1>
            <div className={cx("backtohome-popup-message")}>
              <p>
                Please share your response and click on the send button to submit your answer. If there is no response, the page will go back to the
                home page in a few minutes.
              </p>
            </div>
            <div className={cx("popup-actions")}>

              <a onClick={closeNoResponsePopup}>
                Continue to Exercise
              </a>
            </div>
          </div>
        </div>
      )}

      {showHomePopup && (
        <div className={cx("backtohome-popup-overlay")}>
          <div className={cx("backtohome-popup-container")}>
            <img
              className={cx("backtohome-popup-close-btn")}
              src={circularClose}
              alt="circular-close"
              onClick={closePopup}
            />

            <img
              className={cx("")}
              src={oopsSadmood}
              alt="circular-close"
              onClick={() => navigate(`/home?exerciseId=${exerciseId}`)}
            />
            <h1>Oops…</h1>
            <div className={cx("backtohome-popup-message")}>
              <p>
                If you go to the home page, this exercise will be marked as
                Incomplete, and you’ll need to continue it later.
              </p>
              <p>Are you sure you want to go to the home page?</p>
            </div>
            <div className={cx("popup-actions")}>
              <img
                className={cx("no-btn")}
                src={noBtn}
                alt="circular-close"
                onClick={closePopup}
              />

              <img
                className={cx("yes-btn")}
                src={yesBtn}
                alt="circular-close"
                onClick={confirmHomeNavigation}
              />
            </div>
          </div>
        </div>
      )}

      <div className={cx("student-exercise-recording-chatbox")}>
        <div className={cx("ai-prompt-container")}>{renderRecording()}</div>
        {!useStudentIcon && (
          <div className={cx("prompt-submit-container")}>
            <div className={cx("timer-container")}>
              <img
                className={cx("st-mic-test-stop-record-mic-icon")}
                src={studentStopRecordingIcon}
                alt="st-mic-test-stop-record-mic-icon"
                onClick={handleStopAndSendRecording}
              />
              {/* <span className={cx("countdown-timer")}>
                {countdown} seconds left
              </span> */}
            </div>

            {showReminder && (
              <img
                className={cx("click-submit-floating-reminder")}
                src={clickSubmitFloatingReminder}
                alt="click submit floating reminder"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExerciseResponseRecording;