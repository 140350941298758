import React, { FunctionComponent } from "react";
import classNames from "classnames/bind";
import styles from "./StudentOops.scss";
import circularClose from "../../assets/studentCircularClose.svg";
import field from "../../assets/field.png";
import zombieNonSmiley from "../../assets/zombieNonSmiley.svg";
import yesBtn from "../../assets/yesBtn.svg";
import notBtn from "../../assets/noBtn.svg";

const StudentOops: FunctionComponent = () => {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("student-oops-container")}>
      <div className={cx("student-oops-image-container")}>
        <img className={cx("student-oops-image")} src={field} alt="field" />
      </div>

      <div className={cx("student-oops-rec-container")}>
        <img
          className={cx("student-oops-close-btn")}
          src={circularClose}
          alt="circular-close"
        />
        <img
          className={cx("student-oops-non-smiley")}
          src={zombieNonSmiley}
          alt="zombie-non-smiley"
        />
        <div className={cx("student-oops-title")}>Oops…</div>

        <div className={cx("student-oops-subtitle")}>
          If you go to the home page, this exercise will be marked as
          <br />
          incomplete, and you’ll need to start it again from the beginning.{" "}
          <br />
          <br />
          Are you sure you want to go to the home page ?
        </div>

        <div className={cx("student-oops-btn-container")}>
          {" "}
          <img className={cx("student-oops-btn")} src={notBtn} alt="no-btn" />
          <img className={cx("student-oops-btn")} src={yesBtn} alt="yes-btn" />
        </div>
      </div>
    </div>
  );
};

export default StudentOops;
