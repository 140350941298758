import React, { useState, useEffect } from "react";
// import Cookies from "js-cookie";
import classNames from "classnames/bind";
import styles from "./DetectedDevices.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentRecordingIcon from "../../../assets/studentRecordMic.svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import micInfo from "../../../assets/studentMicInfo.svg";
import circularClose from "../../../assets/studentCircularClose.svg";
import studentStopRecordingIcon from "../../../assets/studentStopVoiceRecording.svg";
import studentPlayRecordingIcon from "../../../assets/studentPlayAudio.svg";
import studentTryAgainRecordingIcon from "../../../assets/studentTryAgainAudio.svg";
import studentPauseIcon from "../../../assets/studentPause.svg";
import NavigationBarFull from "../../../shared/student/NavigationBarFull";

const DetectedDevices = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const [microphones, setMicrophones] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [selectedMic, setSelectedMic] = useState("");
  const [selectedSpeaker, setSelectedSpeaker] = useState("");
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        // console.log("devices", devices);
        setMicrophones(
          devices.filter((device) => device.kind === "audioinput")
        );
        setSpeakers(devices.filter((device) => device.kind === "audiooutput"));
        const selectedMic = devices.find(devices => devices.kind === 'audioinput' && devices.label === localStorage.getItem("selected-microphone-id"));
        const selectedSpeaker = devices.find(devices => devices.kind === 'audiooutput' && devices.label === localStorage.getItem("selected-speaker-id"));
        const selectedMicId = selectedMic ? selectedMic.deviceId : '';
        const selectedSpeakerId = selectedSpeaker ? selectedSpeaker.deviceId : '';
        setSelectedMic(selectedMicId);
        setSelectedSpeaker(selectedSpeakerId);
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    fetchDevices();
  }, [selectedMic, selectedSpeaker]);

  const checkOnboardingStatus = localStorage.getItem(
    "both_parent_email_and_mic_test_onboarding"
  );

  const renderButtonInfo = () => {
    return (
      <>
        {checkOnboardingStatus ? (
          <NavigationBarFull />
        ) : (
          <NavigationBarHomeLogout />
        )}
        <div className={cx("st-detected-devices-container")}>
          <div className={cx("st-detected-devices-overlay")}>
            <div className={cx("st-detected-devices-panel")}>
              <img
                className={cx("st-detected-devices-close-btn")}
                src={circularClose}
                alt="circular-close"
                onClick={() => setShowModal(false)}
              />
              <div
                className={cx(
                  "st-detected-devices-instructions-desc-container"
                )}
              >
                <div className={cx("st-detected-devices-instructions-title")}>
                  Cool! Let's identify the buttons
                </div>

                <div
                  className={cx("st-detected-devices-instructions-icon-set")}
                >
                  <img
                    className={cx("st-detected-devices-instructions-icon")}
                    src={micInfo}
                    alt="st-detected-devices-instructions-mic-info"
                  />
                  <div
                    className={cx("st-detected-devices-instructions-label-set")}
                  >
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-title"
                      )}
                    >
                      Mic
                    </div>
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-subtitle"
                      )}
                    >
                      Press the microphone button to provide your answer.
                    </div>
                  </div>
                </div>

                <div
                  className={cx("st-detected-devices-instructions-icon-set")}
                >
                  <img
                    className={cx("st-detected-devices-instructions-icon")}
                    src={studentStopRecordingIcon}
                    alt="st-detected-devices-instructions-mic-info"
                  />
                  <div
                    className={cx("st-detected-devices-instructions-label-set")}
                  >
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-title"
                      )}
                    >
                      Stop
                    </div>
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-subtitle"
                      )}
                    >
                      Press the stop button to finish the recording.
                    </div>
                  </div>
                </div>

                <div
                  className={cx("st-detected-devices-instructions-icon-set")}
                >
                  <img
                    className={cx("st-detected-devices-instructions-icon")}
                    src={studentPlayRecordingIcon}
                    alt="st-detected-devices-instructions-mic-info"
                  />
                  <div
                    className={cx("st-detected-devices-instructions-label-set")}
                  >
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-title"
                      )}
                    >
                      Play
                    </div>
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-subtitle"
                      )}
                    >
                      Press the play button to play your recording.
                    </div>
                  </div>
                </div>

                <div
                  className={cx("st-detected-devices-instructions-icon-set")}
                >
                  <img
                    className={cx("st-detected-devices-instructions-icon")}
                    src={studentPauseIcon}
                    alt="st-detected-devices-instructions-mic-info"
                  />
                  <div
                    className={cx("st-detected-devices-instructions-label-set")}
                  >
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-title"
                      )}
                    >
                      Pause
                    </div>
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-subtitle"
                      )}
                    >
                      Press the pause button to pause your recording.
                    </div>
                  </div>
                </div>

                <div
                  className={cx("st-detected-devices-instructions-icon-set")}
                >
                  <img
                    className={cx("st-detected-devices-instructions-icon")}
                    src={studentTryAgainRecordingIcon}
                    alt="st-detected-devices-instructions-mic-info"
                  />
                  <div
                    className={cx("st-detected-devices-instructions-label-set")}
                  >
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-title"
                      )}
                    >
                      Try again
                    </div>
                    <div
                      className={cx(
                        "st-detected-devices-instructions-icon-subtitle"
                      )}
                    >
                      Press the try again button to start a new recording.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx("st-detected-devices-rectangle-container")}>
            <div className={cx("st-detected-devices-contents")}>
              <img
                className={cx("st-detected-devices-icon")}
                src={studentMicIcon}
                alt="student-detected-devices-icon"
              />

              <div className={cx("st-detected-devices-title")}>
                Test Your Microphone
              </div>
              <div className={cx("st-detected-devices-subtitle")}>
                Your microphone is
              </div>

              <div className={cx("st-detected-devices-subtitle")}>
                Your speaker is
              </div>
            </div>
          </div>

          <div
            className={cx(
              "st-detected-devices-rectangle-container-start-recording"
            )}
          >
            <div className={cx("st-detected-devices-start-recording-label")}>
              Press the microphone button to start recording.
            </div>
            <img
              className={cx("st-detected-devices-record-mic-icon")}
              src={studentRecordingIcon}
              alt="student-detected-devices-icon"
              onClick={() => navigate("/microphone-recording")}
            />
          </div>
        </div>
      </>
    );
  };

  const renderWithoutButtonInfo = () => {
    return (
      <>
        {/* <NavigationBarHomeLogout /> */}
        
        {checkOnboardingStatus ? (
          <NavigationBarFull />
        ) : (
          <NavigationBarHomeLogout />
        )}
        
        <div className={cx("st-detected-devices-container")}>
          {/* {renderButtonInfo()} */}
          <div className={cx("st-detected-devices-rectangle-container")}>
            <div className={cx("st-detected-devices-contents")}>
              <img
                className={cx("st-detected-devices-icon")}
                src={studentMicIcon}
                alt="student-detected-devices-icon"
              />

              <div className={cx("st-detected-devices-title")}>
                Test Your Microphone
              </div>
              <div className={cx("st-detected-devices-subtitle")}>
                Your microphone is
              </div>

              <Select
                sx={{
                  marginTop: "12px",
                  width: "491px",
                  height: "72px",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "3px 3px 3px #0000000A",
                  border: "1px solid #7F93B22C",
                  borderRadius: "36px",
                  opacity: 1,
                  paddingLeft: "32px",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                  font: "normal normal 600 20px/30px IBM Plex Serif",
                  color: "#5F7294",
                }}
                labelId="demo-simple-select-label"
                id="microphone"
                value={selectedMic}
                onChange={handleMicChange}
              >
                {microphones.map((mic) => (
                  <MenuItem
                    sx={{
                      font: "normal normal 600 20px/30px IBM Plex Serif",
                      color: "#5F7294",
                      paddingLeft: "32px",
                    }}
                    value={mic.deviceId}
                    key={mic.deviceId}
                  >
                    {mic.label || mic.deviceId}
                  </MenuItem>
                ))}
              </Select>

              <div className={cx("st-detected-devices-subtitle")}>
                Your speaker is
              </div>
              <Select
                sx={{
                  marginTop: "12px",
                  width: "491px",
                  height: "72px",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "3px 3px 3px #0000000A",
                  border: "1px solid #7F93B22C",
                  borderRadius: "36px",
                  opacity: 1,
                  paddingLeft: "32px",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                  font: "normal normal 600 20px/30px IBM Plex Serif",
                  color: "#5F7294",
                }}
                labelId="demo-simple-select-label"
                id="speakers"
                value={selectedSpeaker}
                onChange={handleSpeakerChange}
              >
                {speakers.map((speaker) => (
                  <MenuItem
                    sx={{
                      font: "normal normal 600 20px/30px IBM Plex Serif",
                      color: "#5F7294",
                      paddingLeft: "32px",
                    }}
                    key={speaker.deviceId}
                    value={speaker.deviceId}
                  >
                    {speaker.label || speaker.deviceId}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div
            className={cx(
              "st-detected-devices-rectangle-container-start-recording"
            )}
          >
            <div className={cx("st-detected-devices-start-recording-label")}>
              Press the microphone button to start recording.
            </div>
            <img
              className={cx("st-detected-devices-record-mic-icon")}
              src={studentRecordingIcon}
              alt="student-detected-devices-icon"
              onClick={() => navigate("/microphone-recording")}
            />
          </div>
        </div>
      </>
    );
  };

  const handleMicChange = (event) => {
    setSelectedMic(event.target.value);
    const selectedDevice = microphones.find(
      (mic) => mic.deviceId === event.target.value
    );
    if (selectedDevice) {
      // Cookies.set("selectedMicrophone", selectedDevice.label);
      // we change from Cookies to local storage to prevent loss of data
      // from cache clearing
      localStorage.setItem("selected-microphone-id", selectedDevice.label);
    }
  };

  const handleSpeakerChange = (event) => {
    setSelectedSpeaker(event.target.value);
    const selectedDevice = speakers.find(
      (speaker) => speaker.deviceId === event.target.value
    );
    if (selectedDevice) {
      // Cookies.set("selectedSpeaker", selectedDevice.label);
      // we change from Cookies to local storage to prevent loss of data
      // from cache clearing
      localStorage.setItem("selected-speaker-id", selectedDevice.label);
    }
  };

  return <>{showModal ? renderButtonInfo() : renderWithoutButtonInfo()}</>;
};

export default DetectedDevices;
