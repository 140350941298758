import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./EmailVerificationParents.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import disabledStudentVerifyBtn from "../../../assets/studentVerifyBtnDisabled.svg";

const EmailVerificationParents = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const [parentsEmail, setChangeParentsEmail] = useState("");

  const handleParentsEmailChange = (event: any) => {
    setChangeParentsEmail(event.target.value);
  };

  const [parentsConfirmEmail, setChangeParentsConfirmEmail] = useState("");

  const handleParentsConfirmEmailChange = (event: any) => {
    setChangeParentsConfirmEmail(event.target.value);
  };

  return (
    <>
      <NavigationBarHomeLogout />
      <div className={cx("st-add-parents-email-container")}>
        <div className={cx("st-add-parents-email-rectangle-container")}>
          <div className={cx("st-add-parents-email-contents")}>
            <div className={cx("st-add-parents-email-title")}>
              Add Parent's Email
            </div>

            <div className={cx("st-add-parents-email-subtitle")}>
              Your Parent's Email
            </div>

            <input
              id="st-add-parents-email-input-type-email"
              className={cx("st-add-parents-email-input")}
              type="text"
              name="name"
              placeholder="Type your parent's email here"
              value={parentsEmail}
              onChange={handleParentsEmailChange}
            />

            <div className={cx("st-add-parents-email-subtitle")}>
              Confirm Parent's Email
            </div>

            <input
              id="st-add-parents-email-input-confirm-email"
              className={cx("st-add-parents-email-input")}
              type="text"
              name="name"
              placeholder="Type your parent's email here"
              value={parentsConfirmEmail}
              onChange={handleParentsConfirmEmailChange}
            />
            <div
              className={cx(
                "st-add-parents-email-disabled-verify-btn-container"
              )}
            >
              <img
                className={cx("st-add-parents-email-disabled-verify-btn")}
                src={disabledStudentVerifyBtn}
                alt="student-disabled-verify-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerificationParents;
