import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Welcome.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "shared/student/NavigationBarHomeLogout";
import studentMailIcon from "assets/studentMailIcon.svg";
import studentMicIcon from "assets/studentMicIcon.svg";
import studentAddBtnIcon from "assets/studentAddBtn.svg";
import studentStartBtnIcon from "assets/studentStartBtn.svg";
import studentTryAgainDisabledIcon from "assets/studentTryAgainDisabled.svg";
import studentTestBtnIcon from "assets/studentTestBtn.svg";
import studentDisabledTestBtnIcon from "assets/studentDisabledTestBtn.svg";
import successCheckedIcon from "assets/successChecked.svg";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
import { reactGAEvent } from "shared/ReactGA";
import { fetchStudentProfile } from "utils/WebServiceConfig";

const cx = classNames.bind(styles);

const Welcome: React.FC = () => {
  const navigate = useNavigate();
  const [studentName, setStudentName] = useState<string>("");
  const [parentEmailOnboarding, setParentEmailOnboarding] = useState<string | null>(null);
  const [parentEmailPendingVerification, setParentEmailPendingVerification] =
    useState<boolean>(false);
  const [timer, setTimer] = useState<number>(180); // 3 minutes timer, to release try again button from being disabled
  const [micBlocked, setMicBlocked] = useState<boolean | null>(null);
  const [micPermissionState, setMicPermissionState] = useState<
    "granted" | "denied" | "prompt" | null
  >(null);
  const [startBtnVisible, setStartBtnVisible] = useState<boolean>(false);

  useEffect(() => {
    const checkProfileAndMicTest = async () => {
      try {
        const profile = await fetchStudentProfile();
        // const { first_name, last_name, parent_email } = profile;
        if (profile.parent_email) {
          localStorage.setItem("parent-email-onboarding", "true");
          setParentEmailOnboarding("true")
          const pendingVerification = await localStorage.getItem(
            "parent-email-pending-verification"
          );
          setParentEmailPendingVerification(pendingVerification === "true");
        }
        profile.preferred_name !== null
          ? setStudentName(profile.preferred_name)
          : setStudentName(`${profile.first_name} ${profile.last_name}`);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    checkProfileAndMicTest();
  }, []);

  useEffect(() => {
    const checkParentEmailPendingVerification = async () => {
      const pendingVerification = await localStorage.getItem(
        "parent-email-pending-verification"
      );
      const onboardingVerification = await localStorage.getItem(
        "parent-email-onboarding"
      );
      if (pendingVerification === "true") {
        setParentEmailPendingVerification(true);
        // this is to save timer prevent page refreshes from resetting timer
        const savedTimer = await localStorage.getItem("email-pending-timer");
        if (savedTimer) {
          setTimer(parseInt(savedTimer, 10));
        }
      } else if (onboardingVerification === "true") {
        setParentEmailOnboarding('true')
      }
    };

    checkParentEmailPendingVerification();
  }, []);

  useEffect(() => {
    const checkMicBlocked = async () => {
      const micBlocked = await localStorage.getItem("blocked-mic");
      setMicBlocked(micBlocked === "true");
    };

    checkMicBlocked();
  }, []);

  useEffect(() => {
    const checkMicPermissions = async () => {
      try {
        // if it is firefox, navigator.permissions does not work indicated with:
        // https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
        // else if it is any other browser, take all types of permission values
        if (navigator.userAgent.indexOf("Firefox") > -1) {
          // Because of the limitations with firefox where this is a difference
          // with the browser's stand on how permissions is controlled
          // we use this workaround and present it to user as "firefox detected,
          // microphone compatibility may be affected"
          // The fix here would then just be ensuring that the value "prompt" is 
          // always available, as the button interaction depends on whether the
          // the state is "prompt" for all other browsers
          if (micPermissionState === null || micPermissionState === "prompt") {
            setMicPermissionState("prompt");
          }
          // // the following section may help with permissions, but this is 
          // // still rather experimental
          // navigator.mediaDevices.getUserMedia({ audio: true })
          // .then((stream) => {
          //   stream.getTracks().forEach(track => track.stop());
          // })
          // .catch((error) => {
          //     setMicPermissionState("prompt");
          //     localStorage.setItem("mic-test-recording", "false")
          //     // Handle the errors
          //     if (error.name === 'NotAllowedError') {
          //         console.log('User denied the microphone permission.');
          //         // Inform the user that they need to allow microphone access
          //     } else if (error.name === 'NotFoundError') {
          //         console.log('No microphone found.');
          //         // Inform the user that no microphone was found
          //     } else {
          //         console.log('An error occurred:', error);
          //         // Handle other possible errors
          //     }
          //   }
          // )
        } else {
          const permissionStatus = await navigator.permissions.query({
            name: "microphone" as PermissionName,
          });
          setMicPermissionState(permissionStatus.state);
        }
      } catch (error) {
        console.error("Error checking microphone permissions:", error);
      }
    };

    checkMicPermissions();
  }, []);

  // For every second check if the status has changed, if status changed or 
  // timer is up, undo the disable (later in the code)
  useEffect(() => {
    if (parentEmailPendingVerification && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          localStorage.setItem("email-pending-timer", newTimer.toString());
          if (newTimer <= 0 || parentEmailOnboarding === "true") {
            clearInterval(interval);
            setParentEmailPendingVerification(false);
            localStorage.setItem("parent-email-pending-verification", "false");
            localStorage.removeItem("email-pending-timer");
          }
          return newTimer;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [parentEmailPendingVerification, timer]);



  // This is a portion of the code that is for event listening, which changes
  // parent email to success once there is update via a new tab
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      // console.log("event.key", event.key);
      // console.log("event.newValue", event.newValue);
      // if (event.key === 'parent-email-onboarding' && event.newValue === 'true') {
      //   setParentEmailOnboarding("false");
      //   localStorage.setItem("parent-email-onboarding", "false");
      //   localStorage.removeItem("email-pending-timer");
      // }
      // if (event.key === 'parent-email-pending-verification' && event.newValue === 'true') {
      //   setParentEmailPendingVerification(false);
      //   localStorage.setItem("parent-email-pending-verification", "false");
      //   localStorage.removeItem("email-pending-timer");
      // }
      // we can do the above, but this was built in the situation where the 
      // email validation may fail due to other obscure reasons, we at least 
      // check back with backend to validate the data
      if (event.key === 'parent-email-onboarding' && event.newValue === 'true') {
        const checkProfileAndMicTest = async () => {
          try {
            const profile = await fetchStudentProfile();
            const { parent_email } = profile;
            if (parent_email) {
              localStorage.setItem("parent-email-onboarding", "true");
              const pendingVerification = await localStorage.getItem(
                "parent-email-pending-verification"
              );
              setParentEmailPendingVerification(pendingVerification === "false");
              setParentEmailOnboarding("true");
            }
          } catch (error) {
            console.error("Error fetching profile:", error);
          }
        };

        checkProfileAndMicTest()
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [parentEmailPendingVerification, parentEmailOnboarding]);

  // console.log("parentEmailOnboarding", parentEmailOnboarding);
  const micTestRecording = localStorage.getItem("mic-test-recording");

  //Hotjar Event
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_welcome");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  // COMMENTING OUT THIS TO ALLOW SCROLL, SO THAT START BUTTON CAN BE REACHED
  // FOR SCREENS THAT ARE SMALLER (15" SCREEN OF LENOVO P15)
  // TO REFACTOR THIS WHEN REFACTOR FOR WEBPAGE RESIZE
  // useEffect(() => {
  //   if (startBtnVisible) {
  //     document.body.classList.remove("no-scroll");
  //   } else {
  //     document.body.classList.add("no-scroll");
  //   }
  // }, [startBtnVisible]);

  // useEffect(() => {
  //   if (micPermissionState === "denied") {
  //     document.body.classList.remove("no-scroll");
  //   } else {
  //     document.body.classList.add("no-scroll");
  //   }
  // }, [micPermissionState]);

  useEffect(() => {
    if (parentEmailOnboarding === "true" && micTestRecording === "true") {
      setStartBtnVisible(true);
    } else {
      setStartBtnVisible(false);
    }
  }, [parentEmailOnboarding, micTestRecording]);

  const addBtnHandler = () => {
    if (parentEmailOnboarding !== "true") {
      navigate("/add-parents-email");
    }
  };

  //React Google Analytics 4 (GA4) Event
  const testBtnHandler = () => {
    if (micPermissionState === "prompt" || micPermissionState === "granted") {
      reactGAEvent(
        "Welcome Page for Students",
        "Clicked into Welcome Page for Students"
      );
    }
    // match the button displays and;
    // we do not let granted re-test, but this will be different from My Profile
    if (micPermissionState === "prompt" || micTestRecording !== "true") {
      navigate("/test-your-microphone");
    }
  };

  const startBtnHandler = async () => {
    await localStorage.setItem(
      "both_parent_email_and_mic_test_onboarding",
      "true"
    );
    navigate("/home");
  };

  return (
    <div className={cx("st-welcome-nav-container")}>
      <NavigationBarHomeLogout />
      <div className={cx("st-welcome-container")}>
        <div className={cx("st-welcome-container-content")}>
          <div className={cx("st-welcome-title")}>
            Hello {studentName}, Welcome!
          </div>
          <div className={cx("st-welcome-subtitle")}>
            Let's do two things first before we start the exercises. Ask your
            parents to help you with these steps.
          </div>
          <div className={cx("st-welcome-rectangle-container")}>
            <img
              className={cx("st-welcome-mail-icon")}
              src={studentMailIcon}
              alt="student-welcome-mail-icon"
            />
            <div className={cx("st-welcome-label-container")}>
              <div className={cx("st-welcome-label-title")}>
                1. Parent's Email
              </div>
              <div className={cx("st-welcome-label-subtitle")}>
                Make your account safer by adding your parent's email. Click "Add" and enter their email.
              </div>
              {(parentEmailOnboarding !== "true" && parentEmailPendingVerification) && (
                <div className={cx("st-welcome-try-again-email-subtitle")}>
                  We sent an email verification link to your parent's email. If you don't receive it, try again in a little while.
                </div>
              )}
            </div>

            <div className={cx(
              "st-welcome-icon-label-container",
              {

                "try-again": parentEmailPendingVerification
              }
            )}>
              {parentEmailOnboarding === "true"
                ? (
                  <img
                    onClick={addBtnHandler}
                    className={cx("st-welcome-add-btn")}
                    src={successCheckedIcon}
                    alt="student-welcome-test-btn"
                  />
                )
                : parentEmailPendingVerification
                  ? (
                    <img
                      className={cx("st-welcome-add-btn")}
                      src={studentTryAgainDisabledIcon}
                      alt="student-welcome-test-btn"
                    />
                  )
                  : (
                    <img
                      onClick={addBtnHandler}
                      className={cx("st-welcome-add-btn")}
                      src={studentAddBtnIcon}
                      alt="student-welcome-test-btn"
                    />
                  )
              }
              {parentEmailOnboarding === "true" && (
                <div className={cx("st-welcome-success-label")}>Success</div>
              )}
            </div>


          </div>

          <div
            className={cx(
              micPermissionState === "denied"
                ? "st-welcome-blocked-microphone-rectangle-container"
                : "st-welcome-rectangle-container",
              "st-welcome-microphone-container"
            )}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img
                className={cx("st-welcome-mic-icon")}
                src={studentMicIcon}
                alt="student-welcome-mic-icon"
              />
              <div className={cx("st-welcome-label-container")}>
                <div className={cx("st-welcome-label-title")}>
                  2. Test Your Microphone
                </div>
                <div className={cx("st-welcome-label-subtitle")}>
                  Make sure your Microphone works well. Click on the "Test" button to check it.
                </div>
                {
                  (navigator.userAgent.indexOf("Firefox") > -1) && (
                    <div className={cx("st-welcome-firefox-warning")}>
                      Firefox detected. Microphone may not be compatible.
                      <br />You may still proceed if Start button is available
                    </div>
                  )
                }
                {micPermissionState === "denied" && (
                  <div className={cx("st-welcome-blocked-microphone-subtitle")}>
                    We can't use your microphone right now. To use it, we need your parents' help. Please ask them to let you enable the
                    microphone so you can use this feature.
                  </div>
                )}
              </div>
              <div className={cx("st-welcome-icon-label-container")}>
                <img
                  onClick={
                    micPermissionState === "prompt" ||
                      micPermissionState === "granted"
                      ? testBtnHandler
                      : testBtnHandler
                  }
                  className={cx("st-welcome-test-btn")}
                  src={
                    micPermissionState === "denied"
                      ? studentDisabledTestBtnIcon
                      : micPermissionState === "prompt"
                        ? studentTestBtnIcon
                        : micTestRecording === "true"
                          ? successCheckedIcon
                          : studentTestBtnIcon
                  }
                  alt="student-welcome-test-btn"
                />
                {micTestRecording === "true" &&
                  micPermissionState !== "prompt" &&
                  micPermissionState !== "denied" && (
                    <div className={cx("st-welcome-success-label")}>Success</div>
                  )}
              </div>
            </div>

          </div>
          {startBtnVisible && (
            <div className={cx("st-welcome-start-btn-container")}>
              <img
                className={cx("st-welcome-start-icon")}
                src={studentStartBtnIcon}
                alt="st-welcome-start-btn"
                onClick={startBtnHandler}
              />
            </div>
          )}
        </div></div>
    </div>
  );
};

export default Welcome;
