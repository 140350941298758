import React, { FunctionComponent, useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./Profile.scss";
import { useNavigate } from "react-router-dom";
import { fetchStudentProfile } from "../../../utils/WebServiceConfig";
import NavigationBarFull from "../../../shared/student/NavigationBarFull";
import changeButton from "../../../assets/changeBtn.svg";
import testButton from "../../../assets/studentTestBtn.svg";
import Cookies from "js-cookie"; // Import Cookies
import { useLogOut } from "hooks/useCheckExpiredToken";

const Profile: FunctionComponent = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const {doLogOut, checkIsTokenExpired} = useLogOut();

  const [profile, setProfile] = useState<{
    mtl_name: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    parent_name: string;
    parent_email: string;
  } | null>(null);
  const [studentName, setStudentName] = useState<string>("en-US");

  const [microphone, setMicrophone] = useState<string | null>(null);

  const micTestRecording = localStorage.getItem("mic-test-recording");

  useEffect(() => {
    // const storedMicrophone = Cookies.get("selectedMicrophone") || null; 
    // Provide a default value
    // we change from Cookies to local storage to prevent loss of data
    // from cache clearing
    const storedMicrophone = localStorage.getItem("selected-microphone-id") || null;
    setMicrophone(storedMicrophone);

    const getProfile = async () => {
      try {
        const res = await fetchStudentProfile();
        const parentEmailJSON = res.parent_email;
        setProfile(res); // Store response in useState
        res.preferred_name !== null
          ? setStudentName(res.preferred_name)
          : setStudentName(`${res.first_name} ${res.last_name}`);

        if (!(parentEmailJSON || micTestRecording)) {
          navigate("/welcome");
        }
      } catch (error) {
        if (error instanceof Error && error.message === "Token is required but not found") {
          console.error("Caught Token is missing");
          doLogOut();
        } else if (error instanceof Error && error.message === "Signature has expired") {
          console.error("Caught Signature has expired");
          doLogOut();
        } else {
          console.error("Error fetching profile:", error);
        }
      }
    };

    getProfile();
  }, [micTestRecording]);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className={cx("student-profile-container")}>
      <NavigationBarFull />
      <div className={cx("profile-content")}>
        <div className={cx("profile-name-email")}>
          <h1 className={cx("student-name")}>
            {studentName}
          </h1>
          <a href={`mailto:${profile.email}`} className={cx("student-email")}>
            {profile.email}
          </a>
        </div>

        <div className={cx("info-item-rectangle")}>
          <div className={cx("info-profile-label-container")}>
            <span className={cx("info-label")}>Your Parent's Email</span>
            <span className={cx("info-value")}>{profile.parent_email}</span>
          </div>

          <img
            src={changeButton}
            alt="Change Button Icon"
            onClick={() => navigate("/change-parents-email")}
          ></img>
        </div>

        <div className={cx("info-item-rectangle")}>
          <div className={cx("info-profile-label-container")}>
            <span className={cx("info-label")}>Your Microphone</span>
            <span className={cx("info-value")}>
              {microphone || "Loading..."}
            </span>
          </div>

          <img
            src={testButton}
            alt="Test Button Icon"
            onClick={() => navigate("/test-your-microphone")}
          ></img>
        </div>
              {
                (navigator.userAgent.indexOf("Firefox") > -1) && (
                  <div className={cx("info-label-firefox-warning")}>
                    Firefox detected. Microphone may not be compatible. 
                    <br />You may need to change the setting to remember all microphones
                  </div>
                )
              }
      </div>
    </div>
  );
};

export default Profile;
