import ReactGA from "react-ga4";

// Function to log events
export function reactGAEvent(category: string, action: string) {
  ReactGA.event("custom_event", {
    // 'custom_event' is the event name
    category: category,
    action: action,
  });
}
