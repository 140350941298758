import { apiCall } from './ApiUtils';

const { REACT_APP_BACKEND_DOMAIN } = process.env;

if (!REACT_APP_BACKEND_DOMAIN) {
  throw new Error("REACT_APP_BACKEND_DOMAIN is not defined in the environment variables.");
}

const BASE_URL = REACT_APP_BACKEND_DOMAIN.endsWith('/')
  ? REACT_APP_BACKEND_DOMAIN.slice(0, -1)
  : REACT_APP_BACKEND_DOMAIN;

// Shared APIs
export const loginAPI = `${BASE_URL}/api/v0.1/auth/login`;

// System Owner APIs
export const downloadSampleOrganisationSpreadSheet = `${BASE_URL}/api/v0.1/batch-upload/organization-template`;
export const downloadSampleSchoolAdminSpreadSheet = `${BASE_URL}/api/v0.1/batch-upload/school-admin-template`;
export const batchOrganisationUpload = `${BASE_URL}/api/v0.1/batch-upload/organization`;
export const batchSchoolAdminUpload = `${BASE_URL}/api/v0.1/batch-upload/school-admin`;
export const resetPassword = `${BASE_URL}/api/v0.1/auth/reset-password`;
export const forgetPasswordAPI = `${BASE_URL}/api/v0.1/auth/forget-password`;
export const createSingleOrganisationAPI = `${BASE_URL}/api/v0.1/organization`;
export const createSingleSchoolAdminAPI = `${BASE_URL}/api/v0.1/organization/school-admin`;

// Student APIs
export const getStudentProfileAPI = `${BASE_URL}/api/v0.1/student/profile`;
export const getNewestExercisesAPI = () => {
  const curr_time = new Date().toISOString();
  return `${BASE_URL}/api/v0.1/student/exercise-newest?start_date_ge=${curr_time}&end_date_le=${curr_time}`;
}
export const getRecentExercisesAPI = `${BASE_URL}/api/v0.1/student/exercise?status=INCOMPLETE,COMPLETED&sort=last_interaction_date&order=DESC`;
export const getExercisesByStatusAPI = (status: string) => {
  const curr_time = new Date().toISOString();
  return `${BASE_URL}/api/v0.1/student/exercise?status=${status}&start_date_ge=${curr_time}&end_date_le=${curr_time}&sort=start_date&order=DESC`
};
export const getExercisesHistoryByStatusAPI = (status: string) => `${BASE_URL}/api/v0.1/student/exercise?status=${status}&sort=start_date&order=DESC`;
export const getExerciseChatHistoryAPI = (exerciseId: string) =>
  `${BASE_URL}/api/v0.1/student/exercise/${exerciseId}/chat-history`;
export const forgotPINAPI = `${BASE_URL}/api/v0.1/student/forget-password`;
export const changeParentEmailAPI = (data: any) =>
  apiCall(`${BASE_URL}/api/v0.1/student/change-parent-email`, 'POST', data, true);

// New Parent Email Reset API
export const resetParentEmailAPI = `${BASE_URL}/api/v0.1/parent/reset-email`;

// Function to call the new API
export const resetParentEmail = async (token: string) => {
  return apiCall(resetParentEmailAPI, "POST", { token }, false);
};

// Exercise APIs
export const getAnExerciseAPI = (exerciseId: string) => 
  apiCall(`${BASE_URL}/api/v0.1/student/exercise?exercise_id=${exerciseId}`, 'GET', undefined, true)
export const startExerciseAPI = (exerciseId: string) => 
  apiCall(`${BASE_URL}/api/v0.1/student/exercise/${exerciseId}/start`, 'POST', {}, true);

export const nextPromptAPI = (exerciseId: string, interactionMediaId: string) => 
  apiCall(`${BASE_URL}/api/v0.1/student/exercise/${exerciseId}/next-prompt`, 'POST', { interaction_media_id: interactionMediaId }, true);

export const newPresignedUploadAPI = (exerciseId: string) => 
  apiCall(`${BASE_URL}/api/v0.1/student/exercise/${exerciseId}/new-presigned-upload`, 'POST', {}, true);

export const uploadStudentAudioAPI = (url: string, formData: FormData) => 
  apiCall(url, 'POST', formData, false);

export const downloadAIAudioAPI = (audioUrl: string) => 
  apiCall(audioUrl, 'GET');

// Function to fetch audio file
export const fetchAudioFile = async (url: string) => {
  return apiCall(url, 'GET');
};

// Exporting functions for API calls
export const login = (email: string, password: string) =>
  apiCall(loginAPI, 'POST', { email, password });

export const fetchStudentProfile = () =>
  apiCall(getStudentProfileAPI, 'GET', undefined, true);

export const fetchRecentExercises = async () =>
  apiCall(getRecentExercisesAPI, 'GET', undefined, true);

export const fetchNewestExercises = async () =>
  apiCall(getNewestExercisesAPI(), 'GET', undefined, true);

export const fetchExercisesByStatus = async (status: string) =>
  apiCall(getExercisesByStatusAPI(status), 'GET', undefined, true);

export const fetchExercisesHistoryByStatus = async (status: string) =>
  apiCall(getExercisesHistoryByStatusAPI(status), 'GET', undefined, true);

export const forgotPIN = async (data: any) => {
  const response = await apiCall(forgotPINAPI, 'POST', data);
  return response;
};

// System owner
export const createSingleSchoolAdmin = (data: any) =>
  apiCall(createSingleSchoolAdminAPI, 'POST', data, true);

export const createSingleOrganisation = (data: any) =>
  apiCall(createSingleOrganisationAPI, 'POST', data, true);

export const forgetPassword = async (data: any) => {
  const response = await apiCall(forgetPasswordAPI, 'POST', data);
  return response;
};

export const fetchExerciseChatHistory = async (exerciseId: string) => {
  const response = await apiCall(getExerciseChatHistoryAPI(exerciseId), 'GET', undefined, true);
  return response.results.logEntries
    .filter((entry: any) => entry.entry.unionResult.dmResult?.textInUtf8 || entry.entry.unionResult.asrResult?.textInUtf8)
    .map((entry: any, index: number) => ({
      id: entry.entry.moduleChoice,
      message: entry.entry.unionResult.dmResult?.textInUtf8 || entry.entry.unionResult.asrResult?.textInUtf8,
    }));
};
