import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./SentNewPin.scss";
import studentLoginBackground from "../../../assets/studentLoginBackground.png";
import sentNewPinMail from "../../../assets/sentNewPinMail.svg";

const SentNewPin = () => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const email = location.state?.email || "";

  const obfuscateEmail = (email: string) => {
    const [localPart, domainPart] = email.split("@");
    const obfuscatedLocalPart =
      localPart.slice(0, 3) + "******" + localPart.slice(-1);
    return `${obfuscatedLocalPart}@${domainPart}`;
  };

  return (
    <div className={cx("st-sent-new-pin-container")}>
      <img
        className={cx("st-sent-new-pin-background")}
        src={studentLoginBackground}
        alt="student-sent-new-pin-background"
      />

      <div className={cx("st-sent-new-pin-rectangle-container")}>
        <div className={cx("st-sent-new-pin-rectangle")}>
          <div className={cx("st-sent-new-pin-contents-container")}>
            <img
              className={cx("st-sent-new-pin-mail-icon")}
              src={sentNewPinMail}
              alt="st-sent-new-pin-icon"
            />
            <div className={cx("st-sent-new-pin-title")}>Sent a New PIN</div>

            <div className={cx("st-sent-new-pin-subtitle")}>
              We've sent a new PIN to your parent's email for
              <br /> at{" "}
              <span className={cx("st-sent-new-pin-email")}>
                {obfuscateEmail(email)}
              </span>{" "}
              account. Please use the new <br />
              PIN to log in.
              <div style={{ marginTop: "1.5rem" }}>
                If you don't receive it, try again in a little while.
              </div>
              <a href="/" className={cx("st-sent-new-pin-link")}>
                Back to Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentNewPin;
