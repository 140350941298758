import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import "./i18n/config";
import { MediaStreamProvider } from "contexts/MediaStreamContext";
import { AudioAnalyserProvider } from "contexts/AudioAnalyserContext";
import { InputAudioProvider } from "contexts/InputAudioContext";
// import { ExerciseImageProvider } from "contexts/ExerciseImageContext"; // deprecated
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <MediaStreamProvider video={false} audio={true}>
    <InputAudioProvider>
      <AudioAnalyserProvider>
        {/* <ExerciseImageProvider> */}
          <App />
        {/* </ExerciseImageProvider> */}
      </AudioAnalyserProvider>
    </InputAudioProvider>
  </MediaStreamProvider>
);
