import React, { FunctionComponent, useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./History.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
// import exercise1 from "assets/exercise1.png"; // stock exercise image
import continueBtn from "assets/studentContinueBtn.svg";
import startBtn from "assets/studentStartBtn.svg";
import NavigationBarFull from "shared/student/NavigationBarFull";
import {
  fetchRecentExercises,
  fetchExercisesHistoryByStatus,
  fetchStudentProfile,
  // TODO: add fetch COMPLETED exercise API
} from "utils/WebServiceConfig";
import { useLogOut } from "hooks/useCheckExpiredToken";

interface Exercise {
  exercise_id: string;
  task: string;
  status: string;
  image_id: string;
  s3: string;
  start_date: string;
  end_date: string;
  last_interaction_date: string | null;
}

const History: FunctionComponent = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const {doLogOut, checkIsTokenExpired } = useLogOut();

  const [exercises, setExercises] = useState<Exercise[] | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>("Recent");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get("filter");
    if (filter) {
      setSelectedFilter(filter);
      localStorage.setItem("selectedFilter", filter);
    } else {
      const storedFilter = localStorage.getItem("selectedFilter") || "Recent";
      setSelectedFilter(storedFilter);
      navigate(`/history?filter=${storedFilter}`, { replace: true });
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const micTestRecording = localStorage.getItem("mic-test-recording");
    const checkProfileAndMicTest = async () => {
      try {
        const profile = await fetchStudentProfile();
        const parentEmailOnboarding = profile.parent_email;
        if (!(parentEmailOnboarding && micTestRecording)) {
          navigate("/welcome");
        }
      } catch (error) {
        if (error instanceof Error && error.message === "Token is required but not found") {
          console.error("Caught Token is missing");
          doLogOut();
        } else if (error instanceof Error && error.message === "Signature has expired") {
          console.error("Caught Signature has expired");
          doLogOut();
        } else {
          console.error("Error fetching profile:", error);
        }
      }
    };
    checkProfileAndMicTest();
  }, [navigate]);

  //Hotjar Event
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_history");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  useEffect(() => {
    Hotjar.stateChange("/student-history"); // Custom state change for Hotjar recording

    const getExercises = async () => {
      setLoading(true);
      try {
        let res;
        if (selectedFilter === "Recent") {
          try {
          res = await fetchRecentExercises();
        } catch (error) {
          if (error instanceof Error && error.message === "Token is required but not found") {
            console.error("Caught Token is missing");
            doLogOut();
          } else if (error instanceof Error && error.message === "Signature has expired") {
            console.error("Caught Signature has expired");
            doLogOut();
          } else {
            console.error("Error fetching profile:", error);
          }
        }
        } else if (selectedFilter === "Completed") {
          try {
          res = await fetchExercisesHistoryByStatus("COMPLETED");
        } catch (error) {
          if (error instanceof Error && error.message === "Token is required but not found") {
            console.error("Caught Token is missing");
            doLogOut();
          } else if (error instanceof Error && error.message === "Signature has expired") {
            console.error("Caught Signature has expired");
            doLogOut();
          } else {
            console.error("Error fetching profile:", error);
          }
        }
        } else if (selectedFilter === "Incomplete") {
          try {
          res = await fetchExercisesHistoryByStatus("INCOMPLETE");
        } catch (error) {
          if (error instanceof Error && error.message === "Token is required but not found") {
            console.error("Caught Token is missing");
            doLogOut();
          } else if (error instanceof Error && error.message === "Signature has expired") {
            console.error("Caught Signature has expired");
            doLogOut();
          } else {
            console.error("Error fetching profile:", error);
          }
        }
        }
        const sortedResults = sortExerciseResults(res.results);
        setExercises(sortedResults);
      } catch (error) {
        console.error("Error fetching exercises:", error);
      } finally {
        setLoading(false);
      }
    };

    getExercises();
  }, [selectedFilter]);

  const sortExerciseResults = (results: Exercise[]) => {
    return results.sort((a, b) => {
      if (
        a.last_interaction_date === null &&
        b.last_interaction_date === null
      ) {
        return 0;
      }
      if (a.last_interaction_date === null) {
        return 1;
      }
      if (b.last_interaction_date === null) {
        return -1;
      }
      return (
        new Date(b.last_interaction_date).getTime() -
        new Date(a.last_interaction_date).getTime()
      );
    });
  };

  const handleCardClick = (exerciseId: string) => {
    localStorage.setItem("selectedExerciseId", exerciseId);
    navigate(`/information?exerciseId=${exerciseId}`);
  };

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
    localStorage.setItem("selectedFilter", filter);
    navigate(`/history?filter=${filter}`);
  };

  const renderStudentCard = (exercise: Exercise) => {
    // Convert the strings to Date objects
    const start = new Date(exercise.start_date);
    const end = new Date(exercise.end_date);
    // Use the current date and time as the target date
    const target = new Date();

    const isExpired = target >= end;
    const isIncomplete = exercise.status === "INCOMPLETE";
    const isCompleted = exercise.status === "COMPLETED";

    return (
      <div
        className={cx("student-history-cards-container")}
        key={exercise.exercise_id}
      >
        <div className={cx("student-history-cards-rect")}>
          <img
            className={cx("student-history-image")}
            // src={exercise1} // image to render for exercise student card
            src={exercise.s3} // image to render for exercise student card
            alt="exercise-1"
          />
          <div className={cx("history-card-label-container")}>
            <div
              className={cx(
                isIncomplete
                  ? "history-card-date-label-incomplete"
                  : "history-card-date-label-completed"
              )}
            >
              Last Attempt Date
            </div>
            <div
              className={cx(
                isIncomplete
                  ? "history-card-status-label-incomplete"
                  : "history-card-status-label-completed"
              )}
            >
              Status
            </div>
          </div>
          <div className={cx("history-card-data-container")}>
            <div
              className={cx(
                isIncomplete
                  ? "history-card-date-data-incomplete"
                  : "history-card-date-data-completed"
              )}
            >
              {exercise.last_interaction_date
                ? new Date(exercise.last_interaction_date).toLocaleDateString('en-GB')
                : "-"}
            </div>
            <div
              className={cx(
                isIncomplete
                  ? "history-card-status-data-incomplete"
                  : "history-card-status-data-completed"
              )}
            >
              {exercise.status}
            </div>
          </div>
          {
          isCompleted || isExpired ? null :
            <img
              className={cx("student-history-card-btn")}
              src={isIncomplete ? continueBtn : startBtn}
              alt={
                isIncomplete
                  ? "student-history-card-continue-btn"
                  : "student-history-card-start-btn"
              }
              onClick={() => handleCardClick(exercise.exercise_id)}
            />
          }
        </div>
      </div>
    );
  };

  return (
    <>
      <NavigationBarFull />
      <div className={cx("student-history-container")}>
        <div className={cx("student-history-filter-container")}>
          <div className={cx("student-history-title")}>History</div>
          <div className={cx("student-history-filter-btn-container")}>
            <div
              className={cx(
                selectedFilter === "Recent"
                  ? "student-history-selected-button"
                  : "student-history-unselected-button"
              )}
              onClick={() => handleFilterClick("Recent")}
            >
              <div className={cx("student-history-label")}>Recent</div>
            </div>
            <div
              className={cx(
                selectedFilter === "Completed"
                  ? "student-history-selected-button"
                  : "student-history-unselected-button"
              )}
              onClick={() => handleFilterClick("Completed")}
            >
              <div className={cx("student-history-label")}>Completed</div>
            </div>
            <div
              className={cx(
                selectedFilter === "Incomplete"
                  ? "student-history-selected-button"
                  : "student-history-unselected-button"
              )}
              onClick={() => handleFilterClick("Incomplete")}
            >
              <div className={cx("student-history-label")}>Incomplete</div>
            </div>
          </div>
        </div>
        <div className={cx("student-history-card-rows")}>
          {loading ? (
            <p>Loading exercises...</p>
          ) : exercises ? (
            exercises.map((exercise) => renderStudentCard(exercise))
          ) : (
            <p>No exercises found.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default History;
