import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./PasswordChanged.scss";
import systemOwnerLoginBackground from "../../../assets/systemOwnerLoginBackground.png";
import systemOwnerPasswordChanged from "../../../assets/passwordChanged.svg";


const PasswordChanged = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  return (
    <div className={cx("system-owner-password-changed-container")}>
      <img
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
        }}
        src={systemOwnerLoginBackground}
        alt="system-owner-password-changed-background"
      />
      <div className={cx("so-pw-changed-rectangle-container")}>
        <div className={cx("so-pw-changed-rectangle")}>
          <img
            style={{
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              marginTop: "72px",
              marginLeft: "190px",
              marginBottom: "16px",
            }}
            src={systemOwnerPasswordChanged}
            alt="system-owner-password-changed-icon"
          />
          <div className={cx("so-pw-changed-label")}>Password Changed</div>
          <div className={cx("so-pw-changed-sub-label")}>
            Your password has been changed successfully.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChanged;
