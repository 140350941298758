import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Welldone.scss";
// import field from "../../../assets/field.png";
import partyCracker from "../../../assets/partyCracker.svg";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
import { useGetExerciseImage } from "hooks/useGetExerciseImageS3Url";

const Welldone = () => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const [countdown, setCountdown] = useState<number>(20); // Set initial countdown value
  const navigate = useNavigate(); // Initialize useNavigate hook
  
  const searchParams = new URLSearchParams(location.search);
  const exerciseId =
    searchParams.get("exerciseId") ||
    localStorage.getItem("selectedExerciseId");

  // call react hook to get exercise image s3 url
  // const exerciseImageS3Url = useGetExerciseImageS3Url(exerciseId);
  const exerciseImageS3Url = useGetExerciseImage(exerciseId);
  
  //Hotjar Event
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_information_instructions");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);
  
  // countdown event
  useEffect(() => {
    if (countdown > 0) {
      // Decrease the countdown every second
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer); // Clean up the timer on component unmount
    } else {
      // Redirect when the countdown reaches 0
      navigate('/home');
    }
  }, [countdown, navigate]);

  return (
    <div className={cx("student-well-done-container")}>
      <div className={cx("student-well-done-image-container")}>
      {exerciseImageS3Url?(
        <img
          className={cx("student-well-done-image")}
          src={exerciseImageS3Url}
        />
      ):(<p>Loading image...</p>)}
        <div className={cx("student-well-done-image-overlay")}></div>
      </div>

      <div className={cx("student-well-done-rec-container")}>
        <img
          className={cx("student-well-done-party-cracker")}
          src={partyCracker}
          alt="party-cracker"
        />
        <div className={cx("student-well-done-title")}>Well done !</div>

        <div className={cx("student-well-done-subtitle")}>
          You have successfully completed the exercise.
        </div>
        <div className={cx("student-redirect-subtitle")}>
        Redirecting in {countdown} seconds...
        </div>
      </div>
    </div>
  );
};

export default Welldone;
