import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./InformationExerciseBegins.scss";
import { useNavigate, useLocation } from "react-router-dom";
import homeIcon from "assets/Home.svg";
import oopsSadmood from "assets/oopsSadmood.svg";
import historyIcon from "assets/History.svg";
import closeIcon from "assets/Chat History Close.svg";
import promptRepeat from "assets/promptRepeat.svg";
import promptMic from "assets/promptMic.svg";
import recordingSend from "assets/recordingSend.svg";
import clickMicFloatingReminder from "assets/clickMicFloatingReminder.svg";
import CustomChatFeed from "shared/CustomChatFeed";
import circularClose from "assets/studentCircularClose.svg";
import yesBtn from "assets/yesBtn.svg";
import noBtn from "assets/noBtn.svg";
import nonSmiley from "assets/nonSmiley.svg";
import tryAgainBtn from "assets/tryAgainBtn.svg";
import refreshBtn from "../../../assets/refresh.svg";
import { fetchExerciseChatHistory, startExerciseAPI, newPresignedUploadAPI } from "utils/WebServiceConfig";
// import { useGetExerciseImage } from "hooks/useGetExerciseImageS3Url";
import { useGetExerciseImageS3Url } from "hooks/useGetExerciseImageS3Url";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";

interface ChatMessage {
  id: number;
  message: string;
}

const InformationExerciseBegins = () => {
  const aiPromptPlaceholder = "Loading . . .";
  const [showHistory, setShowHistory] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [aiPromptText, setAiPromptText] = useState<string>(aiPromptPlaceholder);
  const [endConvo, setEndConvo] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [showHomePopup, setShowHomePopup] = useState(false);
  const [showTenMin, setShowTenMin] = useState(false);
  const [showMicAccessPopup, setShowMicAccessPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [FiveMinInactivity, setFiveMinInactivity] = useState(false); // 5-min inactivity state
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoadingErrorPopup, setShowLoadingErrorPopup] = useState(false); // First modal (loading)
  const [showAiEngineNotRespondPopup, setShowAiEngineNotRespondPopup] = useState(false); // Second modal (AI engine)

  const loadingTimerRef = useRef<NodeJS.Timeout | null>(null); // Timer for loading modal
  const aiEngineTimerRef = useRef<NodeJS.Timeout | null>(null); // Timer for AI Engine modal
  const redirectTimerRef = useRef<NodeJS.Timeout | null>(null); // Timer for redirect after AI modal

  const searchParams = new URLSearchParams(location.search);
  const exerciseId = searchParams.get("exerciseId") || localStorage.getItem("selectedExerciseId");

  const exerciseImageS3Url = useGetExerciseImageS3Url(exerciseId);
  // const exerciseImageS3Url = useGetExerciseImage(exerciseId);

  const [showReminder, setShowReminder] = useState(false);

  // Function to reset session when the user clicks "refresh" or leaves the page
  const resetSession = () => {
    sessionStorage.removeItem("refreshClicked");
    sessionStorage.removeItem("loadingShown");
  };

  //Hotjar Event
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_information_exercise_begins");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  // Reset the session on page unload to ensure a fresh state when user returns
  useEffect(() => {
    const handleBeforeUnload = () => resetSession();
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  // Add or remove "chat-loading" class based on loading state
  useEffect(() => {
    if (aiPromptText === aiPromptPlaceholder) {
      document.body.classList.add("chat-loading");
    } else {
      document.body.classList.remove("chat-loading");
    }
  }, [aiPromptText, aiPromptPlaceholder]);

  // Three-dots scenario logic: timeout for loading popup and AI engine popup
  useEffect(() => {
    const resetTimers = () => {
      clearTimeout(loadingTimerRef.current!);
      clearTimeout(aiEngineTimerRef.current!);
      clearTimeout(redirectTimerRef.current!);
    };

    const isUserRefresh = sessionStorage.getItem("isUserRefresh");

    if (isUserRefresh === "true") {
      sessionStorage.removeItem("isUserRefresh"); // Remove flag after refresh
      resetTimers(); // Reset previous timers

      // Show AI Engine modal after 5 minutes if the user refreshed the page via the "Refresh" button
      aiEngineTimerRef.current = setTimeout(() => {
        if (aiPromptText === aiPromptPlaceholder) {
          setShowAiEngineNotRespondPopup(true);
          // Start the redirect timer after showing the AI Engine modal
          redirectTimerRef.current = setTimeout(() => {
            navigate("/home"); // Redirect to home after 5 minutes of showing AI modal
          }, 5 * 60 * 1000); // 5 minutes timer for redirection
        }
      }, 5 * 60 * 1000); // 5 minutes timer for AI Engine modal
    } else {
      resetTimers(); // Reset previous timers

      // Show "loading" modal after 5 minutes if loading dots are still active
      loadingTimerRef.current = setTimeout(() => {
        if (aiPromptText === aiPromptPlaceholder) {
          setShowLoadingErrorPopup(true); // Show loading modal
        }
      }, 5 * 60 * 1000); // 5 minutes for loading modal
    }

    return () => {
      resetTimers(); // Clean up timers on unmount or navigation
    };
  }, [aiPromptText]);

  // Handle refresh button click
  const handleRefreshClick = () => {
    sessionStorage.setItem("isUserRefresh", "true"); // Mark that the refresh button was clicked
    setShowLoadingErrorPopup(false); // Close loading modal
    window.location.reload(); // Refresh the page
  };

  // Reset inactivity timers
  const resetInactivityTimer = () => {
    console.log("Resetting inactivity timers"); // Debug log
    // Clear previous timers
    clearTimeout(inactivityTimers.current.fiveMin);
    clearTimeout(inactivityTimers.current.tenMin);
    clearTimeout(inactivityTimers.current.fifteenMin);

    // Set FiveMinInactivity to false when resetting timers
    setFiveMinInactivity(false);
    inactivityTimers.current.fiveMin = setTimeout(() => {
      setFiveMinInactivity(true); // Trigger after 5 minutes only if not loading and not showing placeholder
    }, 5 * 60 * 1000);

    inactivityTimers.current.tenMin = setTimeout(() => {
      // Only show ten-minute popup if loading error popup or AI engine not responding popup are not active
      if (!showLoadingErrorPopup && !showAiEngineNotRespondPopup) {
        setShowTenMin(true); // Show TenMin popup only if not loading and not showing placeholder
      }
    }, 10 * 60 * 1000);

    inactivityTimers.current.fifteenMin = setTimeout(() => {
      confirmHomeNavigation(); // Navigate to home only if not loading and not showing placeholder
    }, 15 * 60 * 1000);
  };

  const closePopup = () => {
    resetInactivityTimer(); // Reset the inactivity timer
    setShowHomePopup(false);
    setShowModal(false);
    setShowLoadingErrorPopup(false); // Close loading error popup if open
    setShowAiEngineNotRespondPopup(false); // Close AI Engine not responding popup if open
    setShowTenMin(false); // Close Ten Min popup if open
    setShowMicAccessPopup(false); // Close Mic Access popup if open
  };

  // Set reminder for mic click
  useEffect(() => {
    const reminderShown = sessionStorage.getItem("micReminderShown");
    if (!reminderShown) {
      setShowReminder(true);
      sessionStorage.setItem("micReminderShown", "true");
    }
  }, []);

  // Fetch chat history for the exercise
  useEffect(() => {
    const getChatHistory = async () => {
      try {
        if (exerciseId) {
          const chatHistory = await fetchExerciseChatHistory(exerciseId);
          setChatHistory(chatHistory);
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };

    getChatHistory();
  }, [exerciseId]);

  // Initiate exercise
  useEffect(() => {
    const initiateExercise = async () => {
      try {
        const uploadAudioRecording = localStorage.getItem("uploadAudioRecording");

        if (uploadAudioRecording === "true") {
          const nextPromptAIText = localStorage.getItem("nextPromptAIText");
          const nextPromptSoundUrl = localStorage.getItem("nextPromptSoundUrl");
          const nextPromptEnd = localStorage.getItem("nextPromptEnd");

          if (nextPromptAIText && nextPromptSoundUrl) {
            setAiPromptText(nextPromptAIText);
            playAudio(nextPromptSoundUrl, "playing from nextPromptSoundUrl");
            localStorage.setItem("uploadAudioRecording", "false");
            if (nextPromptEnd === "true") {
              localStorage.setItem("nextPromptEnd", "false");
              setEndConvo(true);
            }
            return;
          }
        } else {
          if (exerciseId) {
            const response = await startExerciseAPI(exerciseId);
            const interaction_media_id = response.interaction_media_id;
            const input_s3_url = JSON.stringify(response.input_s3_url);
            localStorage.setItem("interactionMediaIdStartExercise", interaction_media_id);
            localStorage.setItem("inputS3UrlStartExercise", input_s3_url);
            localStorage.setItem("startExerciseAPICalled", "true");
            localStorage.setItem("newPresignedUploadCalled", "false");
            const textInUtf8 = response.prompt.prompt.textInUtf8;
            const soundFileUrl = response.prompt.prompt.soundFileUrl;
            setAiPromptText(textInUtf8);
            setAudioUrl(soundFileUrl);
            playAudio(soundFileUrl, "playing from start exercise");
          }
        }
      } catch (error) {
        console.error("Error starting exercise:", error);
      }
    };

    initiateExercise();
  }, [exerciseId]);


  // Play audio based on provided URL
  const playAudio = (url: string, logMessage: string) => {
    if (audioRef.current) {
      if (audioRef.current.src !== url) {
        audioRef.current.pause();
        audioRef.current.src = url;
        audioRef.current.load();
      }
      audioRef.current
        .play()
        .then(() => console.log("Get audio successful."))
        .catch((error) => console.error("Error playing audio:", error));
    }
  };

  // Handle the click of the repeat icon
  const handleRepeatClick = () => {
    resetInactivityTimer(); // Reset the inactivity timer
    const nextPromptSoundUrl = localStorage.getItem("nextPromptSoundUrl");
    if (audioUrl) {
      playAudio(audioUrl, "playing from start exercise");
    } else if (nextPromptSoundUrl) {
      playAudio(nextPromptSoundUrl, "playing from nextPromptSoundUrl");
    }
  };

  // Handle prompt mic click
  const handlePromptMicClick = async () => {
    resetInactivityTimer(); // Reset the inactivity timer
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const nextPromptAPICalled = localStorage.getItem("nextPromptAPICalled") === "true";

      const startExerciseAPICalled = localStorage.getItem("startExerciseAPICalled") === "true";

      if (nextPromptAPICalled) {
        try {
          const response = await newPresignedUploadAPI(exerciseId!);
          localStorage.setItem("newPresignedInteractionMediaId", response.interaction_media_id);
          localStorage.setItem("newPresignedInputS3Url", JSON.stringify(response.input_s3_url));
          localStorage.setItem("newPresignedUploadCalled", "true");
          localStorage.setItem("startExerciseAPICalled", "false");
        } catch (error) {
          console.error("Error calling newPresignedUploadAPI:", error);
        }
      }
      navigate("/exercise-response-recording");
    } catch (error) {
      setShowMicAccessPopup(true);
    }
  };

  // Handle the click of the icons container
  const handleIconsContainerClick = () => {
    resetInactivityTimer(); // Reset the inactivity timer
  };

  // Handle conversation icon rendering
  const convoIcon = () => {
    const icon = endConvo ? (
      <img
        className={cx("prompt-return-icon")}
        src={recordingSend}
        alt="prompt send"
        onClick={() => navigate("/well-done")}
      />
    ) : (
      <>
        <img
          className={cx(showHistory ? "prompt-mic-icon-inactive" : "prompt-mic-icon")}
          src={promptMic}
          alt="prompt microphone icon"
          onClick={handlePromptMicClick}
        />
        {showReminder && (
          <img
            className={cx(showHistory ? "click-mic-floating-reminder-inactive" : "click-mic-floating-reminder")}
            src={clickMicFloatingReminder}
            alt="click mic floating reminder"
          />
        )}
      </>
    );

    // If FiveMinInactivity is reset to false (or 0), skip the animated block and return only the else value
    if (!FiveMinInactivity) {
      return <div className={cx("prompt-mic-container")}>{icon}</div>;
    } else {
      return (
        <div className={cx("prompt-mic-container animate__animated animate__slow animate__fadeIn animate__infinite")}>
          <div id="outerContainer">
            <div id="container">
              <div className={cx("item")}>{icon}</div>
              <div className={cx("circle")}></div>
              <div className={cx("circle")}></div>
              <div className={cx("circle")}></div>
              <div className={cx("circle")}></div>
            </div>
          </div>
        </div>
      );
    }
  };

  const closeMicAccessPopup = () => {
    setShowMicAccessPopup(false);
  };

  const handleHomeClick = () => {
    resetInactivityTimer(); // Reset the inactivity timer
    setShowHomePopup(true);
  };

  const confirmHomeNavigation = () => {
    navigate("/home");
  };

  // Inactivity timer logic
  const inactivityTimers = useRef<any>({ fiveMin: null, tenMin: null, fifteenMin: null });

  // Inactivity timer logic for loading
  const inactivityTimersLoad = useRef<any>({ fiveMin: null, tenMin: null, fifteenMin: null });

  // Start the timer on component mount
  useEffect(() => {
    console.log("Component mounted, starting inactivity timers..."); // Debug log
    resetInactivityTimer(); // Start the timer as soon as the component mounts

    // Add click event listeners for specified elements
    const elementsToMonitor = [
      ".prompt-repeat-icon",
      ".icon",
      ".no-btn",
      ".yes-btn",
      ".backtohome-popup-close-btn",
      ".try-again",
      ".try-again-close-btn",
    ];

    const resetOnClick = () => {
      console.log("User interaction detected, resetting inactivity timer."); // Debug log
      resetInactivityTimer(); // Reset timer on any click
    };

    elementsToMonitor.forEach((selector) => {
      document.querySelectorAll(selector).forEach((element) => {
        element.addEventListener("click", resetOnClick);
      });
    });

    return () => {
      // Clear timers on component unmount
      console.log("Component unmounted, clearing inactivity timers."); // Debug log
      clearTimeout(inactivityTimers.current.fiveMin);
      clearTimeout(inactivityTimers.current.tenMin);
      clearTimeout(inactivityTimers.current.fifteenMin);

      // Remove click event listeners
      elementsToMonitor.forEach((selector) => {
        document.querySelectorAll(selector).forEach((element) => {
          element.removeEventListener("click", resetOnClick);
        });
      });
    };
  }, []); // Empty dependency array ensures that this runs only once when the component mounts

  return (
    <div className={cx("student-information-exercise-container")}>
      {exerciseImageS3Url ? (
        <img
          className={cx("student-information-exercise-background-img")}
          src={exerciseImageS3Url}
          alt="student-information-exercise-background"
        />
      ) : (<p>Loading image...</p>)}
      <div className={cx("student-information-exercise-background-chat")}>
        <div
          className={cx("icons-container", {
            "icons-container-active": showHistory,
          })}
          onClick={handleIconsContainerClick} // Reset timer on clicking icons container
        >
          <img src={homeIcon} alt="home-icon" className={cx("icon")} onClick={handleHomeClick} />
          <img
            src={showHistory ? closeIcon : historyIcon}
            alt={showHistory ? "close-icon" : "history-icon"}
            className={cx("icon")}
            onClick={() => setShowHistory(!showHistory)}
          />
        </div>
      </div>

      {/* Modal for 1st popup (Loading) */}
      {showLoadingErrorPopup && (
        <div className={cx("backtohome-popup-overlay ten-min-no-response-overlay loading")}>
          <div className={cx("backtohome-popup-container ten-min-no-response")}>
            <img className={cx("")} src={oopsSadmood} alt="Oops" onClick={() => navigate(`/home?exerciseId=${exerciseId}`)} />
            <h1>Oops… </h1>
            <div className={cx("backtohome-popup-message")}>
              <p>
                It looks like something isn’t working right. Can you press the “Refresh” button below? That might help
                fix it!
              </p>
            </div>
            <div className={cx("popup-actions")}>
              <img className={cx("refresh")} src={refreshBtn} alt="refresh" onClick={handleRefreshClick} />
            </div>
          </div>
        </div>
      )}

      {/* Modal for 2nd popup (AI Engine Not Responding) */}
      {showAiEngineNotRespondPopup && (
        <div className={cx("backtohome-popup-overlay ten-min-no-response-overlay ai-engine-not-respond")}>
          <div className={cx("backtohome-popup-container ten-min-no-response")}>
            <img className={cx("backtohome-popup-close-btn ")} src={circularClose} alt="circular-close" onClick={closePopup} />
            <img className={cx("")} src={nonSmiley} alt="Oops" onClick={() => navigate(`/home?exerciseId=${exerciseId}`)} />
            <h1>We’re sorry!</h1>
            <div className={cx("backtohome-popup-message")}>
              <p>
                It looks like it still isn't working right. Please contact your teacher. The page will go back to the
                home page in a few minutes.
              </p>
            </div>

          </div>
        </div>
      )}

      {showHistory && (
        <div className={cx("overlay")} onClick={() => setShowHistory(false)}>
          <div className={cx("chat-history-container")}>
            <div className={cx("chat-history-popout")} onClick={(e) => e.stopPropagation()}>
              <div className={cx("icons-container-modal")}>
                <img src={homeIcon} alt="home-icon" className={cx("icon")} onClick={handleHomeClick} />
                <img src={closeIcon} alt="close-icon" className={cx("icon")} onClick={() => setShowHistory(false)} />
              </div>
              <CustomChatFeed messages={chatHistory} />
            </div>
          </div>
        </div>
      )}

      {showMicAccessPopup && (
        <div className={cx("backtohome-popup-overlay mic-permission-denied")}>
          <div className={cx("backtohome-popup-container")}>
            <img className={cx("backtohome-popup-close-btn ")} src={circularClose} alt="circular-close" onClick={closeMicAccessPopup} />

            <img
              className={cx("")}
              src={oopsSadmood}
              alt="circular-close"
              onClick={() => navigate(`/home?exerciseId=${exerciseId}`)}
            />
            <h1>Oops…We can’t access your mic</h1>

            <p>To use the microphone, we require your permission.</p>
            <p>
              Please ask your parents for permission to enable microphone
              access so you can use this feature.
            </p>
            <a onClick={confirmHomeNavigation}>Go to Home Page</a>
          </div>
        </div>
      )}

      {showHomePopup && (
        <div className={cx("backtohome-popup-overlay goback-to-home")}>
          <div className={cx("backtohome-popup-container")}>
            <img className={cx("backtohome-popup-close-btn ")} src={circularClose} alt="circular-close" onClick={closePopup} />
            <img className={cx("")} src={nonSmiley} alt="Oops" onClick={() => navigate(`/home?exerciseId=${exerciseId}`)} />
            <h1>Oops…</h1>
            <div className={cx("backtohome-popup-message")}>
              <p>
                If you go to the home page, this exercise will be marked as
                Incomplete, and you’ll need to continue it later.
              </p>
              <p>Are you sure you want to go to the home page?</p>
            </div>
            <div className={cx("popup-actions")}>
              <img className={cx("no-btn")} src={noBtn} alt="circular-close" onClick={closePopup} />
              <img className={cx("yes-btn")} src={yesBtn} alt="circular-close" onClick={confirmHomeNavigation} />
            </div>
          </div>
        </div>
      )}

      {/* Show TenMin popup only if loading error and AI engine not responding popups are not active */}
      {!showLoadingErrorPopup && !showAiEngineNotRespondPopup && showTenMin && (
        <div className={cx("backtohome-popup-overlay  ten-min-no-response-overlay ten-min-inactivity ")}>
          <div className={cx("backtohome-popup-container ten-min-no-response")}>
            <img className={cx("backtohome-popup-close-btn ")} src={circularClose} alt="circular-close" onClick={closePopup} />
            <img className={cx("")} src={nonSmiley} alt="Oops" />
            <h1>Hmm… Looks like no response</h1>
            <div className={cx("backtohome-popup-message")}>
              <p>
                Please share your response by clicking on the microphone icon
                and then click the send button to submit your answer. If you’re
                having trouble with your microphone, please make sure to check
                it’s settings.
              </p>
            </div>
            <div className={cx("popup-actions")}>
              <img className={cx("yes-btn")} src={tryAgainBtn} alt="circular-close" onClick={closePopup} />
            </div>
          </div>
        </div>
      )}

      <div className={cx("student-information-exercise-chatbox")}>
        <div className={cx("ai-prompt-container")}>
          <div className={cx("ai-prompt-text")}>{aiPromptText}</div>
        </div>
        {aiPromptText !== aiPromptPlaceholder && (
          <img className={cx("prompt-repeat-icon")} src={promptRepeat} alt="prompt repeat icon" onClick={handleRepeatClick} />
        )}
        {aiPromptText !== aiPromptPlaceholder && convoIcon()}
      </div>
      <audio ref={audioRef} style={{ display: "none" }} />
    </div>
  );
};

export default InformationExerciseBegins;