import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./MicAccessDenied.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import studentMailIcon from "../../../assets/studentMailIcon.svg";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentGreenCheckedIcon from "../../../assets/studentChecked.png";
import studentTestBtnIconDisabled from "../../../assets/studentTestBtnDisabled.svg";
import { fetchStudentProfile } from "utils/WebServiceConfig";

const MicAccessDenied = () => {
  // State to hold name
  const [studentName, setStudentName] = useState<string>("en-US");
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  // Fetch the student profile for name
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await fetchStudentProfile();

        profile.preferred_name !== null
          ? setStudentName(profile.preferred_name)
          : setStudentName(`${profile.first_name} ${profile.last_name}`);
      } catch (error) {
        console.error("Error fetching student profile:", error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <>
      <NavigationBarHomeLogout />
      <div className={cx("st-mic-access-denied-container")}>
        <div className={cx("st-mic-access-denied-title")}>
          Hello {studentName}, Welcome !
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;
        </div>
        <div className={cx("st-mic-access-denied-subtitle")}>
          Let's do two things first before we start the exercises. Ask your
          parents to help you with these
          <br /> steps.
        </div>
        <div className={cx("st-mic-access-denied-rectangle-container")}>
          <img
            className={cx("st-mic-access-denied-mail-icon")}
            src={studentMailIcon}
            alt="st-mic-access-denied-mail-icon"
          />
          <div className={cx("st-mic-access-denied-label-container-mail")}>
            <div className={cx("st-mic-access-denied-label-title-mail")}>
              1. Parent's Email
            </div>
            <div className={cx("st-mic-access-denied-label-subtitle-mail")}>
              Make your account safer by adding your parent's <br />
              email. Click "Add" and enter their email.
            </div>
          </div>
          <div className={cx("st-mic-access-denied-checked-icon-set")}>
            <img
              className={cx("st-mic-access-denied-checked-icon")}
              src={studentGreenCheckedIcon}
              alt="st-mic-access-denied-success-checked-icon"
            />
            <div className={cx("st-mic-access-denied-checked-label")}>
              Success
            </div>
          </div>
        </div>
        <div
          className={cx(
            "st-mic-access-denied-rectangle-container",
            "st-mic-access-denied-microphone-container"
          )}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              className={cx("st-mic-access-denied-mic-icon")}
              src={studentMicIcon}
              alt="st-mic-access-denied-success-mic-icon"
            />
            <div className={cx("st-mic-access-denied-label-container")}>
              <div className={cx("st-mic-access-denied-label-title")}>
                2. Test Your Microphone
              </div>
              <div className={cx("st-mic-access-denied-label-subtitle")}>
                Make sure your Microphone works well. Click on the "Test" button to check it.
              </div>
            </div>

            <img
              className={cx("st-mic-access-denied-test-btn-disabled")}
              src={studentTestBtnIconDisabled}
              alt="st-mic-access-denied-success-test-btn-disabled"
            />
          </div>
          <div className={cx("st-mic-access-denied-highlight-desc")}>
            We can’t use your microphone right now. To use it, we need your
            parents’ <br />
            help. Please ask them to let you enable the microphone so you can
            <br />
            use this feature.
          </div>
        </div>
      </div>
    </>
  );
};

export default MicAccessDenied;
