// import React, { useEffect, useState } from "react";
import React from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";


const checkIsTokenExpired = (token: string) => {
  /*
   * Function to check whether token has expired.
   * Returns 
   *  - true, if token has expired.
   *  - null, if token is missing
   */

  if (token === null || token === "") {
    return null;
  }

  try {
    const decodedTokenObject = jwtDecode(token);
    // check if exp exists and if it does, return comparison of current 
    // time to expiry
    // Also, there is some noticable expiry of additional ~5 minutes, but have 
    // left it out unless there is a need to be extra fine (5*60*1000=300000)
    // const isExpired = decodedTokenObject.exp ? Date.now() >= decodedTokenObject.exp * 1000 - 300000: true;
    const isExpired = decodedTokenObject.exp ? Date.now() >= decodedTokenObject.exp * 1000: true;
    return isExpired;
  } catch (error) {
    // jwtDecode might throw InvalidTokenError if token is not in the correct format
    return true; // return true if error (for now)

    // TODO: maybe add some checks to distinguish the different errors?
    // if (error.name === "InvalidTokenError") {
    //   return true;
    // }
    // return true;
  }
};

export const useLogOut = () => {
  const navigate = useNavigate();
  const doLogOut = () => {
    /*
    * Function to log user out (by dispatching the logOut action in redux)
    * and navigating the page to /login.
    */
    localStorage.setItem("role", "");
    localStorage.setItem("bearer-token", "");
    localStorage.setItem("parent-email-onboarding", "");
    localStorage.setItem("parent-email-pending-verification", "");
    localStorage.setItem("both_parent_email_and_mic_test_onboarding", "");
    localStorage.removeItem("selectedExerciseId");
    localStorage.removeItem("selectedFilter");
    localStorage.removeItem("selectedHomeFilter");
    localStorage.removeItem("email-pending-timer");
    // sessionStorage.clear();
    // toast.error("Error: Your session has timed out. Please re-login.", {
    //   id: "AUTH-11002",
    // });

    // dismiss all previous toast
    toast.dismiss(); 
    toast.error("Error: Your session has timed out. Please re-login.");
    navigate("/");
  }
  return {doLogOut, checkIsTokenExpired};
};



// export const checkIsTokenExpired = (token: string | null) => {
//     /*
//      * Function to check whether token has expired.
//      * Returns 
//      *  - true, if token has expired.
//      *  - null, if token is missing
//      */
  
//     if (token === null || token === "") {
//       return null;
//     }
  
//     try {
//       const decodedTokenObject = jwtDecode(token);
//       // check if exp exists and if it does, return comparison of current 
//       // time to expiry
//       // Also, there is some noticable expiry of additional 5 minutes, but have 
//       // left it out unless there is a need to be extra fine (5*60*1000=300000)
//       const isExpired = decodedTokenObject.exp ? Date.now() >= decodedTokenObject.exp * 1000 - 300000: true;
//       // const isExpired = decodedTokenObject.exp ? Date.now() >= decodedTokenObject.exp * 1000: true;
//       console.log("isExpired",isExpired);
//       console.log("decodedTokenObject.exp",decodedTokenObject.exp ? decodedTokenObject.exp * 1000 - 300000 : null);
//       console.log("Date.now()",Date.now());
//       return isExpired;
//     } catch (error) {
//       // jwtDecode might throw InvalidTokenError if token is not in the correct format
//       return true; // return true if error (for now)
  
//       // TODO: maybe add some checks to distinguish the different errors?
//       // if (error.name === "InvalidTokenError") {
//       //   return true;
//       // }
//       // return true;
//     }
//   };
  

// export const useCheckTokenExpiry = (token: string | null) => {
//   /*
//    * React hook to check whether JWT token has expired.
//    */

//   // init hook states
//   const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null); // used to track if token has expired
//   const navigate = useNavigate();
//   const doLogOut = () => {
//     /*
//      * Function to log user out (by dispatching the logOut action in redux)
//      * and navigating the page to /login.
//      */
//     localStorage.setItem("role", "");
//     localStorage.setItem("bearer-token", "");
//     localStorage.setItem("parent-email-onboarding", "");
//     localStorage.setItem("parent-email-pending-verification", "");
//     localStorage.setItem("both_parent_email_and_mic_test_onboarding", "");
//     localStorage.removeItem("selectedExerciseId");
//     localStorage.removeItem("selectedFilter");
//     localStorage.removeItem("selectedHomeFilter");
//     localStorage.removeItem("email-pending-timer");
//     navigate("/");
//   }

//   const logOutIfTokenExpired = () => {
//     /*
//      * Function to log user out when token has expired.
//      */
//     const tokenExpiryStatus = checkIsTokenExpired(token)
//     if (tokenExpiryStatus) {
//       setIsTokenExpired(true);
//       doLogOut();
//       // toast.error("Error: Your session has timed out. Please re-login.", {
//       //   id: "AUTH-11002",
//       // });
//     } else if (tokenExpiryStatus === null) {
//       setIsTokenExpired(null);
//       doLogOut();
//       // toast.error("You are not logged in. Please login.", {
//       //   id: "AUTH-11002",
//       // });
//     } 
//     else {
//       setIsTokenExpired(false);
//     }
//   };

//   useEffect(() => {
//     logOutIfTokenExpired();

//     // Optional: Polling to check for token expiry, and logout if necessary.
//     // const intervalId = setInterval(logOutIfTokenExpired, 60000); // Check every minute

//     // return () => clearInterval(intervalId); // Cleanup interval on unmount
//   }, [token]);
//   return { isTokenExpired };
// };