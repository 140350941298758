import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./NavigationBar.scss";

import soOrgSide from "../../assets/soOrgSide.svg";
import soOrgSideSelected from "../../assets/soOrgSideSelected.svg";

import soAdminAcc from "../../assets/soAdminAcc.svg";
import soAdminAccSelected from "../../assets/soAdminAccSelected.svg";

import soSettings from "../../assets/soSettings.svg";
import soSettingsSelected from "../../assets/soSettingsSelected.svg";

import soLogout from "../../assets/soLogout.svg";
import soLogoutSelected from "../../assets/soLogoutSelected.svg";

const NavigationBar = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const handleLogout = (event: any) => {
    event.preventDefault();
    localStorage.setItem("role", "");
    localStorage.setItem("bearer-token", "");
    navigate("/");
  };

  return (
    <div className={cx("so-org-init-sidebar-container")}>
      <div className={cx("so-org-init-sidebar-name")}>Chan Heng Chee</div>
      <nav>
        <li className={cx("so-side-icon-container")}>
          <NavLink
            to={"/admin/system-owner-create-org-initial"}
            className={cx("so-side-icon-nav")}
            children={({ isActive }) => {
              const file = isActive ? soOrgSideSelected : soOrgSide;
              return (
                <div className={cx("so-nav-container")}>
                  {isActive ? (
                    <div className={cx("selected-rect")} />
                  ) : (
                    <div className={cx("no-selected-rect")} />
                  )}
                  <img
                    className={cx("so-side-icon")}
                    src={file}
                    alt="so-org-side"
                  />
                  <div
                    style={{ paddingRight: "8px" }}
                    className={cx("so-icon-text")}
                  >
                    Organisation
                  </div>
                </div>
              );
            }}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#40DAFF" : "#FFFFFF",
                textDecoration: isActive ? "none" : "none",
              };
            }}
          />
        </li>

        <li className={cx("so-side-icon-container")}>
          <NavLink
            to={"/admin/system-owner-admin-account-initial"}
            className={cx("so-side-icon-nav")}
            children={({ isActive }) => {
              const file = isActive ? soAdminAccSelected : soAdminAcc;
              return (
                <div className={cx("so-nav-container")}>
                  {isActive ? (
                    <div className={cx("selected-rect")} />
                  ) : (
                    <div className={cx("no-selected-rect")} />
                  )}
                  <img
                    className={cx("so-side-icon-admin")}
                    src={file}
                    alt="so-admin-acc-side"
                  />
                  <div className={cx("so-icon-text-admin")}>Admin Accounts</div>
                </div>
              );
            }}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#40DAFF" : "#FFFFFF",
                textDecoration: isActive ? "none" : "none",
              };
            }}
          />
        </li>
        <div style={{ position: "fixed", bottom: 0 }}>
          <li className={cx("so-side-icon-container-settings")}>
            <NavLink
              to={"/admin/system-owner-settings-info"}
              className={cx("so-side-icon-nav-setting")}
              children={({ isActive }) => {
                const file = isActive ? soSettingsSelected : soSettings;
                return (
                  <div className={cx("so-nav-container")}>
                    {isActive ? (
                      <div className={cx("selected-rect")} />
                    ) : (
                      <div className={cx("no-selected-rect")} />
                    )}
                    <img
                      className={cx("so-side-icon-setting")}
                      src={file}
                      alt="so-settings-side"
                    />
                    <div className={cx("so-icon-text-setting")}>Settings</div>
                  </div>
                );
              }}
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#40DAFF" : "#FFFFFF",
                  textDecoration: isActive ? "none" : "none",
                };
              }}
            />
          </li>

          <li className={cx("so-side-icon-container-settings")}>
            <NavLink
              to={""}
              className={cx("so-side-icon-nav-setting")}
              children={({ isActive }) => {
                const file = isActive ? soLogoutSelected : soLogout;
                return (
                  <div
                    className={cx("so-nav-container")}
                    onClick={handleLogout}
                  >
                    {isActive ? (
                      <div className={cx("selected-rect")} />
                    ) : (
                      <div className={cx("no-selected-rect")} />
                    )}
                    <img
                      className={cx("so-side-icon-logout")}
                      src={file}
                      alt="so-logout-side"
                    />
                    <div className={cx("so-icon-text-logout")}>Logout</div>
                  </div>
                );
              }}
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#40DAFF" : "#FFFFFF",
                  textDecoration: isActive ? "none" : "none",
                };
              }}
            />
          </li>
        </div>
      </nav>
    </div>
  );
};

export default NavigationBar;
