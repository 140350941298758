import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./ForgotPinNoParentEmail.scss";
import studentLoginBackground from "../../../assets/studentLoginBackground.png";
import sadEmojiIcon from "../../../assets/nonSmiley.svg";
import tryAgainBtn from "../../../assets/tryAgainBtn.svg";

const ForgotPinNoParentEmail = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const handleTryAgainClick = () => {
    navigate("/forgot-pin");
  };

  const handleBackToLoginClick = () => {
    navigate("/");
  };

  return (
    <div className={cx("forgot-pin-no-parent-email-container")}>
      <img
        className={cx("forgot-pin-no-parent-email-background")}
        src={studentLoginBackground}
        alt="student-sent-new-pin-background"
      />

      <div className={cx("forgot-pin-no-parent-email-rectangle-container")}>
        <div className={cx("forgot-pin-no-parent-email-rectangle")}>
          <div className={cx("forgot-pin-no-parent-email-contents-container")}>
            <img
              className={cx("forgot-pin-no-parent-email-mail-icon")}
              src={sadEmojiIcon}
              alt="forgot-pin-no-parent-email-icon"
            />
            <div className={cx("forgot-pin-no-parent-email-title")}>Ooops!</div>

            <div className={cx("forgot-pin-no-parent-email-subtitle")}>
              We can't find your parent's email. Please contact your teacher.
            </div>

            <img
              className={cx("forgot-pin-no-parent-email-try-again-btn")}
              src={tryAgainBtn}
              alt="forgot-pin-no-parent-email-try-again-btn"
              onClick={handleTryAgainClick}
            />

            <div
              className={cx("forgot-pin-no-parent-email-link")}
              onClick={handleBackToLoginClick}
            >
              Back to Login
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPinNoParentEmail;
