import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./AdminAccount.scss";
import NavigationBar from "../../../shared/systemOwner/NavigationBar";
import leftChevron from "../../../assets/leftChevron.svg";
import { Form, FormGroup, Input, Label } from "reactstrap";
import CustomButton from "../../../shared/CustomButton";
import { Select } from "antd";
// DEPRECATED: Fix webpack failed to parse source map compile warnings.
// Reference: https://stackoverflow.com/questions/71500112/antd-source-map-not-supported-in-react
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import FloatDropDown from "../../../shared/FloatDropdown";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSingleSchoolAdmin } from "../../../utils/WebServiceConfig";

const { Option } = Select;

const AdminAccount = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [firstName, setChangeFirstName] = useState("");
  const [middleName, setChangeMiddleName] = useState("");
  const [lastName, setChangeLastName] = useState("");
  const [adminEmail, setChangeAdminEmail] = useState("");
  const [confirmAdminEmail, setChangeConfirmAdminEmail] = useState("");

  const [selectValueSchool, setSelectValueSchool] = useState("");

  const handleFirstNameChange = (event: any) => {
    setChangeFirstName(event.target.value);
  };

  const handleMiddleNameChange = (event: any) => {
    setChangeMiddleName(event.target.value);
  };

  const handleLastNameChange = (event: any) => {
    setChangeLastName(event.target.value);
  };

  const handleAdminEmailChange = (event: any) => {
    setChangeAdminEmail(event.target.value);
  };

  const handleConfirmAdminEmailChange = (event: any) => {
    setChangeConfirmAdminEmail(event.target.value);
  };

  const postCreateSingleSchoolAdmin = async (
    adminEmail: string,
    selectValueSchool: any,
    firstName: string,
    middleName: string,
    lastName: string
  ) => {
    try {
      await createSingleSchoolAdmin({
        email: adminEmail,
        organization: selectValueSchool,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
      });
      localStorage.setItem("create-school-admin", "true");
      navigate("/admin/system-owner-admin-main-dashboard");
    } catch (err: any) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: {
          backgroundColor: "#F05959",
        },
      });
    }
  };

  return (
    <div className={cx("so-create-admin-container")}>
      <NavigationBar />
      <div className={cx("so-create-admin-white-container")}>
        <ToastContainer />
        <div
          className={cx("so-create-admin-back-container")}
          onClick={() => navigate("/admin/system-owner-admin-account-initial")}
        >
          <img
            className={cx("so-nav-left-chevron")}
            src={leftChevron}
            alt="system-owner-go-back-chevron"
          />
          <div className={cx("so-nav-back-text")}>Go Back</div>
        </div>
        <div className={cx("so-create-admin-title")}>Create New Admin</div>

        <Form className={cx("so-create-admin-form-inputs")}>
          <FormGroup floating>
            <Input
              className={cx("so-create-admin-input")}
              id="so-admin-first-name-input"
              name="text"
              placeholder="First Name"
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <Label
              className={cx("so-create-admin-label")}
              for="so-create-admin-label-first-name"
            >
              First Name
            </Label>
          </FormGroup>

          <FormGroup floating>
            <Input
              className={cx("so-create-admin-input")}
              id="so-admin-middle-name-input"
              name="text"
              placeholder="Middle Name (Optional)"
              type="text"
              value={middleName}
              onChange={handleMiddleNameChange}
            />
            <Label
              className={cx("so-create-admin-label")}
              for="so-create-admin-label-middle-name"
            >
              Middle Name (Optional)
            </Label>
          </FormGroup>

          <FormGroup floating>
            <Input
              className={cx("so-create-admin-input")}
              id="so-admin-last-name-input"
              name="text"
              placeholder="Last Name"
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
            />
            <Label
              className={cx("so-create-admin-label")}
              for="so-create-admin-label-last-name"
            >
              Last Name
            </Label>
          </FormGroup>

          <FormGroup floating>
            <Input
              className={cx("so-create-admin-input")}
              id="so-admin-email-input"
              name="text"
              placeholder="Admin's Email"
              type="text"
              value={adminEmail}
              onChange={handleAdminEmailChange}
            />
            <Label
              className={cx("so-create-admin-label")}
              for="so-create-admin-label-admin-email"
            >
              Admin's Email
            </Label>
          </FormGroup>

          <FormGroup floating>
            <Input
              className={cx("so-create-admin-input")}
              id="so-admin-email-input"
              name="text"
              placeholder="Confirm Admin's Email"
              type="text"
              value={confirmAdminEmail}
              onChange={handleConfirmAdminEmailChange}
            />
            <Label
              className={cx("so-create-admin-label")}
              for="so-create-admin-label-admin-email"
            >
              Confirm Admin's Email
            </Label>
          </FormGroup>

          <div className={cx("so-create-admin-floating-dropdown-container")}>
            <FloatDropDown label="School Name" value={selectValueSchool}>
              <Select
                className={cx("so-create-admin-select")}
                onChange={(value: string) => setSelectValueSchool(value)}
                value={selectValueSchool}
              >
                <Option value="A">Apple Primary School</Option>
                <Option value="B">Blackberry Primary School</Option>
                <Option value="C">Coconut Primary School</Option>
                <Option value="D">Durian Primary School</Option>
                <Option value="E">Elderberry Primary School</Option>
                <Option value="F">Fig Primary School</Option>
                <Option value="G">Guava Primary School</Option>
                <Option value="H">Hawthorn Primary School</Option>
                <Option value="I">Imbe Primary School</Option>
                <Option value="J">Juniper Primary School </Option>
              </Select>
            </FloatDropDown>
          </div>

          <div className={cx("so-create-admin-note")}>
            <b>Note : </b>Admin ID will be auto generated upon admin creation.
          </div>
        </Form>

        <CustomButton
          testId="so-create-admin-btn"
          className="so-create-admin-btn"
          content="Create"
          createOrganisationFlag={true}
          clicked={() =>
            postCreateSingleSchoolAdmin(
              adminEmail,
              selectValueSchool,
              firstName,
              middleName,
              lastName
            )
          }
        ></CustomButton>
      </div>
    </div>
  );
};

export default AdminAccount;
