import React from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./NavigationBarFull.scss";
import homeIcon from "../../assets/homeIcon.svg";
import historyIcon from "../../assets/historyIcon.svg";
import profileIcon from "../../assets/userProfileIcon.svg";
import logoutIcon from "../../assets/logoutIcon.svg";

const NavigationBarFull = () => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = (event: any) => {
    event.preventDefault();
    localStorage.setItem("role", "");
    localStorage.setItem("bearer-token", "");
    localStorage.setItem("parent-email-onboarding", "");
    localStorage.setItem("parent-email-pending-verification", "");
    localStorage.setItem("both_parent_email_and_mic_test_onboarding", "");
    localStorage.removeItem("selectedExerciseId");
    localStorage.removeItem("selectedFilter");
    localStorage.removeItem("selectedHomeFilter");
    localStorage.removeItem("email-pending-timer");
    // sessionStorage.clear();
    navigate("/");
  };

  const getLinkClass = (path: string) =>
    cx("nav-bar-full-icon-set", {
      "nav-bar-full-active": location.pathname === path && path !== "/home",
    });

  return (
    <nav className={cx("nav-bar-full-container")}>
      <div className={cx("nav-bar-icon-sets")}>
        <div className={cx("nav-bar-full-three-icon-sets")}>
          <Link to="/home?filter=Newest" className={cx("nav-bar-full-icon-set")}>
            <img src={homeIcon} alt="Home Icon" />
            <span>Home</span>
          </Link>
          <Link to="/history?filter=Recent" className={getLinkClass("/history")}>
            <img src={historyIcon} alt="Exercise History Icon" />
            <span>Exercise History</span>
          </Link>
          <Link to="/profile" className={getLinkClass("/profile")}>
            <img src={profileIcon} alt="Profile Icon" />
            <span>My Profile</span>
          </Link>
        </div>
        <Link
          to="#"
          className={cx("nav-bar-full-icon-set")}
          onClick={handleLogout}
        >
          <img src={logoutIcon} alt="Logout Icon" />
          <span>Logout</span>
        </Link>
      </div>
    </nav>
  );
};

export default NavigationBarFull;
