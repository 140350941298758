import React, { useState, ChangeEvent } from "react";
import classNames from "classnames/bind";
import styles from "./AddParentsEmail.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import disabledStudentVerifyBtn from "../../../assets/studentVerifyBtnDisabled.svg";
import studentVerifyBtn from "../../../assets/studentVerifyBtn.svg";
import { changeParentEmailAPI } from "../../../utils/WebServiceConfig";

const cx = classNames.bind(styles);

const AddParentsEmail: React.FC = () => {
  const navigate = useNavigate();

  const [parentsEmail, setParentsEmail] = useState<string>("");
  const [parentsConfirmEmail, setParentsConfirmEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleParentsEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setParentsEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
    }
  };

  const handleParentsConfirmEmailChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setParentsConfirmEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
    }
  };

  const isEmailValid = (email: string): boolean => {
    return email.includes("@");
  };

  const isFormValid = (): boolean => {
    return (
      parentsEmail === parentsConfirmEmail &&
      isEmailValid(parentsEmail) &&
      isEmailValid(parentsConfirmEmail)
    );
  };

  const handleVerifyButtonClick = async () => {
    if (isFormValid()) {
      try {
        const response = await changeParentEmailAPI({ email: parentsEmail });
        localStorage.setItem("parents-email-input", parentsEmail);
        navigate("/add-parents-email-pending-verification");
      } catch (error) {
        setErrorMsg("Failed to verify parent email. Please try again.");
      }
    } else {
      setErrorMsg("Emails do not match or are invalid.");
    }
  };

  return (
    <>
      <NavigationBarHomeLogout />
      <div className={cx("st-add-parents-email-container")}>
        <div className={cx("st-add-parents-email-rectangle-container")}>
          <div className={cx("st-add-parents-email-contents")}>
            <div className={cx("st-add-parents-email-title")}>
              Add Parent's Email
            </div>

            <div className={cx("st-add-parents-email-subtitle")}>
              Your Parent's Email
            </div>

            <input
              id="st-add-parents-email-input-type-email"
              className={cx("st-add-parents-email-input")}
              type="text"
              name="parentsEmail"
              placeholder="Type your parent's email here"
              value={parentsEmail}
              onChange={handleParentsEmailChange}
            />

            <div className={cx("st-add-parents-email-subtitle")}>
              Confirm Parent's Email
            </div>

            <input
              id="st-add-parents-email-input-confirm-email"
              className={cx("st-add-parents-email-input")}
              type="text"
              name="parentsConfirmEmail"
              placeholder="Type your parent's email here"
              value={parentsConfirmEmail}
              onChange={handleParentsConfirmEmailChange}
            />

            {errorMsg && (
              <div className={cx("st-add-parents-email-error")}>{errorMsg}</div>
            )}

            <div className={cx("st-add-parents-email-verify-btn-container")}>
              <img
                className={cx("st-add-parents-email-verify-btn")}
                src={
                  isFormValid() ? studentVerifyBtn : disabledStudentVerifyBtn
                }
                alt={isFormValid() ? "verify-btn" : "disabled-verify-btn"}
                onClick={handleVerifyButtonClick}
                style={{ cursor: isFormValid() ? "pointer" : "not-allowed" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddParentsEmail;
