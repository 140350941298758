import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./AudioBar.scss";
import recordingIcon from "../assets/studentVoiceRecording.svg";
import studentIcon from "../assets/studentIcon.svg";
import AudioVisualiser from "./AudioVisualizer";
import { useNavigate } from "react-router-dom";

interface Props {
  testId?: string;
  content?: string;
  clicked?: () => void;
  className?: string;
  width?: string;
  height?: string;
  useStudentIcon?: boolean; // New prop to determine which icon to use
}

const AudioBar: React.FC<Props> = ({
  width,
  height,
  testId,
  content,
  clicked,
  className,
  useStudentIcon = false, // Default to false
}) => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  // Function to render content based on the provided content prop
  const renderContent = (content: string | undefined) => {
    if (!content) return null;
    const maxLength = 50; // Set your desired character limit here
    const lines = content.match(new RegExp(".{1," + maxLength + "}", "g")) || [];

    return (
      <div className={cx("ai-prompt-text")}>
        {lines.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    );
  };

  return (
    <div
      data-testid={`audio-bar-${testId}`}
      className={cx("audio-bar-basic", { "with-text": useStudentIcon })} // Apply class based on condition
      onClick={clicked}
    >
      <img
        className={cx("audio-bar-icon")}
        src={useStudentIcon ? studentIcon : recordingIcon} // Use the appropriate icon
        alt={useStudentIcon ? "student-icon" : "recording-icon"}
      />
      <div className={cx("audio-bar-sounds")}>
        {!useStudentIcon && <AudioVisualiser width={width} height={height} />}
        {useStudentIcon && renderContent(content)}{/* Display the transcript */}
      </div>
    </div>
  );
};

export default AudioBar;
