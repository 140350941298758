import React from "react";
import classNames from "classnames/bind";
import styles from "./CustomChatFeed.scss";

const cx = classNames.bind(styles);

interface ChatMessage {
  id: number;
  message: string;
}

interface CustomChatFeedProps {
  messages: ChatMessage[];
}

const CustomChatFeed: React.FC<CustomChatFeedProps> = ({ messages }) => {
  return (
    <div className={cx("custom-chat-feed")}>
      <div className={cx("custom-chat-feed-title")}>Conversation History</div>
      <hr className={cx("custom-chat-feed-divider")} />
      {messages.map((msg, index) => (
        <div
          key={index}
          className={cx("chat-bubble-container", {
            "chat-bubble-left": msg.id === 0,
            "chat-bubble-right": msg.id === 1,
          })}
        >
          <div
            className={cx("chat-bubble", {
              "chat-bubble-id-0": msg.id === 0,
              "chat-bubble-id-1": msg.id === 1,
            })}
          >
            {msg.message}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomChatFeed;
