import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./AddParentsEmailSuccess.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import studentMailIcon from "../../../assets/studentMailIcon.svg";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentAddBtnIcon from "../../../assets/studentAddBtn.svg";
import studentTestBtnIcon from "../../../assets/studentTestBtn.svg";
import modalCloseIcon from "../../../assets/modalCloseIconCircular.svg";
import { fetchStudentProfile } from "utils/WebServiceConfig";

const cx = classNames.bind(styles);

const AddParentsEmailSuccess: React.FC = () => {
  const navigate = useNavigate();
  const [parentsEmail, setParentsEmail] = useState<string>("");
  // State to hold name
  const [studentName, setStudentName] = useState<string>("en-US");

  useEffect(() => {
    const email = localStorage.getItem("parents-email-input");
    if (email) {
      setParentsEmail(email);
    }
  }, []);

  const handleCloseIconClick = async () => {
    localStorage.setItem("parents-email-input", "");
    await localStorage.setItem("parent-email-pending-verification", "true");
    navigate("/welcome");
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  // Fetch the student profile for name
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await fetchStudentProfile();

        profile.preferred_name !== null
          ? setStudentName(profile.preferred_name)
          : setStudentName(`${profile.first_name} ${profile.last_name}`);
      } catch (error) {
        console.error("Error fetching student profile:", error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <>
      <NavigationBarHomeLogout />
      <div className={cx("st-add-parents-email-success-container")}>
        <div className={cx("st-add-parents-email-success-title")}>
          Hello {studentName}, Welcome!
        </div>
        <div className={cx("st-add-parents-email-success-subtitle")}>
          Let's do two things first before we start the exercises. Ask your
          parents to help you with these
          <br /> steps.
        </div>
        <div className={cx("st-add-parents-email-success-rectangle-container")}>
          <img
            className={cx("st-add-parents-email-success-mail-icon")}
            src={studentMailIcon}
            alt="student-welcome-mail-icon"
          />
          <div className={cx("st-add-parents-email-success-label-container")}>
            <div className={cx("st-add-parents-email-success-label-title")}>
              1. Parent's Email
            </div>
            <div className={cx("st-add-parents-email-success-label-subtitle")}>
              Make your account safer by adding your parent's <br />
              email. Click "Add" and enter their email.
            </div>
          </div>

          <img
            onClick={() => navigate("/add-parents-email")}
            className={cx("st-add-parents-email-success-add-btn")}
            src={studentAddBtnIcon}
            alt="student-welcome-add-btn"
          />
        </div>

        <div
          className={cx(
            "st-add-parents-email-success-rectangle-container",
            "st-add-parents-email-success-microphone-container"
          )}
        >
          <img
            className={cx("st-add-parents-email-success-mic-icon")}
            src={studentMicIcon}
            alt="student-welcome-mic-icon"
          />
          <div className={cx("st-add-parents-email-success-label-container")}>
            <div className={cx("st-add-parents-email-success-label-title")}>
              2. Test Your Microphone
            </div>
            <div className={cx("st-add-parents-email-success-label-subtitle")}>
              Make sure your Microphone works well. Click on
              <br /> the "Test" button to check it.
            </div>
          </div>

          <img
            onClick={() => navigate("/test-your-microphone")}
            className={cx("st-add-parents-email-success-test-btn")}
            src={studentTestBtnIcon}
            alt="student-welcome-test-btn"
          />
        </div>
      </div>

      <div className={cx("modal-overlay")}>
        <div className={cx("modal")}>
          <img
            onClick={handleCloseIconClick}
            className={cx("close-icon-circular")}
            src={modalCloseIcon}
            alt="student-parent-email-success-modal-close-icon"
          />
          <div className={cx("parent-email-verification-success-icon-label")}>
            <img
              className={cx("email-verification-success-icon")}
              src={studentMailIcon}
              alt="student-parent-email-success-email-icon"
            />
            <div className={cx("parent-email-verification-label")}>
              Email Verification
            </div>
            <div className={cx("parent-email-verification-subtitle")} >
              <span data-testid="email-verification-subtitle"> Email verification instructions have been sent to your parent's
                email which is</span>
              <span className={cx("highlight")} data-testid="email-highlight"> {parentsEmail}</span>
              <span data-testid="email-verification-check">. Please
                check it and verify with the help of your parents.
              </span>
              <br />
              <br />
              <span data-testid="email-verification-retry">Try again after a while if you didn't receive the email.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddParentsEmailSuccess;
