import React from "react";
import classNames from "classnames/bind";
import styles from "./ResetPin.scss";
import studentLoginBackground from "../../../assets/studentLoginBackground.png";
import resetPinMail from "../../../assets/resetPinMail.svg";
import sendBtn from "../../../assets/sendBtn.svg";
import backBtn from "../../../assets/backBtn.svg";

const ResetPin = () => {
  const cx = classNames.bind(styles);
 

  return (
    <div className={cx("st-reset-pin-container")}>
      <img
        className={cx("st-reset-pin-background")}
        src={studentLoginBackground}
        alt="student-reset-pin-background"
      />

      <div className={cx("st-reset-pin-rectangle-container")}>
        <div className={cx("st-reset-pin-rectangle")}>
          <div className={cx("st-reset-pin-contents-container")}>
            <img
              className={cx("st-reset-pin-mail-icon")}
              src={resetPinMail}
              alt="st-reset-pin-mail-icon"
            />
            <div className={cx("st-reset-pin-title")}>Reset PIN</div>

            <div className={cx("st-reset-pin-subtitle")}>
              We'll send you a new PIN to your parent's email
              <br /> at{" "}
              <span className={cx("st-reset-pin-email")}>
                tani******@gmail.com
              </span>
              <br />{" "}
              <div style={{ marginTop: "24px" }}>
                Please press the send button to get a new PIN.
              </div>
            </div>

            <div className={cx("st-reset-pin-btn-container")}>
              <img
                className={cx("st-reset-pin-back-btn")}
                src={backBtn}
                alt="student-reset-pin-back-btn"
              />

              <img
                className={cx("st-reset-pin-send-btn")}
                src={sendBtn}
                alt="student-reset-pin-send-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPin;
