import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./InformationInstructions.scss";
import { useNavigate, useLocation } from "react-router-dom";
import nextBtn from "assets/nextBtn.svg";
import field from "assets/scene1.png";
import chatHome from "assets/chatIconHome.svg";
import micInfo from "assets/studentMicInfo.svg";
import repeatInfo from "assets/studentRepeatInfo.svg";
import sendInfo from "assets/studentSendInfo.svg";
import letStartBtn from "assets/studentLetsStartBtn.svg";
import circularClose from "assets/studentCircularClose.svg";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
// import { useGetExerciseImage } from "hooks/useGetExerciseImageS3Url";
import { useGetExerciseImageS3Url } from "hooks/useGetExerciseImageS3Url";
import { fetchStudentProfile } from "utils/WebServiceConfig";

const InformationInstructions = () => {
  const [showModal, setShowModal] = useState(false);
  // State to hold name
  const [studentName, setStudentName] = useState<string>("en-US");
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const exerciseId =
    searchParams.get("exerciseId") ||
    localStorage.getItem("selectedExerciseId");

  // call react hook to get exercise image s3 url
  // const exerciseImageS3Url = useGetExerciseImage(exerciseId);
  const exerciseImageS3Url = useGetExerciseImageS3Url(exerciseId);

  // Fetch the student profile for name
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await fetchStudentProfile();

        profile.preferred_name !== null
          ? setStudentName(profile.preferred_name)
          : setStudentName(`${profile.first_name} ${profile.last_name}`);
      } catch (error) {
        console.error("Error fetching student profile:", error);
      }
    };

    fetchProfile();
  }, []);

  //Hotjar Event
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_information_instructions");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  const handleStartBtn = () => {
    localStorage.setItem("nextPromptAPICalled", "false");
    localStorage.setItem("startExerciseAPICalled", "false");
    navigate(`/information-exercise-begins?exerciseId=${exerciseId}`);
  };

  return (
    <>
      <div className={cx("st-info-instructions-container")}>
        <div className={cx("st-info-instructions-background-container")}>
        {exerciseImageS3Url?(
          <img
            className={cx("st-info-instructions-background-img")}
            src={exerciseImageS3Url}
          />
        ):(<p>Loading image...</p>)}
          <img
            className={cx("st-info-instructions-home-chat-icon")}
            src={chatHome}
            alt="st-info-instructions-home-chat-icon"
          />
          <div className={cx("st-info-instructions-overlay")}></div>
          <div className={cx("st-info-instructions-panel")}>
            <img
              className={cx("st-info-instructions-close-btn")}
              src={circularClose}
              alt="circular-close"
              onClick={() => navigate(`/home?exerciseId=${exerciseId}`)}
            />
            <div className={cx("st-info-instructions-desc-container")}>
              <div className={cx("st-info-instructions-title")}>
                Cool! You're almost there!
              </div>
              <div className={cx("st-info-instructions-icon-set")}>
                <img
                  className={cx("st-info-instructions-icon")}
                  src={micInfo}
                  alt="st-info-instructions-mic-info"
                />
                <div className={cx("st-info-instructions-label-set")}>
                  <div className={cx("st-info-instructions-icon-title")}>
                    Microphone
                  </div>
                  <div className={cx("st-info-instructions-icon-subtitle")}>
                    Press the microphone button to provide your answer.
                  </div>
                </div>
              </div>
              <div className={cx("st-info-instructions-icon-set")}>
                <img
                  className={cx("st-info-instructions-icon")}
                  src={repeatInfo}
                  alt="st-info-instructions-mic-info"
                />
                <div className={cx("st-info-instructions-label-set")}>
                  <div className={cx("st-info-instructions-icon-title")}>
                    Repeat
                  </div>
                  <div className={cx("st-info-instructions-icon-subtitle")}>
                    Press the loudspeaker button to hear the question again.
                  </div>
                </div>
              </div>
              <div className={cx("st-info-instructions-icon-set")}>
                <img
                  className={cx("st-info-instructions-icon")}
                  src={sendInfo}
                  alt="st-info-instructions-mic-info"
                />
                <div className={cx("st-info-instructions-label-set")}>
                  <div className={cx("st-info-instructions-icon-title")}>
                    Send
                  </div>
                  <div className={cx("st-info-instructions-icon-subtitle")}>
                    Press the send button to submit your response.
                  </div>
                </div>
              </div>
              <div
                className={cx("st-info-instructions-language-desc-container")}
              >
                <p className={cx("st-info-instructions-language-desc")}>
                  Starting from the next screen, we will communicate in your
                  Mother
                  <br />
                  tongue language which is{" "}
                  <span
                    className={cx("st-info-instructions-language-highlight")}
                  >
                    Chinese/Malay/Tamil.
                  </span>
                </p>
              </div>
              <div
                className={cx("st-info-instructions-lets-start-btn-container")}
              >
                <img
                  className={cx("st-info-instructions-lets-start-btn")}
                  src={letStartBtn}
                  alt="st-info-instructions-lets-start-btn"
                  onClick={handleStartBtn}
                />
              </div>
            </div>
          </div>
          <div className={cx("st-info-instructions-chatbox")}>
            <div className={cx("st-info-instructions-description")}>
              Hello {studentName}, Welcome! look at the picture and try to find
              the things, characters, and colours in it. When you're ready to talk about the picture, click
              the “Next” button.
            </div>
            <img
              className={cx("st-info-instructions-next-btn")}
              src={nextBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationInstructions;
