import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "shared/student/NavigationBarHomeLogout.scss";
import homeIcon from "assets/homeIcon.svg";
import logoutIcon from "assets/logoutIcon.svg";

const NavigationBarHomeLogout = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const handleLogout = (event: any) => {
    event.preventDefault();
    localStorage.setItem("role", "");
    localStorage.setItem("bearer-token", "");
    localStorage.setItem("parent-email-onboarding", "");
    localStorage.setItem("parent-email-pending-verification", "");
    localStorage.setItem("both_parent_email_and_mic_test_onboarding", "");
    localStorage.removeItem("selectedExerciseId");
    localStorage.removeItem("selectedFilter");
    localStorage.removeItem("selectedHomeFilter");
    localStorage.removeItem("email-pending-timer");
    // sessionStorage.clear();
    navigate("/");
  };

  return (
    <nav className={cx("nav-bar-home-logout-container")}>
      <div className={cx("nav-bar-home-widget")}>
      <div
        className={cx(
          "nav-bar-home-logout-icon-set",
          "nav-bar-home-icon-label-set-spacing"
        )}
      >
        <img
          style={{
            marginTop: "14px",
            width: "29px",
            height: "26px",
          }}
          src={homeIcon}
          alt="Home Icon"
        ></img>
        <Link to="/welcome" className={cx("nav-bar-home-logout-text-label")}>
          Home
        </Link>
      </div>
      <div className={cx("nav-bar-home-logout-icon-set")}>
        <img
          style={{ marginTop: "19px", width: "22px", height: "22px" }}
          src={logoutIcon}
          alt="Logout Icon"
        ></img>

        <Link
          to="#"
          onClick={handleLogout}
          className={cx("nav-bar-home-logout-text-label")}
        >
          Logout
        </Link>
      </div>
      </div>
    </nav>
  );
};

export default NavigationBarHomeLogout;
