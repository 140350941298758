import React, { FunctionComponent, useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./Home.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
// import exercise1 from "assets/exercise1.png";
import continueBtn from "assets/studentContinueBtn.svg";
import startBtn from "assets/studentStartBtn.svg";
import NavigationBarFull from "shared/student/NavigationBarFull";
import {
  fetchNewestExercises,
  fetchExercisesByStatus,
  fetchStudentProfile,
  // TODO: add fetch COMPLETED exercise API
} from "utils/WebServiceConfig";
import { reactGAEvent } from "shared/ReactGA";
import { useLogOut } from "hooks/useCheckExpiredToken";

// import { useCheckTokenExpiry } from "hooks/useCheckExpiredToken"

// Define the Exercise interface to type-check the exercises data structure
interface Exercise {
  exercise_id: string;
  task: string;
  status: string;
  image_id: string;
  s3: string;
  start_date: string;
  end_date: string;
  last_interaction_date: string | null;
}

// Main component for the Home Dashboard
const HomeDashboard: FunctionComponent = () => {
  const cx = classNames.bind(styles);// Bind styles with classNames utility for conditional styling
  const navigate = useNavigate();// Hook to navigate programmaticall
  const location = useLocation();// Hook to access the current location
  const {doLogOut, checkIsTokenExpired} = useLogOut();

  // State to store the list of exercises
  const [exercises, setExercises] = useState<Exercise[] | null>(null);
  // State to store the currently selected filter (Newest, Open, Incomplete)
  const [selectedFilter, setSelectedFilter] = useState<string>("Newest");
  // State to manage the loadingExercises state while fetching exercises
  const [loadingExercises, setLoadingExercises] = useState<boolean>(true);

  const [hasIncomplete, setHasIncomplete] = useState(false);

  // useEffect to manage the selected filter from URL params or local storage
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get("filter"); // Get the filter parameter from URL
    if (filter) {
      setSelectedFilter(filter);// Set filter from URL param
      localStorage.setItem("selectedHomeFilter", filter);// Save filter to localStorage
    } else {
      const storedFilter =
        localStorage.getItem("selectedHomeFilter") || "Newest";// Default to "Newest"
      setSelectedFilter(storedFilter);
      navigate(`/home?filter=${storedFilter}`, { replace: true });// Update the URL with the selected filter
    }
  }, [location.search, navigate]);

  // useEffect to check if the student has completed the mic test and profile setup
  useEffect(() => {
    const micTestRecording = localStorage.getItem("mic-test-recording");
    const checkProfileAndMicTest = async () => {
      try {
        const profile = await fetchStudentProfile(); // Fetch student profile
        const parentEmailOnboarding = profile.parent_email;
        if (!(parentEmailOnboarding && micTestRecording)) {
          navigate("/welcome");// Redirect to welcome page if profile or mic test is incomplete
        }
      } catch (error) {
        if (error instanceof Error && error.message === "Token is required but not found") {
          console.error("Caught Token is missing");
          doLogOut();
        } else if (error instanceof Error && error.message === "Signature has expired") {
          console.error("Caught Signature has expired");
          doLogOut();
        } else {
          console.error("Error fetching profile:", error);
        }
      }
    };
    checkProfileAndMicTest();
  }, [navigate]);


  //Hotjar Event

  // useEffect to initialize Hotjar and log a custom event

  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion)); // Initialize Hotjar
      Hotjar.event("student_home");// Log custom event for Hotjar
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);


  // useEffect to fetch exercises based on the selected filter
  useEffect(() => {
    Hotjar.stateChange("/student-home");// Custom state change for Hotjar recording

    const getExercises = async () => {
      setLoadingExercises(true);// Set loadingExercises to true while fetching exercises
      try {
        let res;
        if (selectedFilter === "Newest") {
          try {
            res = await fetchNewestExercises();// Fetch newest exercises
          } catch (error) {
            if (error instanceof Error && error.message === "Token is required but not found") {
              console.error("Caught Token is missing");
              doLogOut();
            } else if (error instanceof Error && error.message === "Signature has expired") {
              console.error("Caught Signature has expired");
              doLogOut();
            } else {
              console.error("Caught a different error");
              // throw error
            }
          }
        } else if (selectedFilter === "Open") {
          try {
            res = await fetchExercisesByStatus("OPEN");// Fetch open exercises
          } catch (error) {
            if (error instanceof Error && error.message === "Token is required but not found") {
              console.error("Caught Token is missing");
              doLogOut();
            } else if (error instanceof Error && error.message === "Signature has expired") {
              console.error("Caught Signature has expired");
              doLogOut();
            } else {
              console.error("Caught a different error");
              // throw error
            }
          }
        } else if (selectedFilter === "Incomplete") {
          try {
            res = await fetchExercisesByStatus("INCOMPLETE"); // Fetch incomplete exercises
          } catch (error) {
            if (error instanceof Error && error.message === "Token is required but not found") {
              console.error("Caught Token is missing");
              doLogOut();
            } else if (error instanceof Error && error.message === "Signature has expired") {
              console.error("Caught Signature has expired");
              doLogOut();
            } else {
              console.error("Caught a different error");
              // throw error
            }
          }
        }
        const rawRes = res.results.filter(
          (exercise: Exercise) => exercise.status !== "COMPLETED"// Filter out completed exercises
        );
        const sortedResults = sortExerciseResults(rawRes);// Sort exercises by last interaction date
        setExercises(sortedResults);

        // Check if there are any incomplete exercises
        if (rawRes.some((exercise: Exercise) => exercise.status === "INCOMPLETE")) {
          setHasIncomplete(true);
        } else {
          setHasIncomplete(false);
        }
      } catch (error) {
        console.error("Error fetching exercises:", error);
      } finally {
        setLoadingExercises(false);// Set loadingExercises to false after fetching exercises
      }
    };

    getExercises();// Call the function to fetch exercises
  }, [selectedFilter]);// Re-run the effect when selectedFilter changes

  // Function to sort exercises by last interaction date, prioritizing non-null dates
  const sortExerciseResults = (results: Exercise[]) => {
    return results.sort((a, b) => {
      if (a.last_interaction_date === null && b.last_interaction_date === null) {
        return 0;
      }
      if (a.last_interaction_date === null) {
        return 1;
      }
      if (b.last_interaction_date === null) {
        return -1;
      }
      return (
        new Date(b.last_interaction_date).getTime() -
        new Date(a.last_interaction_date).getTime()
      );
    });
  };

  const handleCardClick = (exerciseId: string, isIncomplete: boolean) => {
    localStorage.setItem("selectedExerciseId", exerciseId);// Save the selected exercise ID to localStorage
    localStorage.setItem("isIncomplete", isIncomplete ? "true" : "false"); // Persist isIncomplete in localStorage
    navigate(`/information?exerciseId=${exerciseId}`); // Navigate to the information page with the exercise ID
  };

  // Function to handle filter selection and update the URL and analytics event
  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);// Update selected filter state
    localStorage.setItem("selectedHomeFilter", filter); // Save the selected filter to localStorage
    reactGAEvent(
      "Home Exercise Page for Students",
      "Clicked into Exercise Filter"
    );// Log a Google Analytics event
    navigate(`/home?filter=${filter}`); // Update the URL with the selected filter
  };

  // Function to render a card for each exercise
  const renderStudentCard = (exercise: Exercise) => {

    // TODO: combine this renderStudentCard component with the renderStudentCard component in History.tsx
    const isIncomplete = exercise.status === "INCOMPLETE";
    const isOpen = exercise.status === "OPEN";

    return (
      <div
        className={cx("student-home-cards-container")}
        key={exercise.exercise_id}
      >
        <div className={cx("student-home-cards-rect")}>
          <img
            className={cx("student-home-image")}
            // src={exercise1} // image to render for exercise student card
            src={exercise.s3}// image to render for exercise student card
            alt="exercise-1"
          />
          <div className={cx("home-card-label-container")}>
            <div
              className={cx(
                isIncomplete
                  ? "home-card-date-label-incomplete"
                  : isOpen
                    ? "home-card-date-label-open"
                    : "home-card-date-label-complete"
              )}
            >
              Last Attempt Date
            </div>
            <div
              className={cx(
                isIncomplete
                  ? "home-card-status-label-incomplete"
                  : isOpen
                    ? "home-card-status-label-open"
                    : "home-card-status-label-complete"
              )}
            >
              Status
            </div>
          </div>
          <div className={cx("home-card-data-container")}>
            <div
              className={cx(
                isIncomplete
                  ? "home-card-date-data-incomplete"
                  : isOpen
                    ? "home-card-date-data-open"
                    : "home-card-date-data-complete"
              )}
            >
              {exercise.last_interaction_date
                ? new Date(exercise.last_interaction_date).toLocaleDateString('en-GB')
                : "-"}
            </div>
            <div
              className={cx(
                isIncomplete
                  ? "home-card-status-data-incomplete"
                  : isOpen
                    ? "home-card-status-data-open"
                    : "home-card-status-data-complete"
              )}
            >
              {exercise.status}
            </div>
          </div>
          <img
            className={cx("student-home-card-btn")}
            src={isIncomplete ? continueBtn : startBtn}
            alt={
              isIncomplete
                ? "student-home-card-continue-btn"
                : "student-home-card-start-btn"
            }
            onClick={() => handleCardClick(exercise.exercise_id, isIncomplete)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <NavigationBarFull />
      <div className={cx("student-home-container")}>
        <div className={cx("student-home-filter-container")}>
          <div className={cx("student-home-title")}>Exercises</div>
          <div className={cx("student-home-filter-btn-container")}>
            <div
              className={cx(
                selectedFilter === "Newest"
                  ? "student-home-selected-button"
                  : "student-home-unselected-button"
              )}
              onClick={() => handleFilterClick("Newest")}
            >
              <div className={cx("student-home-label")}>Newest</div>
            </div>
            <div
              className={cx(
                selectedFilter === "Open"
                  ? "student-home-selected-button"
                  : "student-home-unselected-button"
              )}
              onClick={() => handleFilterClick("Open")}
            >
              <div className={cx("student-home-label")}>Open</div>
            </div>
            <div
              className={cx(
                selectedFilter === "Incomplete"
                  ? "student-home-selected-button"
                  : "student-home-unselected-button"
              )}
              onClick={() => handleFilterClick("Incomplete")}
            >
              <div className={cx("student-home-label")}>Incomplete</div>
            </div>
          </div>
        </div>
        <div className={cx("student-history-card-rows")}>
          {loadingExercises ? (
            <p>Loading exercises...</p>
          ) : exercises ? (
            exercises.map((exercise) => renderStudentCard(exercise))
          ) : (
            <p>No exercises found.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeDashboard;
