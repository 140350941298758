import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protected from "./Protected";
import ReactGA from "react-ga4";
import { initHotjar } from "helpers/Hotjar";

import StudentLogin from "screens/student/login/Login";
import StudentResetPin from "screens/student/resetPin/ResetPin";
import StudentSentNewPin from "screens/student/sentNewPin/SentNewPin";
import StudentResetEmail from "screens/student/resetEmail/ResetEmail";
import StudentWelcome from "screens/student/welcome/Welcome";
import StudentTestYourMic from "screens/student/testYourMic/TestYourMic";
import StudentBlockMic from "screens/student/blockMic/BlockMic";
import StudentAddParentsEmail from "screens/student/addParentsEmail/AddParentsEmail";
import StudentAddParentsEmailPendingVerification from "screens/student/addParentsEmailSuccess/AddParentsEmailSuccess";
import StudentInformation from "screens/student/information/Information";
import StudentInformationInstructions from "screens/student/informationInstructions/InformationInstructions";
import StudentNoResponse from "screens/student/response/NoResponse";
import StudentOops from "screens/student/StudentOops";
import StudentSucessChangePin from "screens/student/StudentSuccessChangePin";
import StudentProfile from "screens/student/profile/Profile";
import StudentHome from "screens/student/home/Home";
import StudentHistory from "screens/student/history/History";
import StudentWellDone from "screens/student/welldone/Welldone";
import StudentParentEmailSuccess from "screens/student/parentEmailSuccess/ParentEmailSuccess";

import SystemOwnerLogin from "screens/systemOwner/login/Login";
import SystemOwnerForgetPw from "screens/systemOwner/forgetPassword/ForgetPw";
import SystemOwnerResetPw from "screens/systemOwner/resetPassword/ResetPassword";
import SystemOwnerCreateOrgInit from "screens/systemOwner/createOrganisationInitial/CreateOrganisationInitial";
import SystemOwnerCreateOrg from "screens/systemOwner/createOrganisation/CreateOrganisation";
import SystemOwnerAdminAcc from "screens/systemOwner/adminAccount/AdminAccount";
import SystemOwnerAdminAccInit from "screens/systemOwner/adminAccountInitial/AdminAccountInitial";
import SystemOwnerSettingsInfo from "screens/systemOwner/settings/SettingsInfo";
import SystemOwnerPasswordChanged from "screens/systemOwner/passwordChanged/PasswordChanged";
import SystemOwnerMainDashboard from "screens/systemOwner/mainDashboard/MainDashboard";
import SystemOwnerAdminMainDashboard from "screens/systemOwner/adminAccount/adminMainDashboard/AdminMainDashboard";
import SystemOwnerOrganisationDetails from "screens/systemOwner/organisationDetails/OrganisationDetails";
import SystemOwnerMultiOrganisation from "screens/systemOwner/multiOrganisation/MultiOrganisation";
import SystemOwnerAdminDelete from "screens/systemOwner/adminDelete/AdminDelete";
import StudentMicAccessDenied from "screens/student/micAccessDenied/MicAccessDenied";
import StudentEmailVerificationParents from "screens/student/emailVerificationParents/EmailVerificationParents";
import StudentDetectedDevices from "screens/student/detectedDevices/DetectedDevices";
import StudentMicRecording from "screens/student/recording/MicTestRecording";
import SystemOwnerMultiSchoolAdmin from "screens/systemOwner/multiSchoolAdmin/MultiSchoolAdmin";
import StudentForgotPin from "screens/student/forgotPin/ForgetPin";
import StudentInformationExerciseBegins from "screens/student/informationExerciseBegins/InformationExerciseBegins";
import StudentExerciseResponseRecording from "screens/student/exerciseResponseRecording/ExerciseResponseRecording";
import StudentForgotPinNoParentEmail from "screens/student/forgotPin/ForgotPinNoParentEmail";
// DEPRECATED: Fix webpack failed to parse source map compile warnings.
// Reference: https://stackoverflow.com/questions/71500112/antd-source-map-not-supported-in-react
// import "antd/dist/antd.css";
import StudentChangeParentsEmail from "screens/student/changeParentsEmail/ChangeParentsEmail";

import PageTracking from "components/PageTracking";
import ToasterMain from "components/ToasterMain";

import "antd/dist/antd.min.css";

const TRACKING_ID = process.env.REACT_APP_GA_KEY;

if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

const siteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION);
const enableHotjar = process.env.REACT_APP_ENABLE_HOTJAR === "true";

if (enableHotjar && !isNaN(siteId) && !isNaN(hotjarVersion)) {
  initHotjar(siteId, hotjarVersion);
  console.log("Hotjar is turned ON and initialized.");
} else {
  console.log("Hotjar is turned OFF or not properly configured.");
}

const AppNavigator = () => {

  // LOGGED IN CHECKS HAVE BEEN MOVED TO PROTECTED.JS
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [role, setRole] = useState("");

  // const token = localStorage.getItem("bearer-token");
  // const userRole = localStorage.getItem("role");

  // useEffect(() => {
  //   if (token || userRole) {
  //     setIsLoggedIn(!!token);
  //     setRole(userRole || "");
  //   }
  // }, [token, userRole]);

  return (
    <Router>
      <PageTracking />
      {ToasterMain()}
      <Routes>
        <Route path="/" element={<StudentLogin />} />

        {/* System Owner Non Protected Routes */}
        <Route
          path="/admin/system-owner-login"
          element={<SystemOwnerLogin />}
        />
        <Route
          path="/admin/system-owner-forget-password"
          element={<SystemOwnerForgetPw />}
        />
        <Route
          path="/admin/system-owner-reset-password"
          element={<SystemOwnerResetPw />}
        />

        {/* System Owner Protected Routes */}
        <Route
          path="/admin/system-owner-create-org-initial"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-create-org-initial"
            >
              <SystemOwnerCreateOrgInit />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-create-org"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-create-org"
            >
              <SystemOwnerCreateOrg />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-account-initial"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-admin-account-initial"
            >
              <SystemOwnerAdminAccInit />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-account"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-admin-account"
            >
              <SystemOwnerAdminAcc />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-main-dashboard"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-admin-main-dashboard"
            >
              <SystemOwnerAdminMainDashboard />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-settings-info"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-settings-info"
            >
              <SystemOwnerSettingsInfo />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-password-changed"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-password-changed"
            >
              <SystemOwnerPasswordChanged />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-main-dashboard"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-main-dashboard"
            >
              <SystemOwnerMainDashboard />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-organisation-details"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-organisation-details"
            >
              <SystemOwnerOrganisationDetails />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-multiple-organisation-upload"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-multiple-organisation-upload"
            >
              <SystemOwnerMultiOrganisation />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-multiple-school-admin-upload"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-multiple-school-admin-upload"
            >
              <SystemOwnerMultiSchoolAdmin />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-delete"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/admin/system-owner-admin-delete"
            >
              <SystemOwnerAdminDelete />
            </Protected>
          }
        />

        {/* Student Non Protected Routes */}
        <Route path="/reset-pin" element={<StudentResetPin />} />
        <Route path="/forgot-pin" element={<StudentForgotPin />} />
        <Route path="/sent-new-pin" element={<StudentSentNewPin />} />
        <Route
          path="/forgot-pin-no-parent-email"
          element={<StudentForgotPinNoParentEmail />}
        />
        <Route path="/reset-email" element={<StudentResetEmail />} />

        {/* Student Protected Routes */}
        <Route
          path="/welcome"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/welcome">
              <StudentWelcome />
            </Protected>
          }
        />
        <Route
          path="/add-parents-email"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/add-parents-email"
            >
              <StudentAddParentsEmail />
            </Protected>
          }
        />
        <Route
          path="/test-your-microphone"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/test-your-microphone"
            >
              <StudentTestYourMic />
            </Protected>
          }
        />
        <Route
          path="/detected-devices"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/detected-devices"
            >
              <StudentDetectedDevices />
            </Protected>
          }
        />
        <Route
          path="/blocked-microphone"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/blocked-microphone"
            >
              <StudentBlockMic />
            </Protected>
          }
        />
        <Route
          path="/add-parents-email-pending-verification"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/add-parents-email-pending-verification"
            >
              <StudentAddParentsEmailPendingVerification />
            </Protected>
          }
        />
        <Route
          path="/information"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/information">
              <StudentInformation />
            </Protected>
          }
        />
        <Route
          path="/information-instructions"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/information-instructions"
            >
              <StudentInformationInstructions />
            </Protected>
          }
        />
        <Route
          path="/information-exercise-begins"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/information-exercise-begins"
            >
              <StudentInformationExerciseBegins />
            </Protected>
          }
        />
        <Route
          path="/exercise-response-recording"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/exercise-response-recording"
            >
              <StudentExerciseResponseRecording />
            </Protected>
          }
        />
        <Route
          path="/no-response"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/no-response">
              <StudentNoResponse />
            </Protected>
          }
        />
        <Route
          path="/oops"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/oops">
              <StudentOops />
            </Protected>
          }
        />
        <Route
          path="/well-done"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/well-done">
              <StudentWellDone />
            </Protected>
          }
        />
        <Route
          path="/success-change-pin"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/success-change-pin"
            >
              <StudentSucessChangePin />
            </Protected>
          }
        />
        <Route
          path="/profile"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/profile">
              <StudentProfile />
            </Protected>
          }
        />
        <Route
          path="/change-parents-email"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/change-parents-email"
            >
              <StudentChangeParentsEmail />
            </Protected>
          }
        />
        <Route
          path="/home"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/home">
              <StudentHome />
            </Protected>
          }
        />
        <Route
          path="/history"
          element={
            <Protected 
// isLoggedIn={isLoggedIn} 
// role={role}
 path="/history">
              <StudentHistory />
            </Protected>
          }
        />
        <Route
          path="/parent-email-success"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/parent-email-success"
            >
              <StudentParentEmailSuccess />
            </Protected>
          }
        />
        <Route
          path="/email-verification-parents"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/email-verification-parents"
            >
              <StudentEmailVerificationParents />
            </Protected>
          }
        />
        <Route
          path="/microphone-access-denied"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/microphone-access-denied"
            >
              <StudentMicAccessDenied />
            </Protected>
          }
        />
        <Route
          path="/microphone-recording"
          element={
            <Protected
              // isLoggedIn={isLoggedIn}
              // role={role}
              path="/microphone-recording"
            >
              <StudentMicRecording />
            </Protected>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppNavigator;
