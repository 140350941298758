import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./TestYourMic.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "../../../shared/student/NavigationBarHomeLogout";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentVerifyBtn from "../../../assets/studentVerifyBtn.svg";
import NavigationBarFull from "../../../shared/student/NavigationBarFull";

const TestYourMic = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const [clickedMicAccess, setClickedMicAccess] = useState(false);

  const checkOnboardingStatus = localStorage.getItem(
    "both_parent_email_and_mic_test_onboarding"
  );

  const checkMicPermissions = async () => {
    await navigator.mediaDevices
      ?.getUserMedia({ audio: true })
      .then((stream) => {
        // console.log("Microphone access granted");
        stream.getTracks().forEach((track) => track.stop()); // Stop using the stream
        navigate("/detected-devices");
      })
      .catch((error) => {
        if (error.name === "NotAllowedError") {
          console.log("Microphone access denied");
          navigate("/blocked-microphone");
        } else {
          console.log("Error accessing microphone:", error);
          navigate("/blocked-microphone");
        }
      });
  };

  return (
    <>
      {checkOnboardingStatus ? (
        <NavigationBarFull />
      ) : (
        <NavigationBarHomeLogout />
      )}

      {clickedMicAccess ? (
        <div className={cx("st-test-your-mic-overlay")}>
          <div className={cx("st-test-your-mic-overlay-popup")}>
            <div className={cx("st-test-your-mic-overlay-popup-text")}>
              Please click the "Allow" button
            </div>
          </div>
        </div>
      ) : null}
      <div className={cx("st-test-your-mic-container")}>
        <div className={cx("st-test-your-mic-rectangle-container")}>
          <div className={cx("st-test-your-mic-contents")}>
            <img
              className={cx("st-test-your-mic-icon")}
              src={studentMicIcon}
              alt="student-mic-icon"
            />
            <div className={cx("st-test-your-mic-title")}>
              Test Your Microphone
            </div>
            <div className={cx("st-test-your-mic-subtitle")}>
              Please click the “Allow” button on next screen that we can use
              your microphone.
              <br /> Please press “Verify” button to start.
            </div>

            <img
              className={cx("st-test-your-mic-verify-btn")}
              src={studentVerifyBtn}
              alt="st-test-your-mic-verify-btn"
              onClick={() => {
                {
                  setClickedMicAccess(!clickedMicAccess);
                  checkMicPermissions();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TestYourMic;
