import React, { useState, useEffect } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Login.scss";
import systemOwnerLoginBackground from "../../../assets/systemOwnerLoginBackground.png";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { loginAPI } from "../../../utils/WebServiceConfig";

const Login = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [email, setChangeEmail] = useState("");
  const [password, setChangePassword] = useState("");
  const [jSONFlagError, setJSONFlagError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const checkIfForgetPwIsTrue = localStorage.getItem("reset-password");

  const token = localStorage.getItem("bearer-token");
  const checkToken = token !== null && token !== "";
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    if (checkToken) {
      if (userRole === "SYSTEM_OWNER") {
        navigate("/admin/system-owner-create-org-initial");
      }
    }
  }, [checkToken, userRole]);

  useEffect(() => {
    if (checkIfForgetPwIsTrue === "true") {
      showtoastMsgIfForgetPw();
      localStorage.setItem("reset-password", "");
    }
  }, [checkIfForgetPwIsTrue]);
  const showtoastMsgIfForgetPw = () => {
    toast.success(
      "Forgot password instructions have been sent to your email.",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: {
          backgroundColor: "#1BBF8D",
        },
      }
    );
  };

  const handleClickLogin = (email: string, password: string) => {
    setJSONFlagError(false);
    if (email.length === 0 || password.length === 0) {
      setJSONFlagError(true);
      setErrorMsg("Login failed. Credentials are required.");
      return;
    } else if (!email.includes("@")) {
      setJSONFlagError(true);
      setErrorMsg("Invalid email credentials");
      return;
    } else {
      postLogin(email, password);
    }
  };

  const postLogin = async (email: string, password: string) => {
    let url = loginAPI;

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions: any = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PATCH,OPTIONS",
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        let res = await response.json();
        localStorage.setItem("role", res.payload.role); // Store the role
        localStorage.setItem("bearer-token", res.auth_token);

        setJSONFlagError(false);

        navigate("/admin/system-owner-create-org-initial");
      } else {
        let errorResponse = await response.json();
        setJSONFlagError(true);

        setErrorMsg(errorResponse.detail["error message"]);
      }
    } catch (err) {
      setJSONFlagError(true);

      setErrorMsg("Error. Please try again later.");
      console.log("error", err);
    }
  };

  const handleEmailChange = (event: any) => {
    setChangeEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setChangePassword(event.target.value);
  };

  return (
    <div className={cx("system-owner-login-container")}>
      <img
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
        }}
        src={systemOwnerLoginBackground}
        alt="system-owner-login-background"
      />

      <div className={cx("so-rectangle-container")}>
        <div className={cx("so-rectangle")}>
          <ToastContainer />
          <div className={cx("so-login-container")}>
            <div className={cx("so-login-title")}>Login</div>

            <Form className={cx("so-form-inputs")}>
              <FormGroup floating>
                <Input
                  className={cx("so-email-input")}
                  id="so-email-input"
                  name="email"
                  placeholder="Your Email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <Label className={cx("so-email-label")} for="so-email-label">
                  Your Email
                </Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  className={cx("so-password-input")}
                  id="so-email-password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <Label
                  className={cx("so-password-label")}
                  for="so-password-label"
                >
                  Password
                </Label>
              </FormGroup>
            </Form>
            {jSONFlagError ? (
              <div className={cx("so-field-validation")}>{errorMsg}</div>
            ) : null}
            <div
              className={cx("so-forget-pw-link")}
              onClick={() => navigate("/admin/system-owner-forget-password")}
            >
              Forgot password?
            </div>
            <button
              onClick={() => handleClickLogin(email, password)}
              className={cx("so-login-btn")}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
