import React, { useState, DragEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./MultiOrganisation.scss";
import NavigationBar from "../../../shared/systemOwner/NavigationBar";
import ModalCloseIcon from "../../../assets/ModalCloseIcon.svg";
import ModalUploadIcon from "../../../assets/ModalUploadIcon.svg";
import ErrorUploadIcon from "../../../assets/error-msg-exclaimation-icon.svg";

// DEPRECATED: previously @material-ui/core was installed,
// but it's removed as it conflicts with React v18
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { Box } from "@material-ui/core";

import { CircularProgress, Box } from "@mui/material";
import {
  batchOrganisationUpload,
  downloadSampleOrganisationSpreadSheet,
} from "../../../utils/WebServiceConfig";

const MultiOrganisation: React.FC = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [overlay, setOverlay] = useState<boolean>(true);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const actualBtnRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorJson, setErrorJson] = useState<boolean>(false);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      postBatchUploadOrg(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      postBatchUploadOrg(event.target.files[0]);
    }
  };

  const postBatchUploadOrg = async (fileInput: File) => {
    let url = batchOrganisationUpload;
    const formdata = new FormData();
    setErrorJson(false);
    formdata.append("file_upload", fileInput);
    setFileName(fileInput.name);

    const requestOptions: any = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bearer-token"),
      },
      body: formdata,
    };

    try {
      setLoading(true);
      const response = await fetch(url, requestOptions);
      setLoading(false);

      if (response.ok) {
        localStorage.setItem("batch-upload-org", "true");
        navigate("/admin/system-owner-main-dashboard");
      } else {
        let errorResponse = await response.json();
        setErrorJson(true);
        setErrorMsg(errorResponse.detail["error message"]);
      }
    } catch (err: any) {
      setLoading(false);
      setErrorJson(true);
      setErrorMsg(err.toString());
      console.log("error", err);
    }
  };

  function downloadSpreadsheet(s: ArrayBuffer) {
    var blob = new Blob([s], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    var a = document.createElement("a");
    a.setAttribute("download", "test_org_1.xlsx");
    a.setAttribute("href", window.URL.createObjectURL(blob));
    a.click();
  }

  const getSampleOrgSpreadsheetOrg = async () => {
    let url = downloadSampleOrganisationSpreadSheet;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("bearer-token"),
        },
      });

      if (response.ok) {
        const res = await response.arrayBuffer();
        downloadSpreadsheet(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalClose = () => {
    setOverlay(false);
    navigate("/admin/system-owner-create-org-initial");
  };

  return (
    <div className={cx("so-modal-container")}>
      <NavigationBar />
      {overlay && (
        <div className="so-modal-overlay">
          <div
            className={
              !errorJson
                ? cx("so-modal-main-container")
                : cx("so-modal-error-main-container")
            }
          >
            <img
              className={cx("so-modal-close-icon")}
              src={ModalCloseIcon}
              alt="so-modal-close-icon"
              onClick={handleModalClose}
            />
            <div className={cx("so-modal-label-upload-container")}>
              <div className={cx("so-modal-title")}>
                Multiple Organisations Upload
              </div>
              <div className={cx("so-modal-subtitle")}>
                Upload organisations by uploading an Excel spreadsheet. Please
                download the sample
                <br /> Excel spreadsheet{" "}
                <span
                  onClick={getSampleOrgSpreadsheetOrg}
                  className={cx("so-subtitle-highlight")}
                >
                  here.
                </span>
              </div>
              <div
                className={cx("so-subtitle-rectangle")}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {loading ? (
                  <div className={cx("loader-container")}>
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        style={{ color: "#2C97F4" }}
                        variant="determinate"
                        value={90}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      ></Box>
                    </Box>
                    <div className={cx("loader-upload-label")}>
                      Uploading...
                    </div>
                    <div className={cx("loader-file-label")}>
                      File: {fileName}
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      className={cx("so-modal-upload-icon")}
                      src={ModalUploadIcon}
                      alt="so-modal-upload-icon"
                    />

                    <div className={cx("so-modal-upload-label")}>
                      Drag and drop your file here or click browse to find a
                      file.
                    </div>
                    <div className={cx("so-modal-upload-label")}>
                      Only files with the .xls and .xlsx extensions are
                      accepted.
                    </div>

                    <input
                      onChange={handleChange}
                      type="file"
                      id="actual-file-upload"
                      hidden
                      ref={actualBtnRef}
                    />
                    <label
                      className={cx("so-modal-browse")}
                      htmlFor="actual-file-upload"
                    >
                      Browse
                    </label>
                  </>
                )}
              </div>
              {errorJson && (
                <div className={cx("so-error-msg-container")}>
                  <img
                    className={cx("so-error-msg-icon")}
                    src={ErrorUploadIcon}
                  />
                  <div className={cx("so-error-msg-label")}>{errorMsg}</div>
                </div>
              )}
            </div>

            <div className={cx("so-modal-btn-container")}>
              <button
                className={cx("so-modal-button")}
                onClick={handleModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiOrganisation;
